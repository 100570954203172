import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import footerBackground from "../assets/footer_background.png";
import footerCycle from "../assets/footer_cycle.gif";
import footerCar from "../assets/footer_car.gif";

import {
  GrFacebookOption,
  GrInstagram,
  GrYoutube,
  GrTwitter,
} from "react-icons/gr";
// import { CiYoutube, CiTwitter } from "react-icons/ci";

function Footer() {
  // Automatically scrolls to top of a page
  function scrollTop() {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const footerV1 = () => {
    return (
      <div className="uk-container uk-container-small uk-flex uk-flex-between uk-padding-large">
        <div className="uk-flex uk-flex-column">
          <h4 className="title-text">Get in Touch</h4>
          <p className="link-text">abc125@gmail.com</p>
          <Link to={"/thank-you"} onClick={scrollTop} className="link-text">
            Thank you page
          </Link>
        </div>
        <div className="uk-flex uk-flex-column">
          <h4 className="title-text">Sitemap</h4>
          <Link to={"/"} onClick={scrollTop} className="link-text">
            Home
          </Link>
          <Link to={"/watch"} onClick={scrollTop} className="link-text">
            Watch
          </Link>
          <Link to={"/about"} onClick={scrollTop} className="link-text">
            About
          </Link>
          <Link to={"/contact"} onClick={scrollTop} className="link-text">
            Contact
          </Link>
        </div>
        <div className="uk-flex uk-flex-column">
          <h4 className="title-text">Social links</h4>
          <Link to={"/"} className="link-text">
            Youtube
          </Link>
          <Link to={"/watch"} className="link-text">
            Instagram
          </Link>
          <Link to={"/about"} className="link-text">
            Facebook
          </Link>
          <Link to={"/about"} className="link-text">
            Reddit
          </Link>
        </div>
        {/* <div className="footer-bottom">
        <hr />
        <p className="copyright-text">travelBucket, Copyright &copy; 2021</p>
      </div> */}
      </div>
    );
  };

  return (
    <Wrapper>
      {/* {footerV1()} */}
      {/* <hr /> */}
      <div className="uk-divider-icon"></div>
      <div className="uk-container uk-container-small link-container">
        <div className="uk-flex uk-flex-row uk-flex-wrap uk-flex-center ">
          <Link to={"/"} onClick={scrollTop} className="link-text">
            Home
          </Link>
          <Link to={"/contact"} onClick={scrollTop} className="link-text">
            Contact
          </Link>
          <Link to={"/about"} onClick={scrollTop} className="link-text">
            About
          </Link>
          <Link to={"/watch"} onClick={scrollTop} className="link-text">
            Watch
          </Link>
          <Link to={"/thank-you"} onClick={scrollTop} className="link-text">
            ThankPage
          </Link>
          <Link
            to={"/privacy-policy"}
            onClick={scrollTop}
            className="link-text"
          >
            PrivacyPolicy
          </Link>
          <Link to={"/disclaimer"} onClick={scrollTop} className="link-text">
            Disclaimer
          </Link>
          <Link
            to={"/copyright-report"}
            onClick={scrollTop}
            className="link-text"
          >
            CopyrightReport
          </Link>
        </div>

        <div className="uk-flex uk-flex-row uk-flex-center uk-margin-small-top">
          {/* <a
            href="https://stackoverflow.com/questions/42914666/react-router-external-link"
            className="social-icon"
            target="_blank"
            data-uk-tooltip="title: Youtube; pos: bottom-left"
          >
            <GrYoutube />
          </a>
          <a
            href="https://stackoverflow.com/questions/42914666/react-router-external-link"
            className="social-icon"
            target="_blank"
            data-uk-tooltip="title: Instagram; pos: bottom-left"
          >
            <GrInstagram />
          </a>
          <a
            href="https://stackoverflow.com/questions/42914666/react-router-external-link"
            className="social-icon"
            target="_blank"
            data-uk-tooltip="title: Facebook; pos: bottom-left"
          >
            <GrFacebookOption />
          </a>
          <a
            href="https://stackoverflow.com/questions/42914666/react-router-external-link"
            className="social-icon"
            target="_blank"
            data-uk-tooltip="title: Twitter; pos: bottom-left"
          >
            <GrTwitter />
          </a> */}

          {/* <Link to={"/"} className="social-icon">
            <GrYoutube />
          </Link>
          <Link to={"/"} className="social-icon">
            <GrInstagram />
          </Link>
          <Link to={"/"} className="social-icon">
            <GrFacebookOption />
          </Link>
          <Link to={"/"} className="social-icon">
            <GrTwitter />
          </Link> */}
        </div>
      </div>

      {/* ANIMATION */}
      <div className="footer_bg">
        <div className="footer_bg_one"></div>
        <div className="footer_bg_two"></div>
      </div>

      <div className="footer-opaque">
        {/* <p className="copyright-text">travelBucket, Copyright &copy; 2023</p> */}
        <p className="copyright-text"> - THE END - </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  margin-top: 2rem;
  /* background: #002b51; */
  /* --color-1: #ffffff80; */
  --color-1: var(--color--text);
  --color-2: #40e0d0;
  .link-container {
    padding-top: 2rem;
    padding-bottom: 0rem;
  }

  .title-text {
    margin-bottom: 10px;
    color: var(--color-1);
    font-weight: 500;
  }
  .link-text {
    color: var(--color-1);
    font-size: 1rem;
    /* text-transform: uppercase; */
    margin-right: 1rem;
  }
  .link-text:hover {
    /* color: var(--color-2); */
    color: var(--linkHoverColor);
  }
  .social-icon {
    color: var(--color-1);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    border: 1px solid var(--color-1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }
  .social-icon:hover {
    /* color: var(--color-2); */
    /* border-color: var(--color-2); */
    color: var(--linkHoverColor);
    border-color: var(--linkHoverColor);
  }
  .footer-opaque {
    height: 3rem;
    background: #002b51;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .copyright-text {
    font-size: 14px;
    /* color: #fff; */
    margin-bottom: 0;
  }
  /* ANIMATION STYLES */
  .footer_bg {
    position: relative;
    /* position: absolute;
    bottom: 0; */
    /* background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEigB8iI5tb8WSVBuVUGc9UjjB8O0708X7Fdic_4O1LT4CmLHoiwhanLXiRhe82yw0R7LgACQ2IhZaTY0hhmGi0gYp_Ynb49CVzfmXtYHUVKgXXpWvJ_oYT8cB4vzsnJLe3iCwuzj-w6PeYq_JaHmy_CoGoa6nw0FBo-2xLdOPvsLTh_fmYH2xhkaZ-OGQ/s16000/footer_bg.png")
      no-repeat scroll center 0; */
    background: url("${footerBackground}") no-repeat scroll center 0;
    /* width: 100%; */
    height: 266px;
    overflow: hidden;
    margin-top: -1rem;
    margin-bottom: 0.5rem;
    /* border: 1px solid red; */
  }

  .footer_bg_one {
    background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEia0PYPxwT5ifToyP3SNZeQWfJEWrUENYA5IXM6sN5vLwAKvaJS1pQVu8mOFFUa_ET4JuHNTFAxKURFerJYHDUWXLXl1vDofYXuij45JZelYOjEFoCOn7E6Vxu0fwV7ACPzArcno1rYuVxGB7JY6G7__e4_KZW4lTYIaHSLVaVLzklZBLZnQw047oq5-Q/s16000/volks.gif")
      no-repeat center center;
    /* background: url("${footerCar}") no-repeat scroll center 0; */
    width: 330px;
    height: 105px;
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: 30%;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
    /* border: 1px solid red; */
  }

  .footer_bg_two {
    /* background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhyLGwEUVwPK6Vi8xXMymsc-ZXVwLWyXhogZxbcXQYSY55REw_0D4VTQnsVzCrL7nsyjd0P7RVOI5NKJbQ75koZIalD8mqbMquP20fL3DxsWngKkOLOzoOf9sMuxlbyfkIBTsDw5WFUj-YJiI50yzgVjF8cZPHhEjkOP_PRTQXDHEq8AyWpBiJdN9SfQA/s16000/cyclist.gif")
      no-repeat center center; */
    background: url("${footerCycle}") no-repeat scroll center 0;
    width: 88px;
    height: 100px;
    background-size: 100%;
    left: 38%;
    bottom: 0;
    position: absolute;
    -webkit-animation: myfirst 30s linear infinite;
    animation: myfirst 30s linear infinite;
    /* border: 1px solid red; */
  }

  @-moz-keyframes myfirst {
    0% {
      left: -25%;
    }
    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes myfirst {
    0% {
      left: -25%;
    }
    100% {
      left: 100%;
    }
  }

  @keyframes myfirst {
    0% {
      left: -25%;
    }
    100% {
      left: 100%;
    }
  }
`;

export default Footer;
