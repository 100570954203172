import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Loading from "../../../components/Loading";
import useApi from "../../../utils/custom-hooks/useApi";
import {
  mandatoryField,
  themes,
  placeTypes,
  categories,
} from "../../../utils/utility";

const allSubDestinatonTemp = [
  {
    id: 1,
    name: "Talsari beach",
  },
  {
    id: 2,
    name: "Renova",
  },
  {
    id: 3,
    name: "Indiana",
  },
  {
    id: 4,
    name: "Keloti",
  },
  {
    id: 5,
    name: "Lilawa",
  },
];
function EditNeighbour({ placeId }) {
  const [subDestinationList, setSubDestinationList] =
    useState(allSubDestinatonTemp);
  const [redirect, setRedirect] = useState(false);
  const [mainDestinationId, setMainDestinationId] = useState("");
  const [makePublish, setMakePublish] = useState(false);
  const history = useHistory();

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    fetchNeighbour();
  }, []);

  const fetchNeighbour = async () => {
    await makeApiCall("GET", `/bluff-destination/${placeId}`);
    if (resultRef.current && resultRef.current.status) {
      const destination = resultRef.current.destination;
      setValue("placeName", destination.place_name);
      setValue("placeOverview", destination.place_info);
      setValue("placeType", "neighbour");
      setValue("streetAddress", destination.address.street_address);
      setValue("latLong", destination.address.lat_long);
      setValue("country", destination.address.country);
      setValue("state", destination.address.state);
      setMainDestinationId(destination.main_destination_id);
      setMakePublish(destination.is_published);
    }
  };

  const onSubmit = async (data) => {
    const publishCheckboxElement = document.getElementById("publish-checkbox");
    const isPublished = publishCheckboxElement.checked;

    const address = {
      street_address: data.streetAddress,
      lat_long: data.latLong,
      country: data.country,
      state: data.state,
    };
    var checkedValues = [];
    var inputElements = document.getElementsByClassName("uk-checkbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValues.push(inputElements[i].value);
      }
    }

    const dest = {
      place_id: placeId,
      place_name: data.placeName,
      place_info: data.placeOverview,
      place_type: "neighbour",
      address: address,
      is_published: isPublished,
    };
    await makeApiCall("PUT", `/admin/bluff-destination/edit`, dest);

    setTimeout(() => {
      if (resultRef.current && resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        history.replace(
          `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
        );
        // setRedirect(true);
      } else {
        alert(`FAIL: ${resultRef.current && resultRef.current.message}`);
      }
    }, 1000);
  };

  const handleDeleteNeighbour = async () => {
    await makeApiCall("DELETE", `/admin/bluff-destination/${placeId}`);

    setTimeout(() => {
      if (resultRef.current && resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        history.replace(
          `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
        );
        //  setplaceId(resultRef.current.destination.place_name);
        // setRedirect(true);
      } else {
        alert(`FAIL: ${resultRef.current && resultRef.current.message}`);
      }
    }, 1000);
  };

  const checkboxClickListener = () => {
    setMakePublish(!makePublish);
  };

  const editNeighbourForm = () => {
    return (
      <div>
        <form class="uk-form-stacked" onSubmit={handleSubmit(onSubmit)}>
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              id="publish-checkbox"
              checked={makePublish}
              onClick={checkboxClickListener}
            />
            {` make publish`}
          </label>

          <p className="uk-text-success ">Key Info(fields)</p>

          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Neighbour name..."
              // onChange={(e) => setName(e.target.value)}
              // value={name}
              {...register("placeName", {
                required: {
                  value: true,
                  message: "field is required",
                },
                minLength: {
                  value: 4,
                  message: "Place name must be at least 4 characters",
                },
              })}
            />
            <span className="uk-text-danger">{errors.placeName?.message}</span>
          </div>

          {/* <--  place info --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place info</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Neighbour info..."
              // onChange={(e) => setOverview(e.target.value)}
              // value={overview}
              {...register("placeOverview", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.placeOverview?.message}
            </span>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>
          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place Type (FIXED - neighbour)</label>
            <select
              class="uk-select"
              id="place-type-select-neighbour"
              disabled
              {...register("placeType", { required: true })}
            >
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select>
          </div>
          {/* <-- linking with FD --> */}
          <p className="uk-text-danger uk-margin-medium-top">
            Link with final/sub-destination (fields)
          </p>
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            {subDestinationList.map(({ id, name }) => {
              return (
                <label key={id}>
                  <input class="uk-checkbox" type="checkbox" value={name} />
                  {name}
                </label>
              );
            })}
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>

          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
              {...register("streetAddress", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.streetAddress?.message}
            </span>
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="lat-long (optional)"
                {...register("latLong")}
              />
            </div>

            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="country"
                {...register("country", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.country?.message}</span>
            </div>

            <div>
              <input
                class="uk-input"
                type="text"
                placeholder="state"
                {...register("state", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.state?.message}</span>
            </div>
          </div>

          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            type="submit"
          >
            submit
          </button>
        </form>

        {/* <--  Delete --> */}
        <button
          className="uk-button uk-button-danger uk-margin-small-bottom"
          onClick={handleDeleteNeighbour}
        >
          delete
        </button>
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };
  // MAIN UI
  return (
    <>
      {editNeighbourForm()}
      {showLoading()}
      {errorMessage()}
      {/* {redirect && (
        <Redirect to={`/admin/destination-listing/${mainDestinationId}`} />
      )} */}
    </>
  );
}

export default EditNeighbour;
