import React from "react";
import styled from "styled-components";

const Loading = () => {
  return (
    <Wrapper>
      {/* <div className="uk-text-center">
        <div className="loader"></div>;
      </div> */}

      <div className="uk-text-center uk-margin-small-top  uk-margin-small-bottom">
        <div uk-spinner="ratio: 1"></div>
      </div>
    </Wrapper>
  );
};

export default Loading;

const Wrapper = styled.div`
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
