import React, { useState } from "react";
import styled from "styled-components";

function ExpandNCollapseItem({ title, description }) {
  const [isShow, setIsShow] = useState(false);
  const buttonClickHandler = () => {
    setIsShow(!isShow);
  };

  return (
    <Wrapper>
      <div className="horizontal-container">
        <h4 className="title">{title}</h4>
        <button className="button" onClick={buttonClickHandler}>
          {isShow ? `-` : `+`}
        </button>
      </div>
      {isShow && <p className="description">{description}</p>}
      <div className="border"></div>
    </Wrapper>
  );
}

export default ExpandNCollapseItem;
const Wrapper = styled.div`
  /* margin-top: 0.5rem; */
  width: 50%;

  .horizontal-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.9rem 0;
  }
  .button {
    font-size: 1.5rem;
  }
  .title {
    color: #0f294d;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }
  .border {
    /* border-style: solid;
    border-bottom-width: 0.5px;
    border-color: var(--clr-grey-7); */

    background-color: #c5c5c5;
    height: 1px;
    width: 100%;
  }
  .description {
    margin-bottom: 0.8rem;
  }
`;
