import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import { getUser } from "../utils/utility";

const RouteChangeTracker = ({ history }) => {
  const user = getUser();

  history.listen((location, action) => {
    // console.log("RouteChangeTracker: --- ", location.pathname);
    // ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname);

    if (location.pathname.includes("/my-bucket") && user) {
      // let rootURL = location.pathname.split("/")[1];
      // let userPage = location.pathname.split("/")[3];
      // let pageHit = `/${rootURL}/${userPage}`;
      // ReactGA.pageview(pageHit);

      ReactGA.pageview("/myBucketPageCounter");
    } else if (location.pathname.includes("/single-bucket") && user) {
      ReactGA.pageview("/singleBucketPageCounter");
    } else {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  });

  return <></>;
};

export default withRouter(RouteChangeTracker);
