import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const pathNameHistory = new Set();

/* Automatically scroll to top of page */
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // window.scrollTo(0, 0);
    //
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // });
    //
    if (!pathNameHistory.has(pathname)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      pathNameHistory.add(pathname);
    }
  }, [pathname]);

  return null;
}

export default ScrollToTop;
