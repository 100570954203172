import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { FaThumbsUp, FaTimes, FaHamburger, FaClose } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import styled from "styled-components";
import { getUser } from "../utils/utility";

// import "./../css/NavbarStyle.css";

// TODO: navbar container width not equals uikit container width only at 900-1000 px otherwise working fine everywhere,
// reason for this behaviour [mobile -> desktop] design principle leads difference in width as in uikit container width is dynamic in nature while yours fixed 900px
function Navbar() {
  const { pathname } = useLocation();
  const [currentPosition, setCurrentPosition] = useState(5);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isStick, setIsStick] = useState(false);
  const user = getUser();

  function itemClick(position) {
    setCurrentPosition(position);
    setShowNavbar(!showNavbar);
  }

  const onMenuIconClick = () => {
    setShowNavbar(!showNavbar);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    // setScrollPosition(position);
    if (position > 30) setIsStick(true);
    else setIsStick(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (pathname === "/") setCurrentPosition(0);
    else if (pathname === "/watch") setCurrentPosition(1);
    else if (pathname === "/my-bucket") setCurrentPosition(2);
    else if (pathname === "/about") setCurrentPosition(3);
    else if (pathname === "/my-profile" || pathname === "/login")
      setCurrentPosition(4);
    else if (pathname === "/admin") setCurrentPosition(5);
    else setCurrentPosition(6);
  }, [pathname]);

  return (
    <Wrapper>
      <div className={`nav-wrapper ${isStick && "sticky"}`}>
        <div className={`main-nav ${isStick && "sticky"}`}>
          {/* BRAND NAME */}
          <Link to="/" onClick={() => itemClick(0)}>
            <span className="brand-name">travelBucket</span>
          </Link>

          {/* LINKS */}
          <ul className={`right-nav ${showNavbar && "show"}`}>
            <li>
              <Link to={"/"} onClick={() => itemClick(0)}>
                Explore
                <div
                  className={`${currentPosition == 0 && "selected-link"}`}
                ></div>
              </Link>
            </li>
            <li>
              <Link to={"/watch"} onClick={() => itemClick(1)}>
                Watch
                <div
                  className={`${currentPosition == 1 && "selected-link"}`}
                ></div>
              </Link>
            </li>
            <li>
              <Link to={"/my-bucket"} onClick={() => itemClick(2)}>
                myBucket
                <div
                  className={`${currentPosition == 2 && "selected-link"}`}
                ></div>
              </Link>
            </li>
            <li>
              <Link to={"/about"} onClick={() => itemClick(3)}>
                About
                <div
                  className={`${currentPosition == 3 && "selected-link"}`}
                ></div>
              </Link>
            </li>

            {user ? (
              <li>
                <Link to={"/my-profile"} onClick={() => itemClick(5)}>
                  myProfile
                  <div
                    className={`${currentPosition == 4 && "selected-link"}`}
                  ></div>
                </Link>
              </li>
            ) : (
              <li>
                <Link to={"/login"} onClick={() => itemClick(5)}>
                  Login
                  <div
                    className={`${currentPosition == 4 && "selected-link"}`}
                  ></div>
                </Link>
              </li>
            )}

            {user && user.role === "admin" && (
              <li>
                <Link to={"/admin"} onClick={() => itemClick(4)}>
                  Admin
                  <div
                    className={`${currentPosition == 5 && "selected-link"}`}
                  ></div>
                </Link>
              </li>
            )}
          </ul>

          {/* MENU ICON */}
          <div className="menu-btn" onClick={onMenuIconClick}>
            {/* <i>
              {showNavbar ? (
                <FiX className="tb-icon-grey" />
              ) : (
                <FiMenu className="tb-icon-grey" />
              )}
            </i> */}
            {/*  */}
            <div
              id="nav-icon4"
              className={`${showNavbar && "open"}`}
              // onClick={itemClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 5rem;
  z-index: 10;
  /* border: 2px solid green; */

  /* position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: white;
  box-shadow: var(--light-shadow); */

  .nav-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: white;
    box-shadow: var(--light-shadow);
    min-height: 5rem;
    z-index: 10;
    /* border: 2px solid red; */
  }
  .main-nav {
    /* border: 2px solid green; */

    /* position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: white;
    box-shadow: var(--light-shadow); */

    /* width: 100%; */
    max-width: 900px;
    min-height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* font-family: Arial, Helvetica, sans-serif; */
    transition: all 0.3s ease;
    /* padding: 0 8.8vw; */
    margin: 0 auto;
    z-index: 10; /*To keep navbar always above the any view/component, it's value should always be greater than other used to be on the top */
  }

  .sticky {
    ${"" /* padding: 15px 0; */}
    min-height: 4rem;
    /* background-color: crimson;  */
  }

  a {
    text-decoration: none;
  }
  .brand-name {
    font-size: 1.75rem;
    font-weight: bold;
    color: #002b51;
    color: var(--color--primary);
    text-decoration: none;
  }

  .right-nav {
    display: flex;
  }

  .right-nav li {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 32px;
  }

  .right-nav li a {
    color: #002b51;
    /* color: #999; */
    text-decoration: none;
    font-size: 18px;
  }

  .right-nav li a:hover {
    color: var(--navLinkHoverColor);
  }

  .menu-btn {
    display: none;
    /* color: white; */
    /* color: black; */
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .selected-link {
    border: 1px solid var(--navLinkHoverColor);
    border-radius: 2px;
  }

  /* Hover effect : expand bottom border 
  - https://www.sliderrevolution.com/resources/css-border-animation/
  - https://stackoverflow.com/questions/28623446/hover-effect-expand-bottom-border
  */
  li {
    position: relative;
  }

  li::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 2px;
    left: 50%;
    bottom: 0;
    margin-top: 4px;
    background-color: var(--navLinkHoverColor);
    transition: all ease-in-out 0.3s;
  }
  li:hover::after {
    width: 100%;
    left: 0;
  }

  #nav-icon4 {
    width: 28px;
    height: 24px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    /* border: 1px solid red; */
  }
  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #666;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  #nav-icon4 span:nth-child(1) {
    top: 0px;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(2) {
    top: 8px;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(3) {
    top: 16px;
    transform-origin: left center;
  }

  #nav-icon4.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -1px;
    left: 4px;
  }

  #nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon4.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 18px;
    left: 4px;
  }

  /* <===========================  Laptop  ===================> */
  @media screen and (max-width: 960px) {
    .main-nav {
      /* padding: 0; */
      /* margin: 0; */
      /* border: 1px solid red; */
    }

    .brand-name {
      /* margin-left: 30px; */
      font-size: 1.5rem;
    }

    .menu-btn {
      display: block;
      z-index: 10;
      /* margin-right: 30px; */
    }

    .right-nav {
      position: fixed;
      min-height: 55vh;
      width: 100%;
      ${"" /* left: -100%; */}
      ${"" /* top: 0; */}
      left: 0;
      top: -200%;
      background: linear-gradient(to right, #3a6073, #16222a);
      /* background: #002b51; */
      /* background: #111; */
      /* text-align: center; */
      padding: 30px 0 20px 0;
      margin: 0;
      transition: all 0.3s ease;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 10;
      /* border: 2rem solid red; */
    }
    .right-nav li {
      display: block;
      margin: 0;
      /* border: 2px solid red; */
    }

    .right-nav li a {
      display: inline-block;
      margin: 14px 0;
      font-size: 18px;
      color: #fff;
      ${"" /* border: 2px solid blue; */}
    }

    .show {
      ${"" /* left: 0; */}
      top: 0;
    }
  }

  /* <=========================== UILIT - mid   ===================> */
  @media screen and (min-width: 641px) and (max-width: 960px) {
    .brand-name {
      /* margin-left: 30px; */
      padding-left: 30px;
      font-size: 1.5rem;
    }
    .menu-btn {
      /* margin-right: 30px; */
      padding-right: 30px;
    }
  }

  /* <===========================  UIKIT - small  ===================> */
  @media screen and (max-width: 640px) {
    .brand-name {
      /* margin-left: 15px; */
      font-size: 1.5rem;
      padding-left: 15px;
    }
    .menu-btn {
      /* margin-right: 15px; */
      padding-right: 15px;
    }
  }
`;

const mediaQueryDeviceBreakPoint = styled.div`
  /* UIKIT - breakpoints 
    -s: 640 px [ mobile landscape & bigger ]
    -m : 960 px [ tablet landscape & bigger ]
    -l: 1200 px
    -xl: 1600px
*/
  /* UIKIT moves from mobile to desktop design principle, [mobile -> desktop] ; your case [desktop -> mobile]*/
  /* default one with no media query is mobile, while in your case its desktop*/
  @media (min-width: 640px) {
    .temp {
      background-color: #d6144f;
    }
  }
  /* Tablet landscape and bigger */
  @media (min-width: 960px) {
    .temp {
      background-color: #18b8f8;
    }
  }

  /* <===========================  Laptop  ===================> */
  @media screen and (max-width: 992px) {
  }
  /* <===========================  Tablet  ===================> */
  @media screen and (min-width: 577px) and (max-width: 768px) {
  }

  /* <===========================  Phone  ===================> */
  @media screen and (max-width: 576px) {
  }
`;

export default Navbar;
