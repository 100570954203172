import React, { useState, useEffect, useContext } from "react";
const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const cacheData = new Map();

  useEffect(() => {}, []);

  const addInCache = (key, value) => {
    cacheData.set(key, value);
  };

  const getFromCache = (key) => {
    return cacheData.get(key);
  };

  return (
    <AppContext.Provider value={{ cacheData, addInCache, getFromCache }}>
      {children}
    </AppContext.Provider>
  );
};

// make sure use
export const useAppContext = () => {
  return useContext(AppContext);
};

// export default functio useAppContext(){
//   return useContext(AppContext);
// };
