import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import { AppProvider } from "./contexts/appContext";

Sentry.init({
  dsn: "https://931e53a033454ca1b1859f3eccd2176c@o4504994175647744.ingest.sentry.io/4504994181414912",
  // dsn: process.env.REACT_APP_SENTRY_ID,
  integrations: [new BrowserTracing()],

  // TODO: Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <>
    <AppProvider>
      <App />
    </AppProvider>
  </>,
  document.getElementById("root")
);
