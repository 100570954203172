import React, { useState } from "react";
import styled from "styled-components";
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function DisclaimerPage() {
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Disclaimer - travelBucket </title>
      </Helmet>
      <h2>Disclaimer:</h2>
      <p>
        The information provided on <span className="item">TravelBucket </span>
        is for general informational purposes only. While we do our best to
        provide accurate and up-to-date information, we make no warranties or
        representations of any kind, express or implied, about the completeness,
        reliability, suitability, or availability of the information.
      </p>

      <p>
        Travel decisions based on the information provided are at the user's own
        risk. Users are encouraged to verify the accuracy of the information and
        exercise their own judgement when making travel decisions.
      </p>
      <p>
        <span className="item">TravelBucket </span> will not be liable for any
        loss or damage arising from the use of the website or the information
        provided. Links to external websites are provided for convenience, and
        we have no control over their content.
      </p>
      <p>
        Travel involves inherent risks, and users are advised to exercise
        caution, conduct thorough research, and comply with local laws and
        regulations. Official travel advisories and professional advice should
        be consulted.
      </p>

      <p>
        By using <span className="item">TravelBucket </span>, you agree to this
        disclaimer. If you do not agree, please refrain from using the website.
      </p>
      <p>This disclaimer is subject to change without notice. </p>
    </Wrapper>
  );
}

export default DisclaimerPage;

const Wrapper = styled.main`
  p {
    text-align: justify;
  }
  .item {
    font-weight: 500;
  }
`;
