// import imgNotFound from "../assets/image_not_found_3.jpg";
import imgNotFound from "../assets/image_not_found_1.png";
// import imgNotFound from "../assets/image_not_found_2.jpg";
// import imgNotFound from "../assets/image_not_found_4.webp";
import errorImg from "../assets/error.jpg";
const cacheList = [];
export const categories = [
  "Beach",
  "Museum",
  "Temple",
  "Nature",
  "Sights & attraction",
  "Shopping",
  "Water park",
];
const mockCategories = [
  "Beachs",
  "Adventure",
  "Arts",
  "Desert",
  "Entertainment",
  "Experiences",
  "Food & Drink",
  "History & Heritage",
  "Sports",
  "Theme Park",
  "Museums",
  "Nature",
  "NightLife",
  "Shopping",
];
export const placeTypes = [
  "maindestination",
  "finaldestination",
  "country",
  "state",
  "neighbour",
];

export const themes = [
  "Theme type",
  "Beach",
  "Romantic",
  "Heritage",
  "Adventure",
  "None",
];

export const mandatoryField = {
  required: {
    value: true,
    message: "field is required",
  },
};

export const errorImage = errorImg;
export const imageNotFound = imgNotFound;
export const defaultImage = imgNotFound;
// "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80";

export const defaultUserImage = "";
export const defaultBucketImage = "";
export const contactEmail = "hi.travelbucket@gmail.com";

export const addUser = (user, token) => {
  // In JS, if data exist means truth & null, undefined means false.
  // So, In boolean return type, you can return either true, false as well data
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
};

export const removeUser = () => {
  localStorage.clear();
  // localStorage.removeItem("user");
  // localStorage.removeItem("token");
};

export const getUser = () => {
  // const token = localStorage.getItem("token");
  const data = localStorage.getItem("user");
  const user = data ? JSON.parse(data) : null;
  return user;
};

function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export const addUserWithExpiry = (user, token) => {
  const now = new Date();
  // const expireTime = now.getTime() + process.env.REACT_APP_LOCALSTORAGE_EXPIRY_TIME;
  // const expireTime = now.getTime() + 24 * 60 * 60 * 1000; //24 hours expiry
  const expireTime = now.getTime() + 1 * 24 * 60 * 60 * 1000; //24 hours expiry [in milliseconds]
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
  localStorage.setItem("user-expiry", expireTime);
};

export const checkExpiryOfUser = () => {
  const expireTime = localStorage.getItem("user-expiry");
  if (!expireTime) {
    return;
  }
  const now = new Date();
  if (now.getTime() > expireTime) {
    localStorage.clear();

    // localStorage.removeItem("user-expiry");
    // localStorage.removeItem("user");
    // localStorage.removeItem("token");
    return;
  }
};
