import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser } from "./utility";

const AdminRoute = ({ component: Component, ...rest }) => {
  const user = getUser();
  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.role === "admin" ? ( //user.role === 1
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
