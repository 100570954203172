import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FiPlay } from "react-icons/fi";
import { defaultImage, errorImage } from "../utils/utility";

// TODO: room for code optimization [video list on main-page & only modal component code here i.e., VideoModal]
function CompleteVideoCompoent({ video }) {
  const { video_name, video_thumbnail, video_url } = video;
  const [isShow, setShow] = useState(false);
  const [videoUrl, setVideoUrl] = useState(video_url);

  const [showModal, setShowModal] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  // How to Stop YouTube Video when closing modal
  // source url: [ https://stackoverflow.com/questions/47537088/stop-youtube-video-when-closing-modal ]
  // step 1. Get iframe src attribute value i.e. YouTube video url and store it in a variable
  // step 2. Assign empty url value to the iframe src attribute when modal hide, which stop the video playing
  // step 3. Assign the initially stored url back to the iframe src attribute when modal is displayed again

  const playVideo = () => {
    // setVideoUrl(video_url);
    // setShow(true);
    setShowModal(true);
    setClickCount(clickCount + 1);
  };

  const closeVideo = () => {
    setShow(false);
    setVideoUrl("");
  };

  return (
    <Wrapper>
      <div className="video-container">
        <img
          src={video_thumbnail}
          alt={video_name}
          onError={(e) => {
            if (e.target.src !== errorImage) {
              e.target.src = errorImage;
            }
          }}
        />
        <h5 className="uk-margin-small-top uk-margin-small-bottom">
          {video_name}
        </h5>

        <button
          className="play-button"
          onClick={playVideo}
          // data-uk-toggle="target: #modal-id"
          data-uk-tooltip="title: click to watch; pos: bottom-left"
        >
          <FiPlay className="play-icon"></FiPlay>
        </button>

        {/* <button className="play-button" data-uk-lightbox>
          <a
            data-type="iframe"
            href={videoUrl}
            // href="https://www.youtube.com/watch?v=c2pz2mlSfXA"
          >
            <FiPlay className="play-icon"></FiPlay>
          </a>
        </button> */}
      </div>

      {showModal && (
        <VideoModal
          videoName={video_name}
          videoUrl={video_url}
          clickCount={clickCount}
        />
      )}

      {/* <!-- This is the modal with the default close button --> */}
      {/* <div id="modal-id" data-uk-modal>
        <div class="uk-modal-dialog uk-modal-body">
          <button
            class="uk-modal-close-default"
            type="button"
            data-uk-close
          ></button>
          <h2 class="uk-modal-title">Watch your video</h2>
          <p>You can watch video here...</p>

          <iframe
            src={videoUrl}
            width="1920"
            height="1080"
            data-uk-video
            data-uk-responsive
          ></iframe>
          <iframe
            width="560"
            height="315"
            // src="https://www.youtube.com/embed/kuniEyt3Qks"
            src={videoUrl}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div> */}
    </Wrapper>
  );
}

const VideoModal = ({ videoName, videoUrl, clickCount }) => {
  const [showModal, setShowModal] = useState(true);
  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    setShowModal(true);
  }, [clickCount]);

  return (
    <ModalWrapper>
      <div className={`${showModal ? "modal show" : "modal"}`}>
        <div class="modal-content">
          {/* <span class="close" onClick={closeModal}>
            &times;
          </span> */}
          <button
            class="close"
            type="button"
            onClick={closeModal}
            data-uk-close
          ></button>

          <h3>{videoName}</h3>
          <p>You can watch video here...</p>
          <iframe
            // className="frame-background"
            src={videoUrl}
            width="1920"
            height="1080"
            data-uk-video
            data-uk-responsive
          ></iframe>
        </div>
      </div>
    </ModalWrapper>
  );
};

const Wrapper = styled.article`
  /* background-color: "#1a5e96"; */

  .video-container {
    position: relative;
    /* background: #1a5e96; */
    /* background-color: #8613e4; */
  }
  .video-container img {
    /* width: 100%; */
    /* height: 100%; */
    /* background-color: "#1a5e96"; */
    object-fit: cover;
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }

  .play-button {
    position: absolute;
    top: 30%;
    left: 40%;
    width: 4rem;
    height: 4rem;
    /* background: #000000; */
    background: #0f294d80;
    border-radius: 2rem;
    color: var(--clr-white);
    padding: 8px 8px;
    font-size: 1rem;
    border-color: transparent;
    cursor: pointer;
    z-index: 1;
  }
  .play-button:hover {
    /* background: #443d3d; */
    background: #1b375e80;
  }
  .play-icon {
    /* color: #9aa0a6; */
    color: #fff;
    width: 1.4rem;
    height: 1.4rem;
    /* cursor: pointer; */
  }
  .play-icon:hover {
    width: 1.6rem;
    height: 1.6rem;
  }

  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    padding-top: 100px; /*Location of the box */
    left: 0;
    top: 0;
    width: 100%; /*Full width */
    height: 100%; /*Full height*/
    overflow-y: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .modal-content {
    /* display: inline; */
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    width: 60%;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  /* Add Animation */
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  .show {
    display: block;
  }
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 2rem;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

const ModalWrapper = styled.div`
  .frame-background {
    background-color: #b61919;
  }
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    padding-top: 100px; /*Location of the box */
    left: 0;
    top: 0;
    width: 100%; /*Full width */
    height: 100%; /*Full height*/
    overflow-y: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .modal-content {
    /* display: inline; */
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    width: 60%;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  /* Add Animation */
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  .show {
    display: block;
  }
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 2rem;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default CompleteVideoCompoent;
