import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Redirect, useParams, useHistory } from "react-router-dom";

import {
  mandatoryField,
  themes,
  placeTypes,
  categories,
} from "../../../utils/utility";
import Loading from "../../../components/Loading";
import useApi from "../../../utils/custom-hooks/useApi";
import { finalDestinations } from "../../../mockDb";

function AddSubDestination() {
  const { mainDestinationId } = useParams();
  const [redirect, setRedirect] = useState(false);

  const [travelTip, setTravelTip] = useState("");
  const [travelTipList, setTravelTipList] = useState([]);

  const [notToMiss, setNotToMiss] = useState("");
  const [notToMissList, setNotToMissList] = useState([]);

  const history = useHistory();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  //pre-filling fields for testing
  useEffect(() => {
    // const places = finalDestinations[0];
    // const dest = places[0];
    // setValue("placeName", dest.place_name);
    // setValue("placeOverview", dest.place_info);
    // setValue("placeUniqueness", dest.place_uniqueness);
    // setValue("whatPlaceOffers", dest.what_place_offers);
    // setValue("countryRadBtn", "yes");
    // setValue("stateRadBtn", "no");
    // setValue("mainDestinationRadBtn", "yes");
    // setValue("categoryType", "Temple");
    // setValue("streetAddress", "MB 42 a37 street kualalampur");
    // setValue("latLong", "136.78, 439.54");
    // setValue("country", "India");
    // setValue("state", "JKD");
    // setTravelTipList(dest.travel_tips);
    // setNotToMissList(dest.not_to_miss);
    //
    //
    // setValue("placeType", "finaldestination");
  }, []);

  const onSubmit = async (data) => {
    const pCountry = data.countryRadBtn === "yes" ? true : false;
    const pState = data.stateRadBtn === "yes" ? true : false;
    const pMainDestination =
      data.mainDestinationRadBtn === "yes" ? true : false;

    const address = {
      street_address: data.streetAddress,
      lat_long: data.latLong,
      country: data.country,
      state: data.state,
    };
    const dest = {
      place_name: data.placeName,
      place_uniqueness: data.placeUniqueness,
      place_info: data.placeOverview,
      // what_place_offers: data.whatPlaceOffers,
      not_to_miss: notToMissList,
      travel_tips: travelTipList,
      popular_in_country: pCountry,
      popular_in_state: pState,
      popular_in_main_destination: pMainDestination,
      category_type: data.categoryType,
      place_type: "finaldestination",
      destination_type: "finaldestination",
      is_published: true,
      address: address,
      main_destination_id: mainDestinationId,
    };

    await makeApiCall("POST", `/admin/final-destination`, dest);
    setTimeout(() => {
      if (resultRef.current) {
        if (resultRef.current.status) {
          alert(`SUCCESS:  ${resultRef.current.message}`);
          history.replace(
            `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
          );
          // setRedirect(true);
          // submittedFinalDestionation(resultRef.current.destination);
        } else {
          alert(`FAIL: ${resultRef.current.message}`);
        }
      }
    }, 1000);
  };

  const addTravelTip = (e) => {
    e.preventDefault();
    if (!travelTip) {
      alert("Enter info");
      return;
    }
    setTravelTipList([...travelTipList, { item: travelTip }]);
    setTravelTip("");
  };

  const addNotToMiss = (e) => {
    e.preventDefault();
    if (!notToMiss) {
      alert("Enter info");
      return;
    }
    setNotToMissList([...notToMissList, { item: notToMiss }]);
    setNotToMiss("");
  };

  const createSubDestinationForm = () => {
    return (
      <div>
        <form
          class="uk-form-stacked"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="uk-text-success ">Key Info(fields)</p>

          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Place name..."
              // onChange={(e) => setName(e.target.value)}
              // value={name}
              {...register("placeName", {
                required: {
                  value: true,
                  message: "field is required",
                },
                minLength: {
                  value: 4,
                  message: "Place name must be at least 4 characters",
                },
              })}
            />
            <span className="uk-text-danger">{errors.placeName?.message}</span>
          </div>

          {/* <-- Uniqueness --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Uniqueness</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Place uniqueness with 3-4 words..."
              // onChange={(e) => setPlaceUniqueness(e.target.value)}
              // value={placeUniqueness}
              {...register("placeUniqueness", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.placeUniqueness?.message}
            </span>
          </div>

          {/* <-- Overview (Place Info) --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Overview ( place info in short )
            </label>
            <textarea
              class="uk-textarea"
              rows="4"
              placeholder="Place overview..."
              // onChange={(e) => setOverview(e.target.value)}
              // value={overview}
              {...register("placeOverview", mandatoryField)}
            ></textarea>
            <span className="uk-text-danger">
              {errors.placeOverview?.message}
            </span>
          </div>

          {/* <-- What place offers: field is deprecated or removed & no longer use --> */}
          {/* <div class="uk-margin">
            <label class="uk-form-label">
              What place Offers (deprecated fireld - remove)
            </label>
            <textarea
              class="uk-textarea"
              type="text"
              rows="4"
              placeholder="what are the things place offers..."
              // onChange={(e) => setPlaceOffers(e.target.value)}
              // value={placeOffers}
              {...register("whatPlaceOffers")}
            />
            <span className="uk-text-danger">
              {errors.whatPlaceOffers?.message}
            </span>
          </div> */}

          {/* <-- Not to miss --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Not to miss ( bullet list using regrex or other way)
            </label>
            <input
              class="uk-input"
              type="text"
              placeholder="things not to miss in the place..."
              onChange={(e) => setNotToMiss(e.target.value)}
              value={notToMiss}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={addNotToMiss}
            >
              Add
            </button>
            <ul>
              {notToMissList.map(({ item }) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>

          {/* <-- Travel tips --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Travel tips (bullet list, optional)
            </label>
            <input
              class="uk-input"
              type="text"
              placeholder="suggestion or info aware to be, you want to include..."
              onChange={(e) => setTravelTip(e.target.value)}
              value={travelTip}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={addTravelTip}
            >
              Add
            </button>
            <ul>
              {travelTipList.map(({ item }) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Secondary Info(fields)
          </p>
          {/* <-- popular in country --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in country</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("countryRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("countryRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.countryRadBtn?.message}
            </span>
          </div>

          {/* <-- popular in state --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in state</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("stateRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("stateRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.stateRadBtn?.message}
            </span>
          </div>

          {/* <-- pupular in main destination --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in main destination</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("mainDestinationRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("mainDestinationRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.mainDestinationRadBtn?.message}
            </span>
          </div>

          {/* <-- Category type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Category Type</label>
            <select
              class="uk-select"
              id="form-stacked-select"
              {...register("categoryType", { required: true })}
            >
              {categories.map((category) => {
                return <option>{category}</option>;
              })}
            </select>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>

          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Place Type (FIXED - finaldestination)
            </label>
            {/* <select
              class="uk-select"
              id="form-stacked-select"
              disabled
              {...register("placeType", { required: true })}
            >
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select> */}
          </div>

          {/* <-- destination type --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">
              Destination type (FIXED - finaldestination)
            </label>
            <label>
              <input class="uk-radio" type="radio" name="radio2" disabled />
              main destination
            </label>
            <label>
              <input class="uk-radio" type="radio" name="radio2" checked />
              final destination
            </label>
            <label>
              <input class="uk-radio" type="radio" name="radio2" disabled />
              none
            </label>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>

          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
              // onChange={(e) => setAddress(e.target.value)}
              // value={address}
              {...register("streetAddress", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.streetAddress?.message}
            </span>
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="lat-long (optional)"
                // onChange={(e) => setLatLong(e.target.value)}
                // value={latLong}
                {...register("latLong")}
              />
            </div>

            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="country"
                // onChange={(e) => setCountry(e.target.value)}
                // value={country}
                {...register("country", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.country?.message}</span>
            </div>

            <div>
              <input
                class="uk-input"
                type="text"
                placeholder="state"
                // onChange={(e) => setState(e.target.value)}
                // value={state}
                {...register("state", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.state?.message}</span>
            </div>
          </div>

          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            // onClick={handleSubmit}
            type="submit"
          >
            submit
          </button>
        </form>
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <h2 className="uk-margin-small-top">Final Destination Form</h2>
      {createSubDestinationForm()}
      {showLoading()}
      {errorMessage()}
      {/* {redirect && (
        <Redirect to={`/admin/destination-listing/${mainDestinationId}`} />
      )} */}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  background: #f2f2f2;
  margin-top: 2rem;
`;
export default AddSubDestination;
