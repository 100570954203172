import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import CompleteVideoCompoent from "../components/CompleteVideoComponent";
import { videoList } from "../mockDb";
import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";
import CompleteVideoComponentNew from "../components/CompleteVideoComponentNew";
import { toast } from "react-hot-toast";

const url = "http://localhost:5000/destinations-videos";

function Watch() {
  const [isShow, setShow] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  // const [error, setError] = useState(false);

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  const fetchDestinationVideos = async (language) => {
    // await makeApiCall("GET", `/videos/${language}`);
    await makeApiCall("GET", `/videos`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setVideos(resultRef.current.videos);
        // setVideos(videoList.filter((item) => item.language == language));
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  useEffect(() => {
    fetchDestinationVideos("english");
  }, []);

  const selectChangeListener = (e) => {
    const selectedLanguage = e.target.value;
    fetchDestinationVideos(selectedLanguage);
    // console.log("WATCH --------------------- ", t);
  };

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
          </div>
        </>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // TODO: Add play icon at center of image with animation just like in glance
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Watch - travelBucket </title>
      </Helmet>

      {/* HEADING SECTION */}
      <div className="uk-flex uk-flex-row uk-flex-between change-direction">
        <h3 className="">Discover places by watching videos</h3>

        <div className="language">
          <label for="items">Preferred language: </label>
          <select
            // className="uk-select"
            name="items"
            id="items"
            onChange={selectChangeListener}
          >
            {/* <option value="english">select language</option> */}
            <option value="english">English</option>
            <option value="hindi">Hindi</option>
          </select>
        </div>
      </div>

      {showLoading()}
      {errorMessage()}

      {/* VIDEO LIST SECTION  */}
      {!isLoading && videos.length == 0 && <p>Nothing found 😞</p>}
      {videos.length != 0 && <CompleteVideoComponentNew videos={videos} />}
      {/* <section
        className="uk-grid-match uk-grid-row-medium uk-grid-column-small uk-child-width-1-2@s uk-child-width-1-3@m"
        data-uk-grid
        data-uk-scrollspy="cls: uk-animation-fade; target: .video-container; delay: 200; repeat: true"
      >
        {videos.map((video) => {
          return <CompleteVideoCompoent video={video} />;
        })}
      </section> */}

      {/* TODO: Add play icon at center of image with animation just like in glance*/}
      {/* <h4 style={{ color: "red" }}>
        Add play icon at center of image with animation just like in glance
      </h4> */}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  select {
    padding: 0.4rem 0.5rem;
    font-size: 0.9rem;
  }
  @media screen and (max-width: 576px) {
    .change-direction {
      flex-direction: column;
    }
    .language {
      margin-bottom: 1rem;
    }
  }
`;
export default Watch;
