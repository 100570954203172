import React, { useState } from "react";
import styled from "styled-components";
// import { HiPencil, HiArchiveBoxXMark } from "react-icons/hi2";
import { FiEdit2, FiDelete, FiCheck } from "react-icons/fi";

function AddCategoryNTheme() {
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);

  const categorySubmitHandler = () => {
    setCategories([...categories, category]);
    setCategory("");
  };

  const editCategoryHandler = () => {
    setCategory("");
  };

  const deleteCategoryHandler = () => {
    setCategory("");
  };

  const categorySegment = () => {
    return (
      <div className="">
        <h2>Categories</h2>
        <div className="uk-flex uk-flex-between">
          <input
            class="uk-input"
            type="text"
            placeholder="Category name..."
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          />
          <button
            className="uk-button uk-button-secondary"
            onClick={categorySubmitHandler}
          >
            submit
          </button>
        </div>

        <div className="list">
          {categories.map((category) => {
            return (
              <div className="uk-flex uk-flex-middle border-bottom">
                <p className="uk-text-lead uk-width-3-4 uk-margin-small-top  uk-margin-small-bottom">
                  {category}
                </p>
                <div className="uk-width-1-4 uk-flex uk-flex-middle uk-flex-between">
                  <FiEdit2
                    className="tb-icon"
                    onClick={editCategoryHandler}
                  ></FiEdit2>
                  <FiDelete
                    className="tb-icon"
                    onClick={deleteCategoryHandler}
                  ></FiDelete>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const themeSegment = () => {
    return (
      <div className="">
        <h3>Themes(delete this temp words)</h3>
      </div>
    );
  };

  //   MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <section
        className="uk-grid-divider uk-child-width-1-2 uk-margin-medium-top uk-margin-medium-bottom"
        data-uk-grid
      >
        {categorySegment()}
        {themeSegment()}
      </section>
    </Wrapper>
  );
}

export default AddCategoryNTheme;

const Wrapper = styled.main`
  background: #f2f2f2;
`;
