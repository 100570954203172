import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { defaultImage } from "../utils/utility";

const imgs = [
  "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171287/destinations/yqb4cbuindje2bdtyjfn.jpg",
  "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171357/destinations/qvloaph0w6m9vshs2can.jpg",
  "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171359/destinations/cmwg6q8oafgcxijp5wxo.jpg",
];

function HorizontalImageGallery({ imageList }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [item, setItem] = useState(imageList[currentIndex]);
  const item = useRef(imageList[currentIndex]);

  const leftButtonClick = () => {
    setCurrentIndex((oldValue) => {
      let newValue;

      if (oldValue > 0) {
        newValue = oldValue - 1;
        item.current = imageList[newValue];
        return newValue;
      } else {
        newValue = imageList.length - 1;
        item.current = imageList[newValue];
        return newValue;
      }
    });

    // if (currentIndex > 0) {
    //   setCurrentIndex(currentIndex - 1);
    // } else {
    //   setCurrentIndex(imageList.length - 1);
    // }

    // setItem(imageList[currentIndex]);
    // item.current = imageList[currentIndex];
  };

  const rightButtonClick = () => {
    let newValue;
    setCurrentIndex((oldValue) => {
      if (oldValue < imageList.length - 1) {
        newValue = oldValue + 1;
        item.current = imageList[newValue];
        return newValue;
      } else {
        newValue = 0;
        item.current = imageList[newValue];
        return newValue;
      }
    });

    // if (currentIndex < imageList.length - 1) {
    //   setCurrentIndex(currentIndex + 1);
    // } else {
    //   setCurrentIndex(0);
    // }

    // console.log("INDEX: ", currentIndex);

    // setItem(imageList[currentIndex]);
    // item.current = imageList[currentIndex];
  };

  const slideShow = () => {
    // TODO: setting height 24rem on outer container creates issue due to inner-container has min-height set 480px programatically
    return (
      <div
        className="uk-position-relative uk-visible-toggle uk-light outer-container"
        tabIndex="-1"
        data-uk-slideshow="animation: slide"
      >
        <ul
          className="uk-slideshow-items inner-container"
          // style={innerContainer}
          data-uk-lightbox
        >
          {imageList.map((image, index) => {
            return (
              <li key={image.image_id}>
                {/* <img
                  className="tb-image"
                  src={image.image_url}
                  alt=""
                /> */}
                <a
                  // class="uk-button uk-button-default"
                  href={image.image_url}
                  // data-caption={
                  //   item.current &&
                  //   item.current.source_name &&
                  //   `source: ${item.current.source_name}`
                  // }
                >
                  <img
                    loading="lazy"
                    className="tb-image"
                    src={image.image_url}
                    alt=""
                  />
                </a>
              </li>
            );
          })}
        </ul>

        <button
          className="left-button"
          href="#"
          // data-uk-slidenav-previous
          data-uk-slideshow-item="previous"
          onClick={leftButtonClick}
        >
          <FiArrowLeft className="play-icon"></FiArrowLeft>
        </button>
        <button
          className="right-button"
          href="#"
          // data-uk-slidenav-next
          data-uk-slideshow-item="next"
          onClick={rightButtonClick}
        >
          <FiArrowRight className="play-icon"></FiArrowRight>
        </button>
        {/* {
            "courtesy: author-name/platform - use ROBOT TYPE FONT LIKE IN GETYOURGUIDE HEADER"
          } */}
        <pre className="image-courtesy">
          source:{" "}
          <a href={item.current.source_url} target="_blank">
            {item.current &&
              item.current.source_name &&
              `${item.current.source_name}`}
          </a>
        </pre>
        <p className="image-count">
          <span className="large-font">{currentIndex + 1}</span> /
          {imageList.length}
        </p>
      </div>
    );
  };

  const version1 = () => {
    return (
      <>
        {/* {
            "courtesy: author-name/platform - use ROBOT TYPE FONT LIKE IN GETYOURGUIDE HEADER"
          } */}
        {/* <section className="image-gallery">
        <img
          src={item.current ? item.current.image_url : defaultImage}
          alt={item.current ? item.current.name : "default name"}
          className="image"
        />
        <button className="left-button" onClick={leftButtonClick}>
          <FiArrowLeft className="play-icon"></FiArrowLeft>
        </button>
        <button className="right-button" onClick={rightButtonClick}>
          <FiArrowRight className="play-icon"></FiArrowRight>
        </button>

        <p className="image-courtesy">
          {item.current &&
            item.current.source_name &&
            `source: ${item.current.source_name}`}
        </p>

        <p className="image-count">
          <span className="large-font">{currentIndex + 1}</span> /
          {imageList.length}
        </p>
      </section> */}
      </>
    );
  };

  return <Wrapper>{slideShow()}</Wrapper>;
}

export default HorizontalImageGallery;

const Wrapper = styled.div`
  /* background-color: #ebf0f4; */
  .outer-container {
    position: relative;
    height: 24rem;
    overflow: hidden;
    /* border: 2px solid red; */
  }
  .inner-container {
    background: var(--imageBackgroundColor);
    height: 24rem;
    /* border: 2px solid blue; */
  }
  .tb-image {
    width: 100%;
    height: 24rem;
    object-fit: cover;
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }
  .image-gallery {
    width: 100%;
    height: 20rem;
    position: relative;
    margin-bottom: 2rem;
  }

  .left-button {
    position: absolute;
    /* left: -1.5rem; */
    /* top: 40%; */

    left: 0.5rem;
    top: 45%;

    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    border: none;
    /* margin-right: 14px; */
    width: 3rem;
    height: 3rem;
    /* line-height: 58px; */
    /* background-color: #000; */
    background-color: #0f294d80;
    /* background-color: #b90c0c; */
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 2px 4px 0rgba (0, 0, 0, 0.1);
  }

  .right-button {
    position: absolute;
    /* right: -1.5rem; */
    /* top: 40%; */

    right: 0.5rem;
    top: 45%;

    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    border: none;
    width: 3rem;
    height: 3rem;
    /* background-color: #000; */
    /* background-color: #ee1111; */
    background-color: #0f294d80;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 2px 4px 0rgba (0, 0, 0, 0.1);
  }
  .left-button:hover,
  .right-button:hover {
    background: #1b375e80;
  }

  .play-icon {
    color: #fff;
    width: 1.2rem;
    height: 1.2rem;
  }
  .right-button:hover .play-icon,
  .left-button:hover .play-icon {
    width: 1.4rem;
    height: 1.4rem;
  }

  .large-font {
    font-size: 0.8rem;
  }
  .image-courtesy {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    display: inline;
    /* font-family: "Roboto"; */
    font-size: 0.75rem;
    background-color: #3d3d3d;
    color: #fff;
    padding: 2px 6px;
    border-radius: 4px;
    margin: 0;
    border: 0;
  }
  .image-count {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    display: inline;
    font-size: 0.75rem;
    background-color: #3d3d3d;
    color: #fff;
    padding: 2px 6px;
    border-radius: 4px;
    margin: 0;
  }

  @media (max-width: 425px) {
    .outer-container,
    .inner-container,
    .tb-image {
      height: 16rem;
    }

    .left-button,
    .right-button {
      width: 2.5rem;
      height: 2.5rem;
    }
    .play-icon {
      width: 1.1rem;
      height: 1.1rem;
    }
    .right-button:hover .play-icon,
    .left-button:hover .play-icon {
      width: 1.1rem;
      height: 1.1rem;
    }
    .image-courtesy {
      bottom: 0.25rem;
      left: 0.25rem;
      font-size: 0.65rem;
      padding: 2px 6px;
      border-radius: 2px;
    }
    .image-count {
      bottom: 0.25rem;
      right: 0.25rem;
      font-size: 0.65rem;
      padding: 2px 6px;
      border-radius: 2px;
    }
    .large-font {
      font-size: 0.7rem;
    }
  }
`;
