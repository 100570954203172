import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FiPlay } from "react-icons/fi";
import { imageNotFound } from "../utils/utility";
import CustomModal from "./CustomModal";
import useModal from "../utils/custom-hooks/useModal";

function CompleteVideoComponentNew({ videos }) {
  const { isShow, toggleModal } = useModal();
  const [videoName, setVideoName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const playVideo = (name, url) => {
    setVideoName(name);
    setVideoUrl(url);
    toggleModal();
  };

  const notifyCloseModal = () => {
    setVideoUrl("");
  };

  return (
    <Wrapper>
      {/* VIDEO LIST SECTION  */}
      <section
        className="uk-grid-match uk-grid-row-medium uk-grid-column-small uk-child-width-1-2@s uk-child-width-1-3@m"
        data-uk-grid
        data-uk-scrollspy="cls: uk-animation-fade; target: .video-container; delay: 200;"
      >
        {videos.map(({ video_id, video_name, video_thumbnail, video_url }) => {
          return (
            <div className="video-container" key={video_id}>
              <img
                src={video_thumbnail}
                alt={video_name}
                onError={(e) => {
                  if (e.target.src !== imageNotFound) {
                    e.target.src = imageNotFound;
                  }
                }}
              />
              <h5 className="uk-margin-small-top uk-margin-small-bottom">
                {video_name}
              </h5>

              <button
                className="play-button"
                onClick={() => playVideo(video_name, video_url)}
                data-uk-tooltip="title: click to watch; pos: bottom-left"
              >
                <FiPlay className="play-icon"></FiPlay>
              </button>
            </div>
          );
        })}
      </section>

      {/* MODAL SECTION */}
      <CustomModal
        isShow={isShow}
        closeModal={toggleModal}
        notifyCloseModal={notifyCloseModal}
      >
        <div>
          <h3 className="uk-margin-small-top">{videoName}</h3>
          <p>You can watch video here...</p>
          <iframe
            // className="frame-background"
            src={videoUrl}
            width="1920"
            height="1080"
            data-uk-video
            data-uk-responsive
          ></iframe>
        </div>
      </CustomModal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* background-color: "#1a5e96"; */

  .video-container {
    position: relative;
    /* background: #1a5e96; */
    /* background-color: #8613e4; */
  }
  .video-container img {
    /* width: 100%; */
    height: 12rem;
    /* background-color: #1a5e96; */
    background: var(--imageBackgroundColor);
    object-fit: cover;
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }

  .play-button {
    position: absolute;
    top: 30%;
    left: 40%;
    width: 4rem;
    height: 4rem;
    /* background: #000000; */
    background: #0f294d80;
    border-radius: 2rem;
    color: var(--clr-white);
    padding: 8px 8px;
    font-size: 1rem;
    border-color: transparent;
    cursor: pointer;
    z-index: 1;
  }
  .play-button:hover {
    /* background: #443d3d; */
    background: #1b375e80;
  }
  .play-icon {
    /* color: #9aa0a6; */
    color: #fff;
    width: 1.4rem;
    height: 1.4rem;
    /* cursor: pointer; */
  }
  .play-button:hover .play-icon {
    width: 1.6rem;
    height: 1.6rem;
  }
  @media screen and (max-width: 640px) {
    h3 {
      font-size: 1.12rem;
    }
  }
`;
export default CompleteVideoComponentNew;
