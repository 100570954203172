import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Redirect, Link } from "react-router-dom";
import thankYouGif from "../assets/thank_you.gif";

const thanksList = [
  {
    name: "makemytrip",
    url: "https://www.makemytrip.com/tripideas/",
  },
  {
    name: "lonelyplanet",
    url: "https://www.lonelyplanet.com/places",
  },
  {
    name: "footer animation credit",
    url: "https://codepen.io/uiswarup/pen/oNNMedZ",
  },
];

function ThankYou() {
  const [show, setShow] = useState(false);

  const itemClick = () => {
    console.log("ITEM CLICK");
    setShow(!show);
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Thank Page - travelBucket </title>
      </Helmet>

      <div className="wrap-container">
        <h3 className="uk-margin-small-top uk-margin-remove-bottom">
          Special thanks,
        </h3>

        <p className="uk-margin-small-top uk-margin-small-bottom">
          Special thanks to all the platforms and individuals who have played a
          role for inspiring & influencing during the development journey of
          this platform.
        </p>

        <ul className="uk-list uk-list-square uk-margin-remove-top">
          {thanksList.map((it, index) => {
            return (
              <li>
                <a
                  key={index}
                  href={it.url}
                  className="item-text"
                  target="_blank"
                >
                  {it.name}
                </a>
              </li>
            );
          })}
        </ul>

        <p>
          Additionally, I would like to acknowledge and thank all the other
          platforms and individuals who have influenced me along the way, even
          if not explicitly mentioned.
        </p>
        {/* <iframe src="https://giphy.com/embed/5ArJanyCfxgiY" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/pusheen-5ArJanyCfxgiY">via GIPHY</a></p> */}
        {/* <h4 style={{ color: "red" }}>Can you add thanks animation or gif?</h4> */}

        <img
          src={thankYouGif}
          alt="expressing thank you..."
          className="image_gif"
        />
        <p>
          <a href="https://www.gifcen.com/thank-you-gif-11" target="_blank">
            via GIFcen
          </a>
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .image_gif {
    width: 40%;
    height: 30%;
    margin-top: 3rem;
    // object-fit: cover;
  }

  .dot {
    border: 1px;
    border-style: solid;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-color: black;
    color: black;
    padding: 5px;
    background-size: 200% 200%;
    background-image: linear-gradient(to top, #76daff 50%, transparent 50%);
    transition: background-position 3000ms, color 3000ms ease,
      border-color 3000ms ease;
  }

  .dot:hover {
    color: white;
    border-color: black;
    background-image: linear-gradient(to top, #76daff 51%, transparent 50%);
    background-position: 0 100%;
    transition: background-position 3000ms, color 3000ms ease,
      border-color 3000ms ease;
  }

  .wrap-container {
    background: #ebf0f4;
    padding: 1rem;
  }

  .item-text {
    color: var(--color--text);
    cursor: pointer;
  }
  .item-text:hover {
    text-decoration: underline;
  }
`;

export default ThankYou;
