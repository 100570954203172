import { useState, useEffect, useRef } from "react";
import axios from "../axiosConfig";

/**
 * post request API call
 * @param {end-point of api} url
 * @param {req-data} req
 * @returns isLoading, error, data
 */
// https://stackoverflow.com/questions/61377927/react-hook-correct-way-of-using-custom-hook-to-handle-onclick-event
// https://stackoverflow.com/questions/65472666/calling-custom-hook-in-event-handler
// https://stackoverflow.com/questions/70195659/how-do-i-use-my-custom-usefetch-hook-when-a-button-is-clicked

// IN CONSOLE, You don't get LIVE(updated) values of a useState variable.
const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ show: false, msg: "" });
  const [result, setResult] = useState(null);
  const resultRef = useRef(null);

  const makeApiCall = async (reqType, url, req) => {
    // remove previous api call DATA [ once api call is consumed successfully, delete data(re-initialize to its initial state) so that you get fresh values ]
    //  will be useful when you call the method  2nd,3rd or more times
    if (resultRef.current) {
      resultRef.current = null;
      setResult(null);
    }
    setError({ show: false, msg: "" });

    // reqType = "POST";
    setIsLoading(true);
    const token = localStorage.getItem("token");
    // const token =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmMTI5YWJkYjg0MzAxMTFkODFmNzUiLCJpYXQiOjE2ODU1MzEzODYsImV4cCI6MTY4ODEyMzM4Nn0.FvFgqG5KvX4ppNTVMOgOWFIBP-vsOV5V4AT796wwndo";

    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    let apiResponse;
    try {
      if (reqType === "GET") {
        apiResponse = await axios.get(url, config);
      } else if (reqType === "POST") {
        apiResponse = await axios.post(url, req, config);
      } else if (reqType === "PUT") {
        apiResponse = await axios.put(url, req, config);
      } else if (reqType === "DELETE") {
        apiResponse = await axios.delete(url, config);

        // apiResponse = await axios.delete(url, req, config);
      } else {
        throw Error("No reqType(HTTP verbs) passed in api call");
      }

      const data = apiResponse.data;
      // const { data } = await axios.post(url, req, config);
      //   const response = await axios.post(url, req, config);
      //   const data = response.data;

      if (data.status.toString() === "true") {
        resultRef.current = data;
        setResult(data);

        // setError({ show: true, msg: `success IF - ${data.message}` });
      } else {
        setError({ show: true, msg: `${data.message}` });

        // setTimeout(() => {
        //   setError({ show: false, msg: "" });
        // }, 3000);

        // setError({ show: true, msg: `success ELSE - ${data.message}` });
      }

      setIsLoading(false);
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      setIsLoading(false);
      setError({ show: true, msg: `${message}` });

      // setTimeout(() => {
      //   setError({ show: false, msg: "" });
      // }, 3000);

      // setError({ show: true, msg: `ERROR EXCEPTION - ${message}` });
    }
  };

  // once api call is consumed successfully, delete data(re-initialize to its initial state) so that you get fresh values
  const deleteData = () => {
    if (resultRef.current) {
      resultRef.current = null;
    }
  };

  // useEffect(() => {
  //   apiCall();
  // }, [url]);

  return { isLoading, error, result, resultRef, makeApiCall };
};

export default useApi;
