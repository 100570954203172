import React, { useState, useRef } from "react";
import styled from "styled-components";

const articles = () => {
  // (thumb & full image, image container background color) - https://frontend-digest.com/progressively-loading-images-in-react-107cb075417a
  //  https://kentcdodds.com/blog/building-an-awesome-image-loading-experience#layout-shift
  // (react-cloudinary-lazy-image library) - https://teacode.io/blog/speed-up-your-react-app-using-cloudinary-and-lazy-loading/
  // (react-cool-img library) - https://hackernoon.com/a-easy-way-to-handle-image-ux-and-performance-with-reactjs-introducing-react-cool-img-u2jt362o
  // (google developer blog)  - https://developer.chrome.com/blog/image-component/
  // (Progressive image loading theory only) - https://blog.logrocket.com/progressive-image-loading-react-tutorial/
};

const list = [
  {
    name: "image name",
    url: "https://re.cloudinary.com/dbeeghycn/image/upload/v1671171287/destinations/yqb4cbuindje2bdtyjfn.jpg",
  },
  {
    name: "image name ",
    url: "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171357/destinations/qvloaph0w6m9vshs2can.jpg",
  },
  {
    name: "image name",
    url: "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171359/destinations/cmwg6q8oafgcxijp5wxo.jpg",
  },
  {
    name: "image name 1",
    url: "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
  },
  {
    name: "image 2",
    url: "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
  },
  {
    name: "image 3",
    url: "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
  },
  {
    name: "image name ",
    url: "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171357/destinations/qvloaph0w6m9vshs2can.jpg",
  },
  {
    name: "image name",
    url: "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171359/destinations/cmwg6q8oafgcxijp5wxo.jpg",
  },
  {
    name: "image 4",
    url: "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
  },
  {
    name: "image name ",
    url: "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171357/destinations/qvloaph0w6m9vshs2can.jpg",
  },
  {
    name: "image name",
    url: "https://res.cloudinary.com/dbeeghycn/image/upload/v1671171359/destinations/cmwg6q8oafgcxijp5wxo.jpg",
  },
];

function ImageComponent({ imageList }) {
  //

  return (
    <Wrapper>
      {list.map((image, index) => {
        return (
          <div className="image-container">
            <img
              className="tb-image"
              src={image.url}
              alt="image"
              loading="lazy"
            />
          </div>
        );
      })}

      {/* {images.map((res) => {
        return (
          <div key={res.id} className="wrapper">
            <ImageContainer
              src={res.urls.regular}
              thumb={res.urls.thumb}
              height={res.height}
              width={res.width}
              alt={res.alt_description}
            />
          </div>
        );
      })} */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: #fff;
  .image-container {
    width: 100%;
    height: 24rem;
    margin-bottom: 1rem;
    /* background: rgba(0, 0, 0, 0.05); */
    /* background: #0f294d80; */
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    /* animation: gradient 5s ease infinite; */
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .tb-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ImageContainer = (props) => {
  const aspectRatio = (props.height / props.width) * 100;
  return (
    <div
      className="image-container"
      style={{ paddingBottom: `${aspectRatio}%` }}
    >
      <Image thumb={props.thumb} src={props.src} />
    </div>
  );
};

const Image = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  return (
    <React.Fragment>
      <img
        className="image thumb"
        alt={props.alt}
        src={props.thumb}
        style={{ visibility: isLoaded ? "hidden" : "visible" }}
      />
      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className="image full"
        style={{ opacity: isLoaded ? 1 : 0 }}
        alt={props.alt}
        src={props.src}
      />
    </React.Fragment>
  );
};

const Wrapper2 = styled.div`
  .image-container {
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.05);

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .full {
      transition: opacity 400ms ease 0ms;
    }
    .thumb {
      filter: blur(20px);
      transform: scale(1.1);
      transition: visibility 0ms ease 400ms;
    }
  }
`;
export default ImageComponent;
