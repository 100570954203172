import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";

import useApi from "../../utils/custom-hooks/useApi";
import { getUser } from "../../utils/utility";
import Loading from "../../components/Loading";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // snow theme
import { useAppContext } from "../../contexts/appContext";

function ArticleListing() {
  const [articles, setArticles] = useState([]);
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const { addInCache, getFromCache } = useAppContext();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const data = getFromCache("all-articles");
    const params = new URLSearchParams(location.search);
    const dataUpdated = params.get("refreshRequired") === "true";

    if (!data || dataUpdated) {
      fetchArticles();
      if (dataUpdated) {
        // Clear the query parameter using history.replace
        const cleanUrl = location.pathname;
        history.replace(cleanUrl);
      }
    } else {
      setArticles(data);
    }
  }, []);

  const fetchArticles = async () => {
    await makeApiCall("GET", `/articles`);
    if (resultRef.current) {
      if (resultRef.current.articles) {
        setArticles(resultRef.current.articles);
        addInCache("all-articles", resultRef.current.articles);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <h2>All Articles</h2>
      {showLoading()}
      {errorMessage()}
      {articles.map((article) => {
        // Approach 1: Decode HTML entities in the article body
        // const decodedBody = new DOMParser().parseFromString(
        //   article.body,
        //   "text/html"
        // ).body.textContent;

        // Approach 2: Remove HTML tags from the article body
        //Decode the body:
        //A. using regualr expression remove html entity
        //B. using html encoding & decoding library: he (light weight, last update 6 years ago), html-entities (heavy, last upadte 2 months ago)
        // C. using DOMParse
        const decodedBody = article.body
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">");

        const strippedBody = decodedBody.replace(/<[^>]*>?/gm, "");
        // Extract plain text description up to 220 characters
        const description =
          strippedBody.slice(0, 220) + (strippedBody.length > 220 ? "..." : "");

        return (
          <article
            className="uk-card uk-card-default uk-card-body uk-card-small uk-card-hover uk-margin-medium-bottom"
            key={article.article_id}
          >
            <h3>{article.title}</h3>
            {/* Approach 1 */}
            {/* <p
              dangerouslySetInnerHTML={{
                __html: decodedBody.slice(0, 220) + ".....",
              }}
            ></p> */}
            {/* Approach 2 */}
            <p>{description}</p>

            <div>
              <Link
                className="button uk-button uk-button-default uk-margin-small-right"
                to={`/admin/editor/edit/${article.article_id}`}
              >
                edit
              </Link>
              <Link
                className="button uk-button uk-button-default"
                to={`/article/${article.article_id}`}
              >
                preview
              </Link>
            </div>
          </article>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .remove-underline {
    text-decoration: none;
  }
`;

export default ArticleListing;
