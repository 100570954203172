import React, { useState } from "react";
import styled from "styled-components";
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { contactEmail } from "../utils/utility";

function PrivacyPolicyPage() {
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Privacy Policy - travelBucket </title>
      </Helmet>
      <h2>Privacy Policy:</h2>

      <p>
        The privacy notice describes how the
        <span className="item"> TravelBucket </span> platform protects and makes
        use of the information you provide to the company when you use this
        website and services, for the purpose of carrying out its business
        activities. The information you provide will only be used in the ways
        described in this privacy notice. The policy also describes the use of
        internet cookies. By accessing and using the website, e.g. reading our
        articles or blog posts or visiting the website pages, it is implied that
        you have read and accepted the privacy notice and cookies in its
        entirety. The latest version of the Privacy Notice is published on this
        page, with the revision date shown at the top. This will be updated from
        time to time, so we recommend you check the page regularly. If you have
        any questions on the policy, please contact us. You will find company
        contact details on the ‘Contact Us’ page of the website but you can
        contact us by email at <span className="item-1">{contactEmail}</span> We
        reserve the right to change this Privacy Policy without notice. By using
        or viewing the website, you agree to it, regardless of whether or not
        you’ve read it
      </p>

      <h4>Data We Gather</h4>
      <p>
        When you register on our website you will be required to complete a form
        containing personal information. The following information we gather
      </p>
      <ul>
        <li>Your name</li>
        <li>Email Address </li>
        <li>password</li>
      </ul>
      <p>When you comment on our website, we store it on our database.</p>
      <p>
        We may directly collect analytics data, or use third party analytics
        tools, to help measure traffic , browser type, and other data such as
        usage trends. These tools collect information from your mobile device or
        your computer's browser, which include items such as the pages you visit
        and other information which helps us to improve the service to you. We
        collect this information in an aggregated way so it cannot be
        manipulated to identify a particular person.
      </p>

      <h4>How We Use Data</h4>
      <p>We use your data in the following ways:</p>
      <ul>
        <li>Features that requires you to identify </li>
        <li>Products and service improvements </li>
        <li> Internal records</li>
        <li>
          Contacting you (using the email you have provided) related to changes
          in your account, maintenance, customer service queries
        </li>
      </ul>
      <p>We do not disclose your personal information to any third party.</p>

      <h4>Cookies</h4>
      <p>
        A cookie is a small file placed on your computer, which enables a
        website to identify your computer as you view different pages on the
        website and when you return to the website at a later time/date from the
        same computer.
      </p>
      <p>We uses cookies for the following reasons:</p>
      <ul>
        <li>Identify that you are signed in to our website. </li>
        <li>
          Store information about your login details in order to auto login on
          return to our website. You may wish to remain logged into the website
          (by not logging out) when you next return.
        </li>
        <li>
          Web traffic analysis. Data from website data aggregation services
          (such as google analytics) helps us improve our services and provides
          technical data (for example, versions of internet browsers accessing
          the website) to our development team.
        </li>
        <li>
          We partner with a third party to display and manage advertising. Our
          third party may use technologies such as cookies to gather information
          about your activities on this website or other websites in order to
          serve advertising to you based on your browsing activities.
        </li>
      </ul>

      <h4>TravelBucket Cookies</h4>
      <p>
        When you log in then we create a few cookies and store them for one
        month to automatically recognise you and/or log you back in when coming
        back to the website. When you comment or bookmark any place, we could
        recognize you through these cookies.
      </p>
      <p>
        These cookies can be manually destroyed by you by going in your
        browser’s settings. If you do not want your computer to store cookies
        from our website, you can disable cookies in your internet browser but
        please be aware that this may limit your use of the website or impact
        the functionality of the website
      </p>

      <h4>Use of Google Cookies</h4>
      <p>
        TravelBucket uses Google Analytics to monitor and analyse website
        traffic. Cookies are used by Google to provide statistical visitor
        information on our website.
      </p>

      <h4> Google Adsense Cookies</h4>
      <p>
        We partner with a Google Adsense network to either display advertising
        on our site or to manage our advertising on other sites. This ad network
        partner uses cookies and web beacons to collect non-personally
        identifiable information about your activities on this and other web
        sites to provide you targeted advertising based upon your interests.
      </p>

      <h4>Controlling Your Information</h4>
      <p>
        You have the right to access and edit your account and profile
        information by logging into the website and choosing to edit your
        Profile. You have the right to close your account. you can contact us
        directly under the ‘Contact Us’ page of our website.
      </p>
      <p>We do not sell your personal information to third parties.</p>

      <h4>External Websites</h4>
      <p>
        We have no control of websites outside the www.travelbucket.co.in
        domain.
      </p>
      <p>
        If you provide information to a website external to{" "}
        <span className="item-1">www.travelbucket.co.in</span>, we are not
        responsible for its protection and privacy. Please ensure that you read
        and understand the external site’s data protection and privacy policies
        fully.
      </p>

      <h4>Contact Us</h4>
      <p>
        If you have any questions, comments, or suggestions regarding our
        Privacy Policy, please contact us at
        <span className="item-1"> {contactEmail}</span>
      </p>
    </Wrapper>
  );
}

export default PrivacyPolicyPage;

const Wrapper = styled.main`
  p {
    text-align: justify;
  }
  .item {
    font-weight: 500;
  }
  .item-1 {
    font-weight: 400;
    text-decoration: underline;
    color: #1e87f0;
  }
`;
