import React, { useState, useEffect } from "react";
import UploadAndDisplayImages from "../../../components/UploadAndDisplayImages";
const placeTypes = [
  "Select Place type",
  "Main destination",
  "Final destination",
  "country",
  "state",
  "neighbourhood",
];

const countryZone = [
  "Select country zone",
  "East",
  "North",
  "South",
  "North East",
  "North West",
];
function AddState() {
  const [showForm, setShowForm] = useState(false);
  const [placeName, setPlaceName] = useState("");
  const [countryZones, setCountryZones] = useState(countryZone);

  const createStateForm = () => {
    return (
      <div className={showForm ? "uk-visible" : "uk-hidden"}>
        <UploadAndDisplayImages />

        <form class="uk-form-stacked">
          <p className="uk-text-success ">Key Info(fields)</p>

          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="State name..."
              onChange={(e) => setPlaceName(e.target.value)}
              value={placeName}
            />
          </div>
          {/* <--  place info --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place info</label>
            <input
              class="uk-input"
              type="text"
              placeholder="State info..."
              onChange={(e) => setPlaceName(e.target.value)}
              value={placeName}
            />
          </div>
          {/* <-- country zone --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Country zone</label>
            <select class="uk-select" id="form-stacked-select">
              {countryZones.map((cZone) => {
                return <option>{cZone}</option>;
              })}
            </select>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Secondary Info(fields)
          </p>
          {/* <-- popular in country --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in country</label>
            <label>
              <input class="uk-radio" type="radio" name="radio1" /> Yes
            </label>
            <label>
              <input class="uk-radio" type="radio" name="radio1" checked /> No
            </label>
          </div>
          {/* <-- popular in country zone --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in country zone</label>
            <label>
              <input class="uk-radio" type="radio" name="radio2" /> Yes
            </label>
            <label>
              <input class="uk-radio" type="radio" name="radio2" checked /> No
            </label>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>
          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place Type</label>
            <select class="uk-select" id="form-stacked-select">
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>

          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
            />
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <input
              class="uk-input uk-margin-small-right"
              type="text"
              placeholder="lat-long (optional)"
            />

            <input
              class="uk-input uk-margin-small-right"
              type="text"
              placeholder="country"
            />
            <input class="uk-input" type="text" placeholder="state" />
          </div>

          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            // onClick={handleSubmit}
          >
            submit
          </button>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="uk-flex uk-child-width-1-2">
        <h2>Add State/Province</h2>
        <div>
          <button
            className="uk-button uk-button-secondary"
            onClick={() => setShowForm(!showForm)}
          >
            {showForm ? "Close" : "Add"}
          </button>
        </div>
      </div>
      {createStateForm()}
    </>
  );
}

export default AddState;
