import { useState } from "react";

function useModal() {
  const [isShow, setIsShow] = useState(false);

  function toggleModal() {
    setIsShow(!isShow);
  }

  return {
    isShow,
    toggleModal,
  };
}

export default useModal;
