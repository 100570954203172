import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import { Link, useParams, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

import useApi from "../../utils/custom-hooks/useApi";
import { getUser } from "../../utils/utility";
import Loading from "../../components/Loading";
import { useAppContext } from "../../contexts/appContext";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // snow theme
import "react-quill/dist/quill.bubble.css"; // bubble theme

// The available features can be grouped by nesting arrays. This will add a space between the groups in the UI.
// - To use the default values for options like font, color, and background, set the key value as an empty array
const modulesFirst = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge", "28px", "40px", "5rem"] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

// LINK: https://stackoverflow.com/a/72815524/9716479
// https://imgbb.com/
// sticking with freeimage.host when other IMAGE HOSTING APIs exist.
function EditorPage() {
  const { actionType, articleId } = useParams();
  const quillRef = useRef();
  const user = getUser();
  const { addInCache, getFromCache } = useAppContext();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);

  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  // const articleId = "63a091603e12ce4d0f435737";

  // https://stackoverflow.com/questions/68997364/how-to-upload-image-inside-react-quill-content
  // https://github.com/quilljs/quill/issues/2044
  // https://github.com/quilljs/quill/issues/863
  // upload image: replace the base64 url with the url getting from sever
  // https://github.com/quilljs/quill/issues/2034
  const imageHandler = () => {
    const editor = quillRef.current.getEditor();

    // CASE 1: from widget

    // console.log(editor);
    //  const input = document.createElement("input");
    //  input.setAttribute("type", "file");
    //  input.setAttribute("accept", "image/*");
    //  input.click();
    //  input.onchange = async () => {
    //    const file = input.files[0];
    //    if (/^image\//.test(file.type)) {
    //      console.log(file);
    //      const formData = new FormData();
    //      formData.append("image", file);
    //      const res = await ImageUpload(formData); // upload data into server or aws or cloudinary
    //      const url = res?.data?.url;
    //      editor.insertEmbed(editor.getSelection(), "image", url);
    //    } else {
    //      ErrorToast("You could only upload images.");
    //    }
    //  };
    //
    // CASE 2: from URL
    var value = prompt("Add image URL");
    if (value) {
      editor.insertEmbed(
        editor.getSelection().index,
        "image",
        value
        // Quill.sources.USER
      );
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          // [
          //   {
          //     font: [
          //       "Arial",
          //       "sans-serif",
          //       "monospace",
          //       "Verdana",
          //       "Helvetica",
          //     ],
          //   },
          // ],
          [{ header: [1, 2, 3, 4] }],
          [{ size: ["small", false, "large", "huge", "28px", "40px", "5rem"] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
          ["link", "image", "video"],
          ["blockquote", "code-block"],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ script: "sub" }, { script: "super" }],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  useEffect(() => {
    if (actionType !== "write") {
      const data = getFromCache(`article-id-${articleId}`);
      if (!data) {
        fetchArticle();
      } else {
        setAllData(data);
      }
    }
  }, []);

  const fetchArticle = async () => {
    await makeApiCall("GET", `/article/${articleId}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setAllData(resultRef.current);
        addInCache(`article-id-${articleId}`, resultRef.current);

        //
        // setTitle(resultRef.current.article.title);
        // const storedBody = resultRef.current.article.body;
        // const doc = new DOMParser().parseFromString(storedBody, "text/html");
        // const decodedHtml = doc.documentElement.textContent.trim();
        // setValue(decodedHtml);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const setAllData = (response) => {
    setTitle(response.article.title);
    const storedBody = response.article.body;
    const doc = new DOMParser().parseFromString(storedBody, "text/html");
    const decodedHtml = doc.documentElement.textContent.trim();
    setValue(decodedHtml);
  };

  const submitArticle = async () => {
    // console.log("Data: ", value);

    if (!title || !value) {
      toast.error("Title or body is empty");
      return;
    }

    const data = {
      title: title,
      body: value,
      createdBy: user.user_id,
      updatedBy: user.user_id,
    };

    await makeApiCall("POST", `/admin/article`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        // console.log("SUBMITTED: ", resultRef.current);
        const newlyCreatedArticleId = resultRef.current.article.article_id;
        addInCache(`article-id-${newlyCreatedArticleId}`, resultRef.current);
        history.replace(`/article/${newlyCreatedArticleId}`);

        // setData(resultRef.current.article);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const updateArticle = async () => {
    if (!title || !value) {
      toast.error("Title or body is empty");
      return;
    }

    const data = {
      article_id: articleId,
      title: title,
      body: value,
      updatedBy: user.user_id,
    };

    await makeApiCall("PUT", `/admin/article`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        addInCache(`article-id-${articleId}`, resultRef.current);
        history.push(`/article/${articleId}`);
        // setData(resultRef.current.article);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const checkboxClickListener = () => {
    setIsChecked(!isChecked);
  };

  const deleteArticle = async () => {
    const data = {
      article_id: articleId,
      title: title,
      body: value,
      updatedBy: user.user_id,
    };

    await makeApiCall("DELETE", `/admin/article/${articleId}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        history.replace(`/admin/articles?refreshRequired=true`);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // onChange expects a function with these 4 arguments
  function handleChange(content, delta, source, editor) {
    setValue(editor.getContents());
    console.log("DELTA VALUE: ", value);
  }

  const writeArticleSegment = () => {
    return (
      <>
        <div className="uk-flex uk-flex-row uk-flex-between">
          <h2>Write article</h2>
          <span>
            <button
              className="uk-button uk-button-secondary"
              onClick={submitArticle}
            >
              submit
            </button>
          </span>
        </div>

        <input
          class="uk-input uk-margin-small-bottom"
          type="text"
          placeholder="Name of the article"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <ReactQuill
          ref={quillRef}
          theme="snow"
          placeholder="Start writing your article..."
          modules={modules}
          value={value}
          onChange={setValue}
          // onChange={handleChange}
          scrollingContainer="html"
        />

        {/* <p>{value}</p> */}
        {/* <ReactQuill
          theme="snow"
          modules={modules}
          placeholder="Content goes here..."
          onChange={setValue}
        /> */}
      </>
    );
  };

  const editArticleSegment = () => {
    return (
      <>
        {/* https://stackoverflow.com/questions/44445177/quilljs-jumps-to-top */}
        <div className="uk-flex uk-flex-row uk-flex-between">
          <h2>Edit article</h2>
          <span>
            {/* <Link
              className="uk-button uk-button-default uk-margin-small-right"
              to={`/article/${articleId}`}
            >
              preview
            </Link> */}
            <Link
              className="button uk-button uk-button-default uk-margin-small-right"
              to={`/admin/image-listing/${"article"}/${articleId}`}
            >
              images
            </Link>
            <button
              className="uk-button uk-button-secondary uk-margin-small-right"
              onClick={updateArticle}
            >
              update
            </button>
            <button
              className="uk-button uk-button-danger"
              onClick={deleteArticle}
            >
              delete
            </button>
          </span>
        </div>

        <input
          class="uk-input uk-margin-small-bottom"
          type="text"
          placeholder="Name of your article"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <ReactQuill
          ref={quillRef}
          theme="snow"
          modules={modules}
          value={value}
          onChange={setValue}
          scrollingContainer="html"
        />
      </>
    );
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      {showLoading()}
      {errorMessage()}
      {actionType === "write" ? writeArticleSegment() : editArticleSegment()}
      {/* <h4>Preview Section</h4> */}
      <div className="uk-margin">
        <label>
          <input
            className="uk-checkbox"
            type="checkbox"
            id="preview-checkbox"
            checked={isChecked}
            onClick={checkboxClickListener}
          />
          {`  Enable Preview`}
        </label>

        {isChecked ? (
          <ReactQuill
            value={value}
            readOnly={true}
            theme="bubble"
            modules={modules}
          />
        ) : (
          ""
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .editor-style {
    min-height: 5rem;
  }
`;

export default EditorPage;

// https://codepen.io/alexkrolick/pen/xgyOXQ : css part to customize editor color etc
// https://scriptverse.academy/tutorials/reactjs-rich-text-editor.html : article read
// ADD CUSTOM FEATURES: fonts, undo, redo
// https://medium.com/@mircea.calugaru/react-quill-editor-with-full-toolbar-options-and-custom-buttons-undo-redo-176d79f8d375
//
