import React, { useState, useEffect } from "react";
import UploadAndDisplayImages from "../../../components/UploadAndDisplayImages";
const placeTypes = [
  "Select Place type",
  "Main destination",
  "Final destination",
  "country",
  "state",
  "neighbourhood",
];

const continents = [
  "Select continent",
  "Asia",
  "North America",
  "South America",
  "C 4",
  "C 5",
  "C 6",
  "C 7",
];
function AddCountry() {
  const [showForm, setShowForm] = useState(false);
  const [placeName, setPlaceName] = useState("");

  const createCountryForm = () => {
    return (
      <div className={showForm ? "uk-visible" : "uk-hidden"}>
        <UploadAndDisplayImages />

        <form class="uk-form-stacked">
          <p className="uk-text-success ">Key Info(fields)</p>

          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Country name..."
              onChange={(e) => setPlaceName(e.target.value)}
              value={placeName}
            />
          </div>
          {/* <--  place info --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place info</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Country info..."
              onChange={(e) => setPlaceName(e.target.value)}
              value={placeName}
            />
          </div>
          {/* <-- Continent --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Continent</label>
            <select class="uk-select" id="form-stacked-select">
              {continents.map((continent) => {
                return <option>{continent}</option>;
              })}
            </select>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>
          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place Type</label>
            <select class="uk-select" id="form-stacked-select">
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>

          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
            />
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <input
              class="uk-input uk-margin-small-right"
              type="text"
              placeholder="lat-long (optional)"
            />

            <input
              class="uk-input uk-margin-small-right"
              type="text"
              placeholder="country"
            />
            <input class="uk-input" type="text" placeholder="state" />
          </div>

          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            // onClick={handleSubmit}
          >
            submit
          </button>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="uk-flex uk-child-width-1-2 uk-margin-medium-top">
        <h2>Add Country</h2>
        <div>
          <button
            className="uk-button uk-button-secondary"
            onClick={() => setShowForm(!showForm)}
          >
            {showForm ? "Close" : "Add"}
          </button>
        </div>
      </div>
      {createCountryForm()}
    </>
  );
}

export default AddCountry;
