import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";

import { mandatoryField, themes, placeTypes } from "../../utils/utility";
import Loading from "../../components/Loading";
import useApi from "../../utils/custom-hooks/useApi";
import { FaWindowRestore } from "react-icons/fa";

import { mainDestinations } from "../../mockDb";

function reactHookFormsNote() {
  // https://www.carlrippon.com/react-hook-form-validation-errors/
  // https://jasonwatmore.com/post/2021/09/13/react-hook-form-display-custom-error-message-returned-from-api-request
  // https://www.copycat.dev/blog/react-hook-form/#respond
  //
  // 1. React Hook Forms utilize ref instead of component states. It lessens re-rendering and gives better performance.
  // 2. The handleSubmit method handles two functions as arguments. The first function argument will invoke the registered field values when the form validation is successful. The second function calls errors when the validation fails.
  //2. <form onSubmit={handleSubmit(onFormSubmit, onErrors)}>
  // 3. The useForm Hook above makes the component code cleaner and easier to maintain. The form is uncontrolled, and it is not necessary to pass props like onChange and value to each input.
  // 4. Unlike other form libraries that handle the implementation of their form management library with React state, React Hook Form makes use of ref to control the form inputs. As a result, the use of ref resolves the unnecessary re-rendering of forms in React.
}

function AddDestination() {
  const [bestTimeToGoTitle, setBestTimeToGoTitle] = useState("");
  const [bestTimeToGoInfo, setBestTimeToGoInfo] = useState("");
  const [bestTimeToGoList, setBestTimeToGoList] = useState([]);

  const [howToReachTitle, setHowToReachTitle] = useState("");
  const [howToReachInfo, setHowToReachInfo] = useState("");
  const [howToReachList, setHowToReachList] = useState([]);

  const [thingsToKnow, setThingsToKnow] = useState("");
  const [thingsToKnowList, setThingsToKnowList] = useState([]);

  const [moreInfo, setMoreInfo] = useState({
    title: "",
    description: "",
  });
  const [moreInfos, setmoreInfos] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const [placeId, setplaceId] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  //pre-filling fields for testing
  useEffect(() => {
    // const dest = mainDestinations[4];
    // setValue("placeName", dest.place_name);
    // setValue("placeOverview", dest.place_info);
    // setValue("countryRadBtn", dest.popular_in_country);
    // setValue("stateRadBtn", dest.popular_in_state);
    // setValue("themeType", dest.theme_type);
    // setValue("streetAddress", dest.address.street_address);
    // setValue("latLong", dest.address.lat_long);
    // setValue("country", dest.address.country);
    // setValue("state", dest.address.state);
    // setBestTimeToGoList(dest.best_time_to_go);
    // setHowToReachList(dest.how_to_reach);
    // setThingsToKnowList(dest.things_to_know);
    // setmoreInfos(dest.more_info);
    //
    //
    // setValue("placeType", dest.place_type);
  }, []);

  const addBestTimeToGo = (e) => {
    e.preventDefault();
    if (!bestTimeToGoTitle || !bestTimeToGoInfo) {
      alert("Enter title or info");
      return;
    }
    setBestTimeToGoList([
      ...bestTimeToGoList,
      { title: bestTimeToGoTitle, info: bestTimeToGoInfo },
    ]);
    setBestTimeToGoTitle("");
    setBestTimeToGoInfo("");
  };

  const addHowToReach = (e) => {
    e.preventDefault();
    if (!howToReachTitle || !howToReachInfo) {
      alert("Enter title or info");
      return;
    }
    setHowToReachList([
      ...howToReachList,
      { title: howToReachTitle, info: howToReachInfo },
    ]);
    setHowToReachTitle("");
    setHowToReachInfo("");
  };

  const addThingsToKnow = (e) => {
    e.preventDefault();
    if (!thingsToKnow) {
      alert("Enter info");
      return;
    }
    setThingsToKnowList([...thingsToKnowList, { item: thingsToKnow }]);
    setThingsToKnow("");
  };

  const handleMoreInfoChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMoreInfo({ ...moreInfo, [name]: value });
  };

  const addMoreInfo = (e) => {
    e.preventDefault();
    if (!moreInfo.title || !moreInfo.description) {
      alert("Enter title or description");
      return;
    }
    setmoreInfos([...moreInfos, moreInfo]);
    setMoreInfo({ title: "", description: "" });
  };

  const onSubmit = async (data) => {
    console.log("SUBMIT BUTTON CLICKED");
    const pCountry = data.countryRadBtn === "yes" ? true : false;
    const pState = data.stateRadBtn === "yes" ? true : false;

    const address = {
      street_address: data.streetAddress,
      lat_long: data.latLong,
      country: data.country,
      state: data.state,
    };

    const dest = {
      place_name: data.placeName,
      place_info: data.placeOverview,
      things_to_know: thingsToKnowList,
      more_info: moreInfos,
      how_to_reach: howToReachList,
      popular_in_country: pCountry,
      popular_in_state: pState,
      theme_type: data.themeType,
      place_type: "maindestination",
      destination_type: "maindestination",
      best_time_to_go: bestTimeToGoList,
      address: address,
      source_references: data.sourceReferences,
    };

    await makeApiCall("POST", `/admin/main-destination`, dest);

    setTimeout(() => {
      // TODO: result- useState not getting updated/live value but resultRef-useRef getting updated/live value
      // if (result && result.status) {
      //   console.log("AFTER API CALL IF INSIDE");
      //   alert("GOT IT");
      // } else {
      //   console.log("AFTER API CALL ELSE INSIDE", resultRef.current);
      //   // alert(`GOT ELSE ${resultRef.current.message}`);
      //   setplaceId(resultRef.current.destination.place_name);
      //   setRedirect(true);
      // }

      if (resultRef.current) {
        if (resultRef.current.status) {
          alert(`SUCCESS:  ${resultRef.current.message}`);
          setplaceId(resultRef.current.destination.place_id);
          setRedirect(true);
        } else {
          alert(`FAIL: ${resultRef.current.message}`);
        }
      }
    }, 2000);
  };

  /** 
   const setAndGetValuesRadioButtonSpinnerCheckbox = () => {
    // SETTING  VALUES IN RADIO BUTTON - https://stackoverflow.com/questions/12810034/dynamically-checked-radio-button-based-on-value-attribute-not-checking
    let radios = document.getElementsByName("countryRadBtn");
    let value = "no"; //value you want to compare radio with

    if (radios[0].value == value) {
      radios[0].checked = true;
    } else {
      radios[1].checked = true;
    }

    let radiot = document.getElementsByName("destRadBtn");
    let val = "finalDestination"; //value you want to compare radio with

    for (let i = 0, length = radiot.length; i < length; i++) {
      if (radiot[i].value == val) {
        radiot[i].checked = true;

        // only one radio can be logically checked, don't check the rest
        break;
      }
    }

    // GETTING VALUES RADIO BUTTON
    const vCountry = document.forms[0].elements["countryRadBtn"].value;
    const vState = document.forms[0].elements["stateRadBtn"].value;

    // SETTING  VALUES IN SPINNER (select) - https://stackoverflow.com/questions/43831859/javascript-auto-select-a-drop-down-menu
    var options = document.getElementById("theme-select").options;
    var theme = "Heritage";
    for (var i = 0; i < options.length; i++) {
      if (options[i].text == theme) {
        options[i].selected = true;
        break;
      }
    }

    var options = document.getElementById("place-type-select").options;
    var placeType = "country";
    for (var i = 0; i < options.length; i++) {
      if (options[i].text == placeType) {
        options[i].selected = true;
        break;
      }
    }

    // GETTING VALUES SPINNER
    var e = document.getElementById("theme-select");
    var selectedTheme = e.options[e.selectedIndex].text;

    // GETTING VALUES CHECKBOX
     var checkedValues = [];
     var inputElements = document.getElementsByClassName("uk-checkbox");
     for (var i = 0; inputElements[i]; ++i) {
       if (inputElements[i].checked) {
         checkedValues.push(inputElements[i].value);
       }
     }
    
  };

  const handleSubmitUsingFormData = async (event) => {
    event.preventDefault();
    const vCountry = document.forms[0].elements["countryRadBtn"].value;
    const vState = document.forms[0].elements["stateRadBtn"].value;

    const pCountry = vCountry === "yes" ? true : false;
    const pState = vState === "yes" ? true : false;

    var e = document.getElementById("theme-select");
    var selectedTheme = e.options[e.selectedIndex].text;

    const address = {
      street_address: streetAddress,
      lat_long: latLong,
      country: country,
      state: state,
    };

    // https://stackoverflow.com/questions/12989442/uploading-multiple-files-using-formdata
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }
    formData.append("place_name", name);
    formData.append("place_info", overview);
    formData.append("things_to_know", thingsToKnow);
    formData.append("more_info", JSON.stringify(moreInfos));
    formData.append("how_to_reach", `${viaPlane}##${viaTrain}##${viaBus}`);
    formData.append("popular_in_country", pCountry);
    formData.append("popular_in_state", pState);
    formData.append("theme_type", selectedTheme);
    formData.append("place_type", "maindestination");
    formData.append("destination_type", "maindestination");
    formData.append("best_time_to_go", `${peakSeason}##${offSeason}`);
    formData.append("address", JSON.stringify(address));

    await makeApiCall(`/admin/main-destination`, formData);
  };
*/

  const createMainDestinationForm = () => {
    return (
      <div>
        <form
          class="uk-form-stacked"
          // encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="uk-text-success ">Key Info(fields)</p>

          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Destination name..."
              // onChange={(e) => setName(e.target.value)}
              // value={name}
              {...register("placeName", {
                required: {
                  value: true,
                  message: "field is required",
                },
                minLength: {
                  value: 3,
                  message: "Place name must be at least 3 characters",
                },
              })}
            />
            <span className="uk-text-danger">{errors.placeName?.message}</span>
          </div>

          {/* <--  Overview --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Overview (place info)</label>
            <textarea
              class="uk-textarea"
              rows="5"
              placeholder="Destination overview (only key-infos)..."
              // onChange={(e) => setOverview(e.target.value)}
              // value={overview}
              {...register("placeOverview", mandatoryField)}
            ></textarea>
            <span className="uk-text-danger">
              {errors.placeOverview?.message}
            </span>
          </div>

          {/* <--  Best time to go--> */}
          <div class="uk-margin">
            <ul data-uk-accordion>
              {bestTimeToGoList.map(({ title, info }) => {
                return (
                  <li>
                    <a class="uk-accordion-title">{title}</a>
                    <div class="uk-accordion-content">
                      <p>{info}</p>
                    </div>
                    <hr />
                  </li>
                );
              })}
            </ul>

            <label class="uk-form-label">
              Best time to visit (1. OVERVIEW-- use here the word
              peak,off,moderate-- only key-infos from [peak season, off season,
              moderate season], 2. PEAK season, 3. OFF season, 4. MODERATE
              season or (2.Winter: October - February, 3.monsoon:
              July-September, 4.summer: March-June -- use here the word
              peak,off,moderate))
              <span style={{ color: "red" }}> Change to more-info form</span>
            </label>

            <input
              class="uk-input"
              type="text"
              placeholder="Title of info [overview, peak season, off season, moderate season, etc]"
              value={bestTimeToGoTitle}
              onChange={(e) => setBestTimeToGoTitle(e.target.value)}
            />
            <textarea
              class="uk-textarea uk-margin-small-top"
              rows="4"
              placeholder="info..."
              value={bestTimeToGoInfo}
              onChange={(e) => setBestTimeToGoInfo(e.target.value)}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={addBestTimeToGo}
            >
              Add
            </button>
          </div>

          {/* <-- Things to know --> */}
          <div class="uk-margin">
            <ul>
              {thingsToKnowList.map(({ item }) => {
                return (
                  <li>
                    {item}
                    {/* <a class="uk-accordion-title">{title}</a>
                    <div class="uk-accordion-content">
                      <p>{info}</p>
                    </div> */}
                    {/* <hr /> */}
                  </li>
                );
              })}
            </ul>
            <label class="uk-form-label">
              Things to know (bullet list, optional)
            </label>
            <input
              class="uk-input"
              type="text"
              placeholder="Things to be aware about the destination..."
              value={thingsToKnow}
              onChange={(e) => setThingsToKnow(e.target.value)}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={addThingsToKnow}
            >
              Add
            </button>
          </div>

          {/* <-- More info about destination [list: title-description] --> */}
          <div class="uk-margin">
            <ul data-uk-accordion>
              {moreInfos.map((moreInfo) => {
                return (
                  <li>
                    <a class="uk-accordion-title">{moreInfo.title}</a>
                    <div class="uk-accordion-content">
                      <p>{moreInfo.description}</p>
                    </div>
                    <hr />
                  </li>
                );
              })}
            </ul>

            <label class="uk-form-label">
              More info about destination (title-description)
            </label>
            <input
              class="uk-input"
              type="text"
              placeholder="Title of info..."
              name="title"
              value={moreInfo.title}
              onChange={handleMoreInfoChange}
            />
            <textarea
              class="uk-textarea uk-margin-small-top"
              rows="4"
              placeholder="Description of info..."
              name="description"
              value={moreInfo.description}
              onChange={handleMoreInfoChange}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={addMoreInfo}
            >
              Add info
            </button>
          </div>

          {/* <--  How to reach --> */}
          <div class="uk-margin">
            <ul data-uk-accordion>
              {howToReachList.map(({ title, info }) => {
                return (
                  <li>
                    <a class="uk-accordion-title">{title}</a>
                    <div class="uk-accordion-content">
                      <p>{info}</p>
                    </div>
                    <hr />
                  </li>
                );
              })}
            </ul>

            <label class="uk-form-label">
              How to reach (1. OVERVIEW, only key infos from [flight, train,
              road], 2. FLIGHT, 3. TRAIN, 4. ROAD )
              <span style={{ color: "red" }}> Change to more-info form</span>
            </label>

            <input
              class="uk-input"
              type="text"
              placeholder="Title of info [overview, flight, train, road]"
              value={howToReachTitle}
              onChange={(e) => setHowToReachTitle(e.target.value)}
            />
            <textarea
              class="uk-textarea uk-margin-small-top"
              rows="4"
              placeholder="info..."
              value={howToReachInfo}
              onChange={(e) => setHowToReachInfo(e.target.value)}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={addHowToReach}
            >
              Add
            </button>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Secondary Info(fields)
          </p>
          {/* <-- popular in country --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in country</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                // name="countryRadBtn"
                value="yes"
                {...register("countryRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                // checked
                // name="countryRadBtn"
                value="no"
                {...register("countryRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.countryRadBtn?.message}
            </span>
          </div>

          {/* <-- popular in state --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in state</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                // name="stateRadBtn"
                value="yes"
                {...register("stateRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                // checked
                // name="stateRadBtn"
                value="no"
                {...register("stateRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.stateRadBtn?.message}
            </span>
          </div>

          {/* <-- Theme type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Theme Type</label>
            <select
              class="uk-select"
              id="theme-select"
              {...register("themeType", { required: true })}
            >
              {themes.map((theme) => {
                return <option>{theme}</option>;
              })}
            </select>
            <span className="uk-text-danger">{errors.themeType?.message}</span>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>

          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Place Type((FIXED - maindestination))
            </label>
            {/* <select
              class="uk-select"
              id="form-stacked-select"
              {...register("placeType", { required: true })}
              disabled
            >
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select> */}
          </div>

          {/* <-- destination type --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">
              Destination type(FIXED - maindestination)
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                name="destRadBtn"
                value="maindestination"
                checked
              />
              main destination
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                name="destRadBtn"
                value="finaldestination"
                disabled
              />
              final destination
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                name="destRadBtn"
                value="none"
                disabled
              />
              none
            </label>
          </div>

          {/* <-- Address info --> */}
          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>
          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
              // onChange={(e) => setStreetAddress(e.target.value)}
              // value={streetAddress}
              {...register("streetAddress", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.streetAddress?.message}
            </span>
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="lat-long (optional)"
                // onChange={(e) => setLatLong(e.target.value)}
                // value={latLong}
                {...register("latLong")}
              />
              {/* <span className="uk-text-danger">{errors.latLong?.message}</span> */}
            </div>

            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="country"
                // onChange={(e) => setCountry(e.target.value)}
                // value={country}
                {...register("country", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.country?.message}</span>
            </div>

            <div>
              <input
                class="uk-input"
                type="text"
                placeholder="state"
                // onChange={(e) => setState(e.target.value)}
                // value={state}
                {...register("state", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.state?.message}</span>
            </div>
          </div>

          {/* <-- source references  --> */}
          <p className="uk-text-success uk-margin-medium-top">Others info</p>
          <div class="uk-margin">
            <label class="uk-form-label">
              Source references (links separated with ## or use ## as regex
              separator)
            </label>
            <textarea
              class="uk-textarea"
              rows="5"
              placeholder="links separated with ##"
              {...register("sourceReferences")}
            ></textarea>
          </div>

          <h4 style={{ color: "red" }}> FAQs </h4>

          <button
            className="uk-button uk-button-secondary uk-margin-small-bottom"
            type="submit"
          >
            submit
          </button>
          {showLoading()}
          {errorMessage()}
        </form>
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <h2 className="uk-margin-small-top">Main Destination Form</h2>
      {createMainDestinationForm()}
      {redirect && <Redirect to={`/admin/destination-listing/${placeId}`} />}
      {/* <h4 style={{ color: "red" }}>
        How to use google images (with credit legally )[makemytrip]
      </h4> */}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  background: #f2f2f2;
`;
export default AddDestination;
