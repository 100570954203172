import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

import MyBucketForm from "../components/MyBucketForm";
import { getUser, mandatoryField } from "../utils/utility";
import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";
import { toast } from "react-hot-toast";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import CustomModal from "../components/CustomModal";
import useModal from "../utils/custom-hooks/useModal";
import { useAppContext } from "../contexts/appContext";

function MyBucket() {
  const user = getUser();
  const [bucketList, setBucketList] = useState();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  // const [isShow, setIsShow] = useState(false);
  const { isShow, toggleModal } = useModal();
  const { addInCache, getFromCache } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (user) {
      // const result = getFromCache("my-buckets");
      // if (result) {
      //   setBucketList(result);
      //   handleScrollPosition();
      // } else {
      //   fetchBuckets();
      // }
      ReactGA.set({
        username: user.name,
      });
      fetchBuckets();
    }

    ReactGA.set({
      username: "random-pizza",
    });
  }, []);

  const fetchBuckets = async () => {
    await makeApiCall("GET", `/buckets/${user.user_id}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        setBucketList(resultRef.current.buckets);
        handleScrollPosition();
        // addInCache("my-buckets", resultRef.current.buckets);
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const itemClick = () => {
    sessionStorage.setItem("scrollPosition-b", window.pageYOffset);
  };

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition-b");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition-b");
    }
  };

  const onSuccessBucketAdd = (newBuckets) => {
    setBucketList(newBuckets);
    toggleModal();
    toast.success("Wohoo... new bucket created");

    // ReactGA.event({
    //   category: "User",
    //   action: "Created a new bucket",
    // });

    // ReactGA.event({
    //   category: "Form",
    //   action: "Form Submit",
    //   transport: "beacon",
    // });

    ReactGA.event({
      category: "bucket_added",
      action: "new",
      transport: "beacon",
    });
  };

  const submitBucket = async (data) => {
    const req = {
      bucket_name: data.bucketName,
      bucket_description: data.bucketDescription,
      user_id: user.user_id,
      // user_id: "637f129abdb8430111d81f75",
    };
    toggleModal();
    await makeApiCall("POST", `/bucket`, req);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setBucketList(resultRef.current.buckets);
        alert(`SUCCESS:  ${resultRef.current.message}`);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const bucketListSection = () => {
    return (
      <section
        className="uk-margin-medium-top"
        data-uk-scrollspy="cls: uk-animation-fade; target: .single-bucket; delay:200"
      >
        {bucketList && bucketList.length === 0 && (
          <>
            <div class="uk-alert-warning" data-uk-alert>
              <p className="uk-text-warning">
                You have not added any bucket. Create a bucket by clicking
                <span className="uk-text-bold"> New Bucket </span> button.
              </p>
            </div>
          </>
        )}

        {bucketList &&
          bucketList.map((bucket) => {
            return (
              <article
                key={bucket.bucket_id}
                className="uk-flex uk-flex-row uk-margin-small-bottom single-bucket"
              >
                <div className="uk-width-2-3 uk-margin-small-left">
                  <h3 className="uk-margin-small-top">{bucket.bucket_name}</h3>
                  <p className="uk-margin-remove-top">
                    {bucket.bucket_description}
                  </p>
                  <div>
                    <Link
                      to={`/single-bucket/${bucket.bucket_id}`}
                      className="uk-button uk-button-secondary small-button uk-margin-small-bottom"
                      onClick={itemClick}
                    >
                      inside mybucket
                    </Link>
                  </div>
                </div>
                <div className="uk-width-1-3 right-container">
                  <img src={bucket.image} className="image" />
                </div>
              </article>
            );
          })}
      </section>
    );
  };

  const privateSection = () => {
    return (
      <div>
        <div
          className="uk-flex uk-flex-between"
          data-uk-scrollspy="cls:uk-animation-fade; delay:200"
        >
          {/* <h2 className="h-2">Your buckets {user.signal.second.ft}</h2> */}
          <h2 className="h-2">Your buckets </h2>
          <div>
            <button
              className="uk-button uk-button-primary small-button"
              onClick={toggleModal}
            >
              New Bucket
            </button>
          </div>
        </div>

        <p
          className="uk-text-default"
          data-uk-scrollspy="cls:uk-animation-fade; delay:200"
        >
          All your created buckets will be here. You can create a new bucket by
          clicking
          <span className="uk-text-bold"> New Bucket </span> button & providing
          the required informations.
          {/* <span className="uk-text-bold"> All Places </span>
          is your default bucket. */}
        </p>

        {/* <-- BUCKET LIST --> */}
        {bucketListSection()}

        {/* <-- NEW BUCKET FORM --> */}
        {/* <MyBucketForm submitBucket={submitBucket} /> */}
        {/* MODAL SECTION */}
        <CustomModal isShow={isShow} closeModal={toggleModal}>
          <MyBucketForm onSuccessBucketAdd={onSuccessBucketAdd} />
        </CustomModal>
      </div>
    );
  };

  const nonSignedInSection = () => {
    return (
      <div className="non-signedin-section">
        {/* <button
          class="c1v58enf lytijta my-icon-white back-to-top visible d8axrx0"
          tabindex="0"
          aria-label="To top"
          title="To top"
        >
          <FaBookmark
            className="bookmark-icon"
            // onClick={() => bookmarkBtnClick(destination.place_id, true)}
          ></FaBookmark>
        </button> */}
        <p>Please login to show or create your bucket.</p>
        <Link to="/login" className="uk-button uk-button-secondary">
          Login
        </Link>
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
          </div>
        </>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const buttonFilAnimation = () => {
    return (
      <Wrapper2>
        {/* APPROACH 1 */}
        <div class="outer">
          <h1>Left to Right</h1> &nbsp;
          <div class="outer__button button__left-right">
            <div class="text">Button</div>
          </div>
          &nbsp;
        </div>

        {/*  APPROACH 2 */}
        <a href="">CARROT</a>
        {/* APPROACH 3 */}
        <h3 data-text="CARROT">CARROT</h3>
        {/*  */}
        <button className="btn">text</button>
      </Wrapper2>
    );
  };

  const ItemTestingComponent = () => {
    return (
      <>
        {/* <button onClick={() => methodDoesNotExist()}>Break the world</button> */}
      </>
    );
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top ">
      <Helmet>
        <title>myBucket - travelBucket </title>
      </Helmet>

      {/* <ItemTestingComponent /> */}
      {showLoading()}
      {errorMessage()}
      {!user && nonSignedInSection()}
      {user && privateSection()}

      {/* {buttonFilAnimation()} */}

      {/* <button
        className="uk-button uk-button-primary"
        // onClick={() => setIsShow(true)}
        // onClick={toggleModal}
      >
        show modal
      </button> */}

      {/* <CustomModal isShow={isShow} closeModal={() => setIsShow(false)}>
        <p>Hi modal  without customHook</p>
      </CustomModal> */}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .non-signedin-section {
    background: #ebf0f4;
    padding: 1rem;
  }
  .single-bucket {
    background-color: #fafafa;
    border: 1px solid #e6e6e6;
  }

  .right-container {
    /* width: 320px; */
    /* height: 180px; */
    background-color: #098af31f;
    margin-left: 0.5rem;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 425px) {
    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.25rem;
    }
    .small-button {
      padding: 0 15px;
    }
  }

  /* HOVER CODE */
  .bookmark-icon {
    color: #338280;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }

  .c1v58enf.back-to-top.visible {
    display: inline-block;
    opacity: 1;
    z-index: 2;
  }

  .lytijta {
    left: unset;
    right: 12px;
    z-index: 6;
  }
  .c1v58enf {
    position: fixed;
    bottom: 25px;
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: opacity 0.3s;
    overflow: hidden;
    padding: 0;
  }
  .my-icon-white {
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    border: none;
    margin-right: 14px;
    width: 58px;
    height: 58px;
    line-height: 58px;
    background-color: #d11111;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  }
  .c1v58enf:before {
    content: "";
    display: block;
    background-color: #0067b9;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    top: 0;
    left: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    z-index: 0;
  }
`;

const Wrapper2 = styled.div`
  /* PSEUDO ELEMENTS - 'after', 'before' */
  /* BACKGROUND POSITION */

  /* 15+ CSS Buttons Animation On Hover With Source Code */
  /* https://www.rustcodeweb.com/2022/07/css-buttons-animation-on-hover-with-source-code.html */
  /* MEDIUM - https://medium.com/web-dev-survey-from-kyoto/change-the-background-color-with-transition-animation-web-dev-survey-from-kyoto-ef2f65756e9a */

  /*1.  https://dev.to/flyingduck92/creating-fill-background-animation-for-button-using-css-2me3 */
  .outer {
    margin: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  h1 {
    color: teal;
    text-transform: uppercase;
  }

  .outer__button {
    padding: 0.5em 1.5em;
    font-size: 2em;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    margin-top: -1em;
    border-radius: 100vh;
  }
  /* LEFT TO RIGHT */
  .button__left-right {
    border: 2px solid black;
    background: linear-gradient(to right, #000 50%, #fff 50%);
    background-size: 200% 100%;
    background-position: bottom right;
    transition: all 0.5s ease-out;
  }

  .button__left-right:hover {
    border: 2px solid limegreen;
    background-position: bottom left;
    color: #fff;
  }

  /* 2. https://awik.io/gradually-fill-link-background-color-with-css/ */
  a {
    position: relative;
    text-decoration: none;
    color: green;
  }

  a:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 100%;
    background: orange;
    transition: all 0.5s;
    z-index: -1;
  }

  a:hover:before {
    width: 100%;
  }

  /*3. https://www.geeksforgeeks.org/how-to-create-text-fill-animation-using-css/  */
  h3 {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 5em;
    color: #ccc;
  }

  h3:before {
    /* This will create the layer
               over original text*/
    content: attr(data-text);
    /* content: ""; */
    position: absolute;
    top: 0;
    left: 0;

    /* Setting different color than
               that of original text  */
    color: green;
    overflow: hidden;

    /* Setting width as 0*/
    width: 0%;
    transition: 1s;
  }

  h3:hover:before {
    /* Setting width to 100 on hover*/
    width: 100%;
  }
  /*  */
  .btn {
    padding: 0.5rem 1.5rem;
    background-color: #ff0;
    transition: background-color 1000ms ease-out;
  }

  .btn:hover {
    background-color: #ad310b;
  }
`;
export default MyBucket;
