import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import useApi from "../utils/custom-hooks/useApi";
import Loading from "./Loading";
import { getUser, mandatoryField } from "../utils/utility";

// import user from "../../../backend/models/user";
// import { mandatoryField, themes, placeTypes } from "../../utils/utility";
// import Loading from "../../components/Loading";

function MyBucketForm({ onSuccessBucketAdd }) {
  const user = getUser();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [bucketName, setBucketName] = useState("");
  const [bucketDescription, setBucketDescription] = useState("");

  const submitForm = () => {
    console.log("submitForm click");
    if (!bucketName) {
      alert("Please enter bucket name");
      return;
    }

    if (!bucketDescription) {
      alert("Please enter bucket description");
      return;
    }

    submitBucket(bucketName, bucketDescription);

    // https://stackoverflow.com/questions/47998570/uikit3-call-out-modal-programmatically
    // https://www.w3schools.com/howto/howto_js_toggle_class.asp
    // https://stackoverflow.com/questions/54757324/opening-a-uikit-modal-through-javascript

    //  APPROACH 1: by toggling `uk-open` claas but due to not removing style, you have to make an extra click to come on initial zone
    document.getElementById("new-bucket-modal-id").classList.toggle("uk-open");

    //  APPROACH 2: add `uk-modal-close` on button a/c to the condition in formsubmit to add this class in button. You have to track `isShow` value carefully
    // setisShow(true);
  };

  const submitBucket = async (data) => {
    const req = {
      bucket_name: data.bucketName,
      bucket_description: data.bucketDescription,
      user_id: user.user_id,
      // user_id: "637f129abdb8430111d81f75",
    };
    await makeApiCall("POST", `/bucket`, req);
    if (resultRef.current) {
      if (resultRef.current.status) {
        setValue("bucketName", "");
        setValue("bucketDescription", "");
        onSuccessBucketAdd(resultRef.current.buckets);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };
  const errorMessage = () => {
    if (error.show) {
      return (
        <div className="uk-alert-danger uk-margin-remove-bottom" data-uk-alert>
          {/* <a className="uk-alert-close" data-uk-close></a> */}
          <p className="uk-text-danger">{error.msg}</p>
        </div>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };
  return (
    <Wrapper>
      <form class="uk-form-stacked" onSubmit={handleSubmit(submitBucket)}>
        {showLoading()}
        {errorMessage()}
        <h3 class="uk-margin-small-top">Create new bucket</h3>
        <div class="uk-margin">
          <input
            class="uk-input"
            type="text"
            placeholder="Name of your bucket"
            {...register("bucketName", mandatoryField)}
          />
        </div>
        <span className="uk-text-danger">{errors.bucketName?.message}</span>
        <div class="uk-margin">
          <textarea
            class="uk-textarea"
            rows="5"
            placeholder="Description (optional)"
            {...register("bucketDescription")}
          />
          <span className="uk-text-danger">
            {errors.bucketDescription?.message}
          </span>
        </div>
        <button
          className="uk-button uk-button-secondary"
          // onClick={submitForm}
          type="submit"
        >
          submit
        </button>
      </form>
    </Wrapper>

    // <div id="new-bucket-modal-id" data-uk-modal>
    //   <div class="uk-modal-dialog uk-modal-body">
    //     <button
    //       class="uk-modal-close-default"
    //       type="button"
    //       data-uk-close
    //     ></button>
    //     <h2 class="uk-modal-title">Create new bucket</h2>
    //     <div class="uk-margin">
    //       <input
    //         class="uk-input"
    //         type="text"
    //         placeholder="Name of your bucket"
    //         value={bucketName}
    //         onChange={(e) => setBucketName(e.target.value)}
    //       />
    //     </div>
    //     <div class="uk-margin">
    //       <textarea
    //         class="uk-textarea"
    //         rows="5"
    //         placeholder="Description (optional)"
    //         value={bucketDescription}
    //         onChange={(e) => setBucketDescription(e.target.value)}
    //       ></textarea>
    //     </div>
    //     <button
    //       // className={`${
    //       //   isShow
    //       //     ? "uk-button uk-button-secondary uk-modal-close"
    //       //     : "uk-button uk-button-secondary"
    //       // }`}
    //       className="uk-button uk-button-secondary"
    //       onClick={submitForm}
    //     >
    //       submit
    //     </button>
    //   </div>
    // </div>
  );
}

export default MyBucketForm;

const Wrapper = styled.div``;
