import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Redirect, useParams, useHistory } from "react-router-dom";

import Loading from "../../../components/Loading";
import {
  mandatoryField,
  themes,
  placeTypes,
  categories,
} from "../../../utils/utility";
import useApi from "../../../utils/custom-hooks/useApi";

function AddNeighbour() {
  const { mainDestinationId } = useParams();
  const [redirect, setRedirect] = useState(false);

  const history = useHistory();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  //pre-filling fields for testing
  useEffect(() => {
    // setValue("placeName", "Yindi");
    // setValue("placeOverview", "about 2 km away from the airport..");
    // setValue("streetAddress", "MB 42 a37 street kualalampur");
    // setValue("latLong", "136.78, 439.54");
    // setValue("country", "India");
    // setValue("state", "JKD");
    //
    //
    // setValue("placeType", "neighbour");
  }, []);

  const onSubmit = async (data) => {
    console.log("SUBMIT BUTTON CLICKED");

    const address = {
      street_address: data.streetAddress,
      lat_long: data.latLong,
      country: data.country,
      state: data.state,
    };
    const dest = {
      place_name: data.placeName,
      place_info: data.placeOverview,
      place_type: "neighbour",
      address: address,
      main_destination_id: mainDestinationId,
      is_published: true,
    };

    await makeApiCall("POST", `/admin/bluff-destination`, dest);
    setTimeout(() => {
      if (resultRef.current) {
        if (resultRef.current.status) {
          alert(`SUCCESS:  ${resultRef.current.message}`);
          history.replace(
            `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
          );
          // setRedirect(true);
        } else {
          alert(`FAIL: ${resultRef.current.message}`);
        }
      }
    }, 1000);
  };

  const testSubmit = async (e) => {
    e.preventDefault();
    console.log("SUBMIT BUTTON CLICKED");
  };
  const onInvalid = (errors) => {
    console.log("ERRORS CALLED");
    console.error(errors);
  };

  const createNeighbourForm = () => {
    return (
      <div>
        {/* onSubmit = {handleSubmit(onSubmit)} */}
        <form
          class="uk-form-stacked"
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <p className="uk-text-success ">Key Info(fields)</p>

          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Neighbour name..."
              // onChange={(e) => setName(e.target.value)}
              // value={name}
              {...register("placeName", {
                required: {
                  value: true,
                  message: "field is required",
                },
                minLength: {
                  value: 4,
                  message: "Place name must be at least 4 characters",
                },
              })}
            />
            <span className="uk-text-danger">{errors.placeName?.message}</span>
          </div>

          {/* <--  place info --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place info</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Neighbour info..."
              // onChange={(e) => setOverview(e.target.value)}
              // value={overview}
              {...register("placeOverview", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.placeOverview?.message}
            </span>
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>
          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Place Type (FIXED - neighbour)</label>
            {/* <select
              class="uk-select"
              id="form-stacked-select"
              disabled
              {...register("placeType", { required: true })}
            >
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select> */}
          </div>
          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>

          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
              // onChange={(e) => setAddress(e.target.value)}
              // value={address}
              {...register("streetAddress", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.streetAddress?.message}
            </span>
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="lat-long (optional)"
                // onChange={(e) => setLatLong(e.target.value)}
                // value={latLong}
                {...register("latLong")}
              />
            </div>

            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="country"
                // onChange={(e) => setCountry(e.target.value)}
                // value={country}
                {...register("country", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.country?.message}</span>
            </div>

            <div>
              <input
                class="uk-input"
                type="text"
                placeholder="state"
                // onChange={(e) => setState(e.target.value)}
                // value={state}
                {...register("state", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.state?.message}</span>
            </div>
          </div>

          {/* <span className="uk-text-danger">{errors.toString()}</span> */}
          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            // onClick={testSubmit}
            type="submit"
          >
            submit
          </button>
        </form>
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // MAIN UI
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <h2 className="uk-margin-small-top">Neighbour Form</h2>
      {createNeighbourForm()}
      {showLoading()}
      {errorMessage()}
      {/* {redirect && (
        <Redirect to={`/admin/destination-listing/${mainDestinationId}`} />
      )} */}
    </Wrapper>
  );
}

export default AddNeighbour;

const Wrapper = styled.main`
  background: #f2f2f2;
  margin-top: 2rem;
`;
