import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Redirect, Link, useLocation } from "react-router-dom";

// link - https://stackoverflow.com/a/71877172/9716479
function ErrorFallback({ error, resetErrorBoundary }) {
  const location = useLocation();
  const errorLocation = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.pathname]);

  return (
    <Wrapper>
      <div className="container uk-flex uk-flex-column uk-flex-middle uk-flex-center">
        <p>Oops! something went wrong on our end.</p>
        <Link to="/" className="uk-button uk-button-secondary">
          go to home
        </Link>
      </div>
    </Wrapper>
  );
}

export default ErrorFallback;

const Wrapper = styled.section`
  .container {
    height: 60vh;
  }
`;
