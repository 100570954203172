import React, { useState, useEffect, useRef } from "react";
import { videoList } from "../mockDb";
import styled from "styled-components";
import Loading from "../components/Loading";
import { FaRegBookmark, FaBookmark, FaHeart, FaRegHeart } from "react-icons/fa";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import ReactGA from "react-ga";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";

import Review from "../components/Review";
import destinations from "./../mockDb";
import HorizontalImageGallery from "../components/HorizontalImageGallery";
import ExpandNCollapseItem from "../components/ExpandNCollapseItem";
import CompleteVideoComponent from "../components/CompleteVideoComponent";
import useApi from "../utils/custom-hooks/useApi";
import useModal from "../utils/custom-hooks/useModal";
import {
  defaultImage,
  getUser,
  errorImage,
  imageNotFound,
  getCacheList,
  setCacheList,
} from "../utils/utility";
import BucketListAndForm from "../components/BucketListAndForm";
import CompleteVideoComponentNew from "../components/CompleteVideoComponentNew";
import CustomModal from "../components/CustomModal";
import BookmarkModal from "../components/BookmarkModal";
import ImageComponent from "../components/ImageComponent";
import { useAppContext } from "../contexts/appContext";
const url = "http://localhost:5000/destinations";
const mockCategories = ["beach", "adventure", "arts", "food"];

const list = [
  {
    name: "image name 1",
    url: "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
  },
  {
    name: "image 2",
    url: "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
  },
  {
    name: "image 3",
    url: "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
  },
  {
    name: "image 4",
    url: "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
  },
];

const di =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Stadtbild_M%C3%BCnchen.jpg/2560px-Stadtbild_M%C3%BCnchen.jpg";

// "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80";

// const callback = (list) => {
//   list.getEntries().forEach((entry) => {
//     console.log("LOADING TRACKER MAIN DESTINATION: ", entry);
//     ReactGA.timing({
//       category: "Load Performace",
//       variable: "Load Time",
//       value: entry.responseEnd - entry.requestStart,
//     });
//   });
// };
// var observer = new PerformanceObserver(callback);
// observer.observe({ entryTypes: ["navigation"] });

function MainDestination() {
  // Feather, Grommet-icons, Heroicons, Hero icons 2, vscode icons
  const { placeId } = useParams();
  const user = getUser();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  const [destination, setDestination] = useState(null);
  const [finalDestinations, setFinalDestinations] = useState([]);
  const [unsortedFds, setUnsortedFds] = useState([]);
  const [neighbours, setNeighbours] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [comments, setComments] = useState([]);
  const [sourceReferences, setSourceReferences] = useState([]);

  const [categories, setCategories] = useState(mockCategories);
  // const [error, setError] = useState(false);
  const destinationUrl = url + placeId;

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const { isShow, toggleModal } = useModal();

  // isShow - renders BucketListAndForm component first time only when bookmark-icon is clicked, if no clicked then don't show form i.e.,avoid rendering
  //  bookmarkClickCount - to update `showModal` value in BucketListAndForm via useEffect
  // const [isShow, setIsShow] = useState(false);
  const [modalPlaceId, setModalPlaceId] = useState("");
  const [bookmarkClickCount, setBookmarkClickCount] = useState(0);
  const [alreadyBookmarked, setAlreadyBookmarked] = useState(false);
  const [showReferenceItem, setShowReferenceItem] = useState(false);

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

  const intersectTarget = useRef(null);
  const { addInCache, getFromCache } = useAppContext();

  useEffect(() => {
    /**
    console.log("MAIN DESTINATION LOADING  useEffect");
    const callback = (list) => {
      list.getEntries().forEach((entry) => {
        // Load Time: entry.responseEnd - entry.requestStart
        //  Server Latency: entry.responseStart - entry.requestStart
        // Download Time: entry.responseEnd - entry.responseStart
        console.log("MAIN DESTINATION LOADING TRACKER: ", entry);
        console.log(
          "MAIN DESTINATION LOAD TIME: ",
          entry.responseEnd - entry.requestStart
        );
      });
    };
    var observer = new PerformanceObserver(callback);
    observer.observe({ entryTypes: ["navigation"] });
    */
  }, []);

  useEffect(() => {
    /**
    // link: https://stackoverflow.com/questions/72907673/how-to-use-the-performancenavigationtiming-api-to-get-page-load-time
    console.log("MAIN DESTINATION LOADING  useEffect");

    const perfObserver = new PerformanceObserver((observedEntries) => {
      const entry = observedEntries.getEntriesByType("navigation")[0];
      // console.log("PAGE LOAD TIME: ", entry.responseEnd - entry.requestStart);
      console.log("PAGE LOAD TIME: ", entry);
    });

    perfObserver.observe({
      type: "navigation",
      buffered: true,
    });
     */
  }, []);

  //Tracking Scroll: Use the observer inside the component you want to track scroll
  useEffect(() => {
    // const opts = {
    //   root: null,
    //   rootMargin: "0px",
    //   threshold: 0,
    // };
    // const callback = (list) => {
    //   list.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       ReactGA.event({
    //         category: "Scroll",
    //         action: "Scrolled to FAQ header",
    //         value: entry.intersectionRatio,
    //       });
    //     }
    //   });
    // };
    // const observerScroll = new IntersectionObserver(callback, opts);
    // observerScroll.observe(intersectTarget.current);
    // const onPageLoad = () => {
    // alert("loaded complete");
    // observerScroll.observe(intersectTarget.current);
    // };
    // Check if the page has already loaded
    // if (document.readyState === "complete") {
    //   onPageLoad();
    // } else {
    //   window.addEventListener("load", onPageLoad, false);
    //   // Remove the event listener when component unmounts
    //   return () => window.removeEventListener("load", onPageLoad);
    // }
  }, []);

  // TODO: use a custom hook like useSessionStorage for storing & retrieving data in whole app rather than copy pasting code
  // link 1: https://blog.logrocket.com/using-localstorage-react-hooks/
  // link 2: https://www.copycat.dev/blog/react-localstorage/
  useEffect(() => {
    // const savedData = sessionStorage.getItem(`${placeId}`);
    // const result = savedData ? JSON.parse(savedData) : null;

    const result = getFromCache(placeId);
    if (result) {
      onDataReceived(result);
      const item = sessionStorage.getItem("selectedIndex")
        ? sessionStorage.getItem("selectedIndex")
        : 0;
      setSelectedCategoryIndex(item);
    } else {
      fetchMainDestinationAllInfo();
    }
  }, []);

  const fetchMainDestinationAllInfo = async () => {
    await makeApiCall("GET", `/main-destination-all-info/${placeId}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        onDataReceived(resultRef.current);
        // sessionStorage.setItem(`${placeId}`, JSON.stringify(resultRef.current));
        addInCache(placeId, resultRef.current);
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const onDataReceived = (data) => {
    const { destination, finalDestinations, neighbours, comments } = data;
    setComments(comments);
    setDestination(destination);
    // setFinalDestinations(finalDestinations);
    // setUnsortedFds(finalDestinations);
    setNeighbours(neighbours);
    const uniqueCatgs = [
      ...new Set(finalDestinations.map((item) => item.category_type)),
    ];
    if (uniqueCatgs.length > 3) {
      let b = uniqueCatgs.splice(0, 3);
      setUniqueCategories(b);
    } else {
      setUniqueCategories(uniqueCatgs);
    }

    /* FILTERING WITH ANIMATION */
    setFinalDestinations(finalDestinations);
    setUnsortedFds(finalDestinations);

    /* FILTERING WITHOUT ANIMATION */
    // const sortedFds = finalDestinations.filter(
    //   (destination) => destination.category_type === uniqueCatgs[0]
    // );
    // setFinalDestinations(sortedFds);
    // setUnsortedFds(finalDestinations);

    const sr = destination.source_references;
    if (sr) {
      const result = sr.split("##");
      setSourceReferences(result);
    }

    setTimeout(() => {
      handleScrollPosition();
    }, 500);
  };

  const itemClick = (placeId) => {
    // history.push(`/final-destination/${placeId}`);

    sessionStorage.setItem("scrollPosition", window.pageYOffset);
    sessionStorage.setItem("selectedIndex", selectedCategoryIndex);
  };

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition");
      sessionStorage.removeItem("selectedIndex");
    }
  };

  const categoryClickHandler = (selectedCategory, index) => {
    /* FILTERING WITH ANIMATION */
    setSelectedCategoryIndex(index);

    /* FILTERING WITHOUT ANIMATION */
    // const sortedFds = unsortedFds.filter(
    //   (destination) => destination.category_type === selectedCategory
    // );
    // setFinalDestinations(sortedFds);
    // setSelectedCategoryIndex(index);
  };

  const bookmarkBtnClick = (id, isBookmarked) => {
    // setBookmarkClickCount(bookmarkClickCount + 1);
    setModalPlaceId(id);
    setAlreadyBookmarked(isBookmarked);
    toggleModal();
  };

  const referenceClickHandler = () => {
    setShowReferenceItem(!showReferenceItem);
  };

  const updateData = (placeId, newValue) => {
    let fds = finalDestinations.map((dest) => {
      if (dest.place_id === placeId) {
        return {
          ...dest,
          is_bookmarked: newValue,
        };
      }
      return dest;
    });
    let fds2 = unsortedFds.map((dest) => {
      if (dest.place_id === placeId) {
        return {
          ...dest,
          is_bookmarked: newValue,
        };
      }
      return dest;
    });

    setFinalDestinations(fds);
    setUnsortedFds(fds2);

    // const newResult = { ...completeInfo, finalDestinations: fds2 };
    // sessionStorage.setItem(`${placeId}`, JSON.stringify(newResult));

    //   // updating main destination info for local usage
    //   const savedData = sessionStorage.getItem(`${placeId}`);
    //   const result = savedData ? JSON.parse(savedData) : null;
    //   if (result) {
    //     const newResult = { ...result, finalDestinations: fds2 };
    //     sessionStorage.setItem(`${placeId}`, JSON.stringify(newResult));
    //   }
  };

  useEffect(() => {
    // updating main destination info for local usage
    const result = getFromCache(placeId);
    if (result) {
      const newResult = { ...result, finalDestinations: unsortedFds };
      addInCache(placeId, newResult);
    }
  }, [unsortedFds]);

  if (isLoading) {
    return <Loading />;
  }

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
            <img src={errorImage} alt="error" className="error-image" />
          </div>
        </>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      {errorMessage()}
      {/* {destination && <ImageComponent imageList={destination.images} />} */}
      {destination && (
        <div>
          <Helmet>
            <title>{destination.place_name} Travel Guide - travelBucket</title>
          </Helmet>

          {/* <ScrollRestoration /> */}
          {/* IMAGE GALLERY */}
          <HorizontalImageGallery imageList={destination.images} />

          {/* ABOUT DESTINATION SECTION */}
          <h2 className="uk-h2">{destination.place_name} Travel Guide</h2>
          <p>{destination.place_info}</p>

          {/* ONLY WATCH SECTION -- (Featured Videos) (Only Watch) ( <place_name> Videos) (Destination Videos)*/}
          {destination.only_watch.length > 0 && (
            <>
              <h3>Only Watch</h3>
              <p>
                If you don't like reading, simply watch video to discover
                places. You can do your hunt journey by watching videos. View
                these inspiring videos and discover places.
              </p>

              {/* TODO:  different category level*/}
              {/* <h4 style={{ color: "red" }}>
            One video should be based on different segments like- beaches,
            temple, park etc just like in youtube different section of a video
            rather than randomly showing all 20 places one by one
          </h4> */}

              {/* TODO: like glance */}
              {/* <h4 style={{ color: "red" }}>
            Can we add youtube in a mobile/desktop frame just like in glance
          </h4> */}

              {/* <CompleteVideoComponentNew videos={videoList} /> */}
              <CompleteVideoComponentNew videos={destination.only_watch} />
            </>
          )}

          {/* <section
            className="uk-grid-match uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m"
            data-uk-grid
            data-uk-scrollspy="cls: uk-animation-fade; target: .video-container; delay: 200"
          >
            {videoList.map((video) => {
              return <CompleteVideoComponent key={video.id} video={video} />;
            })}
          </section> */}

          {/* // https://stackoverflow.com/questions/34097560/react-js-replace-img-src-onerror
                        // onError={({ currentTarget }) => {
                        //   currentTarget.onerror = null; // prevents looping
                        //   currentTarget.src =
                        //     "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Stadtbild_M%C3%BCnchen.jpg/2560px-Stadtbild_M%C3%BCnchen.jpg";
                        // }}  */}

          {/* THINGS TO DO & SEE SECTION*/}
          {/* <h3>Things to Do & See</h3> */}
          <div className="uk-flex uk-flex-row uk-flex-between uk-flex-top uk-margin-medium-top">
            <h3>Things to Do & See</h3>
            {uniqueCategories.length === 3 && (
              <Link
                to={`/md-places/${destination.place_id}`}
                className="view-all-btn"
                onClick={itemClick}
              >
                view all
              </Link>
            )}
          </div>

          {/* FILTERING WITHOUT ANIMATION */}
          {/* <section className="uk-flex uk-flex-row uk-flex-between uk-flex-top uk-margin-small-bottom">
            <div>
              {uniqueCategories.map((category, index) => {
                return (
                  <button
                    className={
                      selectedCategoryIndex == index
                        ? `category-btn selected`
                        : `category-btn`
                    }
                    onClick={() => categoryClickHandler(category, index)}
                  >
                    {category}
                  </button>
                );
              })}
            </div>

            <Link
              to={`/md-places/${destination.place_id}`}
              className="view-all-btn uk-flex-none uk-visible@s"
            >
              view all
            </Link>
          </section>
          <section
            className="uk-grid-match uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m"
            data-uk-grid
          >
            {finalDestinations.map((dest) => {
              return (
                <article>
                  <div className="uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                    <div className="img-container">
                      <img
                        src={
                          dest.images && dest.images[0]
                            ? dest.images[0].image_url
                            : defaultImage
                        }
                        alt={dest.place_name}
                        onError={(e) => {
                          if (e.target.src !== errorImage) {
                            e.target.src = errorImage;
                          }
                        }}
                      />
                      <div className="bookmark-container">
                        {user && dest.is_bookmarked ? (
                          <FaHeart
                            className="bookmark-icon"
                            onClick={() =>
                              bookmarkBtnClick(dest.place_id, true)
                            }
                          />
                        ) : (
                          <FaRegHeart
                            className="bookmark-icon"
                            onClick={() =>
                              bookmarkBtnClick(dest.place_id, false)
                            }
                          />
                        )}
                      </div>
                    </div>

                    <Link
                      to={`/final-destination/${dest.place_id}`}
                      className="remove-underline"
                    >
                      <h4 className="uk-margin-medium-top">
                        {dest.place_name}
                      </h4>
                    </Link>
                    <p className="line-limit">{dest.place_info}</p>
                  </div>
                </article>
              );
            })}
          </section> */}

          {/* FILTERING WITH ANIMATION */}
          <div data-uk-filter="target: .js-filter">
            {/* <ul className="uk-subnav uk-subnav-pill">
              {uniqueCategories.map((category, index) => {
                return (
                  <li
                    className={
                      index === 0 ? "uk-active" : "category-btn-border"
                    }
                    data-uk-filter-control={`[data-color='${category}']`}
                  >
                    <a href="#"> {category}</a>
                  </li>
                );
              })}
            </ul> */}
            <div className="uk-flex uk-flex-row uk-flex-wrap uk-margin-small-bottom">
              {uniqueCategories.map((category, index) => {
                return (
                  <button
                    className={
                      selectedCategoryIndex == index
                        ? `uk-active category-btn selected`
                        : `category-btn`
                    }
                    // className={ index === 0 &&  "uk-active" }
                    data-uk-filter-control={`[data-color='${category}']`}
                    onClick={() => categoryClickHandler(category, index)}
                  >
                    {category}
                  </button>
                );
              })}
            </div>
            <section
              className="js-filter uk-grid-match uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m"
              data-uk-grid
              data-uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 200"
            >
              {finalDestinations.map((dest) => {
                return (
                  <article data-color={`${dest.category_type}`}>
                    <div className="uk-card uk-card-default uk-card-small uk-card-body uk-card-hover">
                      <div className="img-container">
                        <img
                          loading="lazy"
                          className="place-image"
                          src={
                            dest.images && dest.images[0]
                              ? dest.images[0].image_url
                              : defaultImage
                          }
                          alt={dest.place_name}
                          onError={(e) => {
                            if (e.target.src !== imageNotFound) {
                              e.target.src = imageNotFound;
                            }
                          }}
                        />
                        <div className="bookmark-container">
                          {user && dest.is_bookmarked ? (
                            <FaHeart
                              className="bookmark-icon"
                              onClick={() =>
                                bookmarkBtnClick(dest.place_id, true)
                              }
                            />
                          ) : (
                            <FaRegHeart
                              className="bookmark-icon"
                              data-uk-tooltip="title: add me in your bucket; pos: bottom-left"
                              onClick={() =>
                                bookmarkBtnClick(dest.place_id, false)
                              }
                            />
                          )}
                        </div>
                      </div>

                      <Link
                        to={`/final-destination/${dest.place_id}`}
                        className="remove-underline"
                      >
                        <h4 className="title-text" onClick={itemClick}>
                          {dest.place_name}
                        </h4>
                      </Link>

                      {/* <h4
                        className="title-text"
                        onClick={() => itemClick(dest.place_id)}
                      >
                        {dest.place_name}
                      </h4> */}

                      {/* <p className="uk-text-danger">{dest.category_type}</p> */}
                      <p className="line-limit uk-margin-remove-top">
                        {dest.place_info}
                      </p>
                    </div>
                  </article>
                );
              })}
            </section>
          </div>

          {/* BookmarkModal */}
          {/* {modalPlaceId && (
            <BucketListAndForm
              modalPlaceId={modalPlaceId}
              bookmarkClickCount={bookmarkClickCount}
              alreadyBookmarked={alreadyBookmarked}
              updateData={updateData}
            />
          )} */}
          {/* MODAL SECTION */}
          <CustomModal isShow={isShow} closeModal={toggleModal}>
            <BookmarkModal
              modalPlaceId={modalPlaceId}
              alreadyBookmarked={alreadyBookmarked}
              updateData={updateData}
            />
          </CustomModal>

          {/* HOW TO REACH - only include in video  */}
          {/* <div className="sub-heading-text mt">How to reach</div>
      <div className="linear-layout-horizontal">
        <h4>Nearest airport :</h4>
        <p> {destination.how_to_reach.via_plane} </p>
      </div>
      <div className="linear-layout-horizontal">
        <h4>Nearest railway station :</h4>
        <p> {destination.how_to_reach.via_train} </p>
      </div>
      <div className="linear-layout-horizontal">
        <h4>Nearest bust stop :</h4>
        <p> {destination.how_to_reach.via_bus} </p>
      </div> */}

          {/* BEST TIME TO VISIT SECTION - (When To Go) (Best Time to visit)*/}
          <h3>When To Go</h3>
          <p>
            {destination.best_time_to_go && destination.best_time_to_go[0].info}
          </p>
          {/* <ul data-uk-accordion>
            {destination &&
              destination.best_time_to_go.map((item) => {
                return (
                  <li key={item._id}>
                    <a className="uk-accordion-title">{item.title}</a>
                    <div className="uk-accordion-content">
                      <p>{item.info}</p>
                    </div>
                    <hr />
                  </li>
                );
              })}
          </ul> */}

          {/* THINGS TO KNOW SECTION- (IF EXIST / OPTIONAL) */}
          {destination && destination.things_to_know.length > 0 && (
            <>
              <h3>Things to Know</h3>
              <ul className="uk-list uk-list-square">
                {destination.things_to_know.map((it) => {
                  return <li key={it._id}>{it.item}</li>;
                })}
              </ul>
            </>
          )}

          {/* MORE INFO SECTION */}
          {destination && destination.more_info.length > 0 && (
            <>
              <h3>More about {destination.place_name}</h3>
              <ul data-uk-accordion>
                {destination &&
                  destination.more_info.map((item) => {
                    return (
                      <li key={item._id}>
                        <a className="uk-accordion-title">{item.title}</a>
                        <div className="uk-accordion-content">
                          <p>{item.description}</p>
                        </div>
                        <hr />
                      </li>
                    );
                  })}
              </ul>
            </>
          )}

          {/* TODO: FAQ */}
          {/* <h4 style={{ color: "red" }} ref={intersectTarget}>
            FAQs
          </h4> */}

          {/* REVIEW SECTION */}
          <Review
            allReviews={comments}
            placeId={placeId}
            fromDestination={true}
          />

          {sourceReferences && sourceReferences.length > 0 && (
            <>
              <div className="uk-flex uk-flex-row">
                <h5 className="uk-margin-remove-bottom">References</h5>
                {!showReferenceItem && (
                  <RiArrowDownSLine
                    className="arrow"
                    onClick={referenceClickHandler}
                  />
                )}
                {showReferenceItem && (
                  <RiArrowUpSLine
                    className="arrow"
                    onClick={referenceClickHandler}
                  />
                )}
              </div>
              {showReferenceItem && (
                <ul className="uk-list uk-list-square">
                  {sourceReferences.map((url, index) => {
                    return (
                      <li>
                        <a
                          key={index}
                          href={url}
                          className="item-text"
                          target="_blank"
                        >
                          {url}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .rd {
    border: 2px solid red;
  }
  p {
    text-align: justify;
  }

  .main-image {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
    margin-bottom: 2rem;
  }

  .category-btn {
    background: transparent;
    padding: 0.5rem 1rem;
    font-size: 15px;
    cursor: pointer;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    line-height: 18px;
    border: 1px solid #dadfe6;
    border-radius: 2px;
    color: #0f294d;

    /* color: #999; */
    /* font-size: 14px; */
    /* text-transform: uppercase; */
  }

  .category-btn:hover {
    /* border-color: rgba(50, 100, 255, 0.0784313725490196); */
    /* color: #3264ff; */
    /* background-color: rgba(50, 100, 255, 0.0784313725490196); */
  }
  .category-btn-border {
    border: 1px solid #dadfe6;
  }
  .selected {
    background: #007aff;
    color: #fff;
  }
  .view-all-btn {
    background: transparent;
    padding: 0.5rem 1rem;
    font-size: 16px;
    cursor: pointer;
    line-height: 18px;
    border: 1px solid #dadfe6;
    border-radius: 2px;
    text-decoration: none;
    color: #0f294d;

    /* color: #999; */
    /* font-size: 14px; */
    /* text-transform: uppercase; */
  }
  .view-all-btn:hover {
    font-weight: 700;
    border-color: rgba(50, 100, 255, 0.0784313725490196);
    color: #3264ff;
    background-color: rgba(50, 100, 255, 0.0784313725490196);
  }

  .line-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .img-container {
    position: relative;
    /* height: 20%; */
    /* background: #81a6ec; */
    background: var(--imageBackgroundColor);
    /* border: 2px solid red; */
  }

  .place-image {
    width: 100%;
    height: 14rem;
    /* aspect-ratio: 16/9; */
    object-fit: cover;
  }

  .bookmark-container {
    position: absolute;
    /* width: 2rem; */
    /* height: 2rem; */
    padding: 0.45rem;
    background: #fff;
    right: 1rem;
    bottom: 1rem;
    z-index: 5;
    /* border-radius: 50%; */
  }
  .remove-underline {
    text-decoration: none;
  }
  .title-text {
    margin: 20px 0 10px;
  }
  .title-text:hover {
    opacity: 0.8;
  }

  .arrow {
    /* grey icon */
    color: #666;
    width: 1.8rem;
    height: 1.8rem;
    cursor: pointer;
  }
  .arrow:hover {
    color: #575656;
  }

  .item-text {
    // font-size: 0.75rem;
    color: var(--color--text);
    // cursor: pointer;
  }
`;

export default MainDestination;
