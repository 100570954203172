import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";

import { getUser, removeUser } from "../utils/utility";

function MyProfilePage() {
  const [redirect, setRedirect] = useState(false);
  const user = getUser();

  const logoutUser = () => {
    removeUser();
    setRedirect(true);
  };
  return (
    <Wrapper
      className="uk-container uk-container-small uk-margin-medium-top"
      data-uk-scrollspy="cls:uk-animation-fade; delay:200"
    >
      <Helmet>
        <title>myProfile - travelBucket </title>
      </Helmet>

      <div className="wrap-container">
        {user && (
          <div>
            <h3 className="uk-margin-small-top uk-margin-remove-bottom">
              Welcome {user.name},
            </h3>
            <p className="uk-margin-small-top uk-margin-small-bottom">
              Email: {user.email}
            </p>
          </div>
        )}
        <p className="uk-text-primary uk-margin-remove-top">
          <Link to="/my-bucket">Go to myBucket</Link>
        </p>
        <button
          className="uk-button uk-button-secondary uk-margin-small-bottom"
          onClick={logoutUser}
        >
          log me out
        </button>
        {redirect && <Redirect to={`/login`} />}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .wrap-container {
    background: #ebf0f4;
    padding: 1rem;
  }

  .item-text {
    /* color: #1e87f0; */
    cursor: pointer;
  }
  .item-text:hover {
    text-decoration: underline;
  }
`;

export default MyProfilePage;
