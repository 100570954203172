import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { getUser } from "../utils/utility";
import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";

function BucketListAndForm({
  modalPlaceId,
  bookmarkClickCount,
  alreadyBookmarked,
  updateData,
}) {
  const user = getUser();

  const [bucketName, setBucketName] = useState("");
  const [bucketDescription, setBucketDescription] = useState("");
  const [bucketList, setBucketList] = useState([]);
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const [showModal, setShowModal] = useState(true);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (user) {
      fetchBuckets();
    }
  }, [modalPlaceId]);

  useEffect(() => {
    setShowModal(true);
  }, [bookmarkClickCount]);

  useEffect(() => {
    // let isExist = false;
    // bucketList.map((bucket) => {
    //   const item = bucket.places.find((place) => place == modalPlaceId);
    //   if (item) isExist = true;
    // });
    // const neddRefresh = alreadyBookmarked !== isExist;

    let isExist = false;
    var inputElements = document.getElementsByClassName("uk-checkbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        isExist = true;
      }
    }
    const neddRefresh = alreadyBookmarked === isExist ? false : true;

    if (user && neddRefresh) {
      // toast(`Refresh require ${alreadyBookmarked} ${isExist}`);
      updateData(modalPlaceId, !alreadyBookmarked);
    }
  }, [bucketList]);

  const fetchBuckets = async () => {
    await makeApiCall("GET", `/buckets/${user.user_id}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        setBucketList(resultRef.current.buckets);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const itemClick = () => {
    setShowForm(true);
  };

  const createBucketAndAddItemInIt = async () => {
    if (!bucketName) {
      alert("Please enter bucket name");
      return;
    }

    if (!bucketDescription) {
      alert("Please enter bucket description");
      return;
    }

    const data = {
      bucket_name: bucketName,
      bucket_description: bucketDescription,
      place_id: modalPlaceId,
      user_id: user.user_id,
    };

    await makeApiCall("POST", `/bucket/create-bucket-and-add-item`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        setBucketList(resultRef.current.buckets);
        setShowForm(false);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };
  const addItemInBucket = async (bucketId) => {
    const data = {
      bucket_id: bucketId,
      place_id: modalPlaceId,
      user_id: user.user_id,
    };

    // console.log("clicked - saveItems: ", data);

    await makeApiCall("POST", `/bucket/add-item`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        setBucketList(resultRef.current.buckets);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const removeItemFromBucket = async (bucketId) => {
    const data = {
      bucket_id: bucketId,
      place_id: modalPlaceId,
      user_id: user.user_id,
    };

    await makeApiCall("POST", `/bucket/remove-item`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        // alert(`SUCCESS:  ${resultRef.current.message}`);
        setBucketList(resultRef.current.buckets);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const checkboxEventListener = (e) => {
    if (e.target.checked) {
      addItemInBucket(e.target.value);
    } else {
      removeItemFromBucket(e.target.value);
    }
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const nonSignedInSection = () => {
    return (
      <div className="uk-text-center uk-margin-medium">
        <p>Please login to add place in your bucket.</p>
        <Link to="/login" className="uk-button uk-button-secondary">
          Login
        </Link>
      </div>
    );
  };

  const privateSection = () => {
    return (
      <>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Select bucket to add</h2>
        </div>
        {!showForm && (
          <div className="uk-modal-body">
            <section className="">
              {bucketList.map((bucket) => {
                const item = bucket.places.filter(
                  (place) => place == modalPlaceId
                );

                const isExist = item.length === 0 ? false : true;

                return (
                  <article
                    className="uk-flex uk-flex-row"
                    key={bucket.bucket_id}
                  >
                    <input
                      className="uk-checkbox uk-margin-remove-top"
                      type="checkbox"
                      value={bucket.bucket_id}
                      onChange={checkboxEventListener}
                      checked={isExist}
                    />

                    <h6 className="uk-margin-remove-top uk-margin-small-left">
                      {bucket.bucket_name}
                    </h6>
                  </article>
                );
              })}
            </section>
          </div>
        )}
        <div class="uk-modal-footer">
          {!showForm && (
            <h4 className="new-bucket-text" onClick={itemClick}>
              Create new bucket
            </h4>
          )}
          {showForm && (
            <div>
              <div class="uk-margin">
                <input
                  class="uk-input"
                  type="text"
                  placeholder="Name of your bucket"
                  value={bucketName}
                  onChange={(e) => setBucketName(e.target.value)}
                />
              </div>

              <div class="uk-margin">
                <textarea
                  class="uk-textarea"
                  rows="5"
                  placeholder="Description (optional)"
                  value={bucketDescription}
                  onChange={(e) => setBucketDescription(e.target.value)}
                ></textarea>
              </div>

              <button
                className="uk-button uk-button-secondary"
                onClick={createBucketAndAddItemInIt}
              >
                create
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <Wrapper>
      <div className={`${showModal ? "modal show" : "modal"}`}>
        <div class="modal-content">
          {/* <span class="close" onClick={closeModal}>
            &times;
          </span> */}
          <button
            class="close"
            type="button"
            onClick={closeModal}
            data-uk-close
          ></button>

          {!user && nonSignedInSection()}
          {user && privateSection()}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    padding-top: 100px; /*Location of the box */
    left: 0;
    top: 0;
    width: 100%; /*Full width */
    height: 100%; /*Full height*/
    overflow-y: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .modal-content {
    /* display: inline; */
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    width: 60%;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
  /* Add Animation */
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
  .show {
    display: block;
  }
  /* The Close Button */
  .close {
    /* color: #aaaaaa; */
    float: right;
    /* font-size: 2rem; */
    /* font-weight: bold; */
  }

  .close:hover,
  .close:focus {
    /* color: #000; */
    /* text-decoration: none; */
    /* cursor: pointer; */
  }

  .remove-margin {
    margin-top: 0px;
  }
  .new-bucket-text {
    color: #338280;
  }
  .new-bucket-text:hover {
    color: #0ddfd8;
    cursor: pointer;
  }
`;

export default BucketListAndForm;
