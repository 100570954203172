import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { contactEmail } from "../utils/utility";

function Contact() {
  const actualVersion = () => {
    return (
      <Wrapper>
        <div className="contact-title-container">
          <div
            className="uk-container uk-container-small contact-title"
            data-uk-scrollspy="cls:uk-animation-fade; delay:200"
          >
            <h2>CONTACT & CONNECT</h2>
            <p>
              Have a question to ask? Have query? Have suggestion? See the links
              below for contact info to reach out to me.
            </p>
          </div>
        </div>

        <div className="contact-info-container">
          <div className="contact-info">
            <div className="item">
              <h4>Email</h4>
              <hr />
              <p>abc@gmail.com</p>
            </div>
            <div className="item">
              <h4>Social links</h4>
              <hr />
              <div className="quick-link">
                <Link to={"/"}>Youtube</Link>
                <Link to={"/watch"}>Instagram</Link>
                <Link to={"/about"}>Facebook</Link>
                <Link to={"/about"}>Reddit</Link>
              </div>
            </div>
            <div className="item">
              <h4>Connect me on Linkedin (if you are)</h4>
              <hr />
              <p>abc@gmail.com</p>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  };

  const openMail = () => {
    const email = contactEmail;
    const emailTitle = "Get in touch";
    const emailBody = "Your query...";

    window.open(`mailto:${email}?subject=${emailTitle}&body=${emailBody}`);
    // window.location(`mailto:${email}`);
  };

  return (
    <WrapperTemp>
      <Helmet>
        <title>Contact - travelBucket </title>
      </Helmet>

      <section className="get-in-touch-container">
        <div
          className="uk-container uk-container-small item-padding"
          data-uk-scrollspy="cls:uk-animation-fade; delay:200"
        >
          <p className="text-4 uk-margin-small-bottom">
            Have query? Have suggestion? Connect with me?
          </p>
          <h2 className="text-5 uk-margin-remove-top uk-margin-small-bottom">
            Lets Get In Touch !
          </h2>
          <p className="small-text uk-margin-small-bottom">
            If you're a programmer, connect me on
            <a
              href="https://www.linkedin.com/in/gopaldan"
              className="uk-margin-small-left"
              target="_blank"
            >
              LinkedIn
            </a>
            {/* <a
              target="_blank"
              href="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies"
            >
              Policies
            </a> */}
          </p>

          {/* <div className="email-container-1 uk-flex uk-flex-row uk-flex-middle uk-width-1-3@s">
            <FaThumbsUp className="email-icon"></FaThumbsUp>
            <p className="email-value">hello125@gmail.com</p>
          </div> */}

          <div
            className="email-container"
            onClick={openMail}
            data-uk-tooltip="title: click to email us; pos: bottom-right"
          >
            <AiOutlineMail className="email-icon" />
            <p className="email-value">{contactEmail}</p>
          </div>
        </div>
      </section>
    </WrapperTemp>
  );
}

const WrapperTemp = styled.div`
  .get-in-touch-container {
    /* background-color: #223c50; */
    background: linear-gradient(to right, #3a6073, #16222a);
    /* color: #fff; */
  }

  .item-padding {
    padding: 8rem 0;
  }

  .text-4 {
    font-size: 1.5rem;
    color: #fff;
  }
  .text-5 {
    font-size: 1.75rem;
    color: #fff;
  }
  --clr-grey-5: #617d98;
  .small-text {
    color: var(--clr-grey-5);
    font-size: 0.875rem;
  }

  .email-container-1 {
    border: 1px solid var(--clr-grey-5);
  }

  .email-container {
    height: 3rem;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--color--primary);
    transition: border 0.3s linear;
    /* color: var(--clr-grey-5); */
  }

  .email-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 0.5rem;
    color: var(--color--primary);
    transition: color 0.3s linear;
  }

  .email-value {
    display: inline;
    margin-right: 1rem;
    color: var(--color--primary);
    transition: color 0.3s linear;
  }

  .email-container:hover {
    border-color: var(--clr-grey-5);
  }
  .email-container:hover .email-value {
    color: var(--clr-grey-5);
  }
  .email-container:hover .email-icon {
    color: var(--clr-grey-5);
  }

  @media screen and (min-width: 641px) and (max-width: 960px) {
    .item-padding {
      padding: 8rem 30px;
    }

    .text-4 {
      font-size: 1.2rem;
    }

    .text-5 {
      font-size: 1.3rem;
    }
  }

  @media screen and (max-width: 576px) {
    .item-padding {
      padding: 8rem 15px;
    }
    .text-4 {
      font-size: 1.1rem;
    }

    .text-5 {
      font-size: 1.25rem;
    }
  }
`;

const Wrapper = styled.div`
  background: #000;

  .contact-title-container {
    background: #fcb900;
    height: 70vh;
  }

  .contact-title {
    display: flex;
    height: 70vh;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
  }
  .contact-title h2 {
    width: 50%;
    font-size: 1.75rem;
    text-align: center;
    border: 1px solid red;
  }
  .contact-title p {
    width: 50%;
    font-size: 1.25rem;
    margin-top: 0;
    text-align: justify;
    border: 1px solid red;
  }

  .contact-info-container {
    background: #000;
    border-bottom: 1px solid red;
  }
  .contact-info {
    width: 80%;
    margin: 2rem auto;

    ${"" /* padding: 4rem 6rem; */}
  }

  .item {
    margin-bottom: 2.5rem;
  }
  .item hr {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
  }

  .item p {
    color: #fff;
    font-size: 1.5rem;
  }

  .quick-link {
    display: flex;
    flex-direction: column;
  }
  .quick-link a {
    margin-right: 1.5rem;
    color: #fff;
    font-size: 1rem;
  }

  /*  */
  @media screen and (max-width: 960px) {
    h2 {
      font-size: 1.5rem;
    }
  }

  /* small */
  @media screen and (max-width: 640px) {
    .contact-title {
      flex-direction: column;
      justify-content: center;
    }
    .contact-title h2 {
      /* width: 50%; */
      font-size: 1.5rem;
      /* text-align: center; */
      border: 1px solid red;
    }
    .contact-title p {
      /* width: 50%; */
      font-size: 1.25rem;
      /* margin-top: 0; */
      /* text-align: justify; */
      /* border: 1px solid red; */
    }
    h2 {
      font-size: 1.25rem;
    }
  }
`;
export default Contact;
