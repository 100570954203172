import React from "react";
import Trip from "./Trip-com";

function DesignInspiration() {
  return (
    <>
      <h3 style={{ color: "red" }}>Trip.com</h3>
      <Trip />
    </>
  );
}

export default DesignInspiration;
