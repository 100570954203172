import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";

function ChangePasswordPage() {
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const history = useHistory();

  const handlePasswordChange = async (data) => {
    const userInfo = {
      password: data.password,
      confirm_password: data.confirmPassword,
    };
    await makeApiCall("PUT", `/password/reset/${token}`, userInfo);
    if (resultRef.current) {
      if (resultRef.current.status) {
        const { message } = resultRef.current;
        toast.success(message);
        setTimeout(() => {
          history.replace("/login");
        }, 3000);
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const changePasswordForm = () => {
    return (
      <div className="">
        <div className="uk-form-width-large">
          <div className="uk-card uk-card-default uk-card-body uk-card-small uk-card-hover">
            <form
              class="uk-form-stacked "
              onSubmit={handleSubmit(handlePasswordChange)}
            >
              <div class="uk-margin">
                <label class="uk-form-label">New Password</label>
                <input
                  class="uk-input"
                  type="text"
                  placeholder="new password"
                  {...register("password", {
                    required: {
                      value: true,
                      message: "password is required",
                    },
                    minLength: {
                      value: 5,
                      message: "password should be of min 5 char",
                    },
                  })}
                />
                <span className="uk-text-danger">
                  {errors.password?.message}
                </span>
              </div>

              <div class="uk-margin">
                <label class="uk-form-label">Confirm your new password</label>
                <input
                  class="uk-input"
                  type="password"
                  placeholder="confirm password"
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message: "confirm password is required",
                    },
                    minLength: {
                      value: 5,
                      message: "confirm password should be of min 5 char",
                    },
                  })}
                />
                <span className="uk-text-danger">
                  {errors.confirmPassword?.message}
                </span>
              </div>
              <button
                className="uk-button uk-button-secondary uk-margin-small-bottom"
                type="submit"
                disabled={isLoading}
              >
                update
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger uk-margin-remove-top" data-uk-alert>
            <p className="uk-text-danger">{error.msg}</p>
          </div>
        </>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Change Password - travelBucket </title>
      </Helmet>

      <div className="inner-container">
        <div className="uk-flex uk-flex-center">
          <div>
            <h2 className="uk-text-center">New Password</h2>
            {showLoading()}
            {errorMessage()}
            {changePasswordForm()}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.main`
  background: #ebf0f4;
  .inner-container {
    padding: 3rem 0;
  }
`;
export default ChangePasswordPage;
