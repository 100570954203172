import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { contactEmail } from "../utils/utility";

function CopyrightReportPage() {
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <Helmet>
        <title>Copyright Report - travelBucket </title>
      </Helmet>
      <h2>Copyright Report:</h2>
      <p>
        At <span className="item">TravelBucket</span> , we use copyrighted free
        images and videos & there might be some video clips used under fair use
        policy. We respect the creative work of others and make every effort to
        provide proper attribution to the original authors.However, we
        acknowledge that mistakes can happen on our part.
      </p>
      <p>
        If you believe that we have not given proper credit for any media
        (image, video), or if you wish to have your media removed from our
        platform, we kindly request that you reach out to us via email at{" "}
        <span className="item-1"> {contactEmail}</span> before taking any
        further action. We value open communication and are dedicated to
        addressing any concerns promptly.
      </p>
      <p>
        If we have mistakenly made an error in the use of your media, we
        sincerely apologize for any inconvenience caused. We appreciate your
        understanding and would like to rectify any mistakes that may have
        occurred.
      </p>
    </Wrapper>
  );
}

export default CopyrightReportPage;

const Wrapper = styled.main`
  p {
    text-align: justify;
  }
  .item {
    font-weight: 500;
  }
  .item-1 {
    font-weight: 400;
    text-decoration: underline;
    color: #1e87f0;
  }
`;
