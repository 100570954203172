import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";

// Temp for design practice
import DesignInspiration from "./designRemember/design-inspiration/DesignInspiration";

import "./index.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import ErrorFallback from "./components/ErrorFallback";
import ToastContainer from "./components/ToastContainer";

import AdminRoute from "./utils/AdminRoute";
import PrivateRoute from "./utils/PrivateRoute";

import Home from "./pages/Home";
import Watch from "./pages/Watch";
import MyBucket from "./pages/MyBucket";
import About from "./pages/About";
import MainDestination from "./pages/MainDestination";
import FinalDestination from "./pages/FinalDestination";
import AdminHome from "./pages/admin/AdminHome";
import AddDestination from "./pages/admin/AddDestination";
import Contact from "./pages/Contact";
import AllFinalDestinationsOfMainDestination from "./pages/AllFinalDestinationsOfMainDestination";
import SingleBucketPage from "./pages/SingleBucketPage";
import AddCategoryNTheme from "./pages/admin/AddCategoryNTheme";
import AddPlaceTypes from "./pages/admin/AddPlaceTypes";
import EditDestination from "./pages/admin/EditDestination";
import DestinationListing from "./pages/admin/DestinationListing";
import DestinationImages from "./pages/admin/DestinationImages";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import MyProfilePage from "./pages/MyProfilePage";
import ThankYou from "./pages/ThankYouPage";
import EditorPage from "./pages/admin/EditorPage";
import SingleArticlePage from "./pages/SingleArticlePage";
import ArticleListing from "./pages/admin/ArticleListing";
import PageNotFound from "./pages/PageNotFound";
import RouteChangeTracker from "./components/RouteChangeTracker";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import DisclaimerPage from "./pages/DisclaimerPage";
import CopyrightReportPage from "./pages/CopyrightReportPage";
import { checkExpiryOfUser } from "./utils/utility";
import AddSubDestination from "./pages/admin/components/AddSubDestination";
import AddNeighbour from "./pages/admin/components/AddNeighbour";
import TinyMainEditor from "./blog-editor/tinymce/TinyMainEditor";
import CKMainEditor from "./blog-editor/ck/CKMainEditor";
import ImageListing from "./pages/admin/ImageListing";

/**
 *  show navbar only for specific routes react - https://stackoverflow.com/a/47282032/9716479
 * 
 *   404 on refresh in mobil devices - https://stackoverflow.com/a/64516145/9716479
      https://create-react-app.dev/docs/deployment/#netlify-https-wwwnetlifycom

 * PROPERTY_ID: 352901310     
 */

// * TODO:  UNCOMMENT  GA intialization code in PRODUCTION*/
const TRACKING_ID = "G-P1H8P2YGJR";
// const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

// ReactGA.initialize(TRACKING_ID, {
//   debug: true,
//   gaOptions: {
//     siteSpeedSampleRate: 100,
//   },
// });

// TODO:  React Google Analytics - Events sending but not showing up in google analytics dashboard
/**
const callback = (list) => {
  list.getEntries().forEach((entry) => {
    console.log("LOADING TRACKER: ", entry);
    ReactGA.timing({
      category: "Load Performace",
      variable: "Server Latency",
      value: entry.responseStart - entry.requestStart,
    });

    // ReactGA.timing({
    //   category: "Load Performace",
    //   variable: "Download Time",
    //   value: entry.responseEnd - entry.responseStart,
    // });

    ReactGA.timing({
      category: "Load Performace",
      variable: "Load Time",
      value: entry.responseEnd - entry.requestStart,
    });
  });
};
var observer = new PerformanceObserver(callback);
observer.observe({ entryTypes: ["navigation"] });
*/

function App() {
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    checkExpiryOfUser();
    setTimeout(() => {
      setShowCookieConsent(true);
    }, 10000);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="app-container">
        <Helmet>
          <title>travelBucket </title>
        </Helmet>
        <Navbar />
        <ToastContainer />
        <RouteChangeTracker />
        {/* TODO: load this component after 3s of main-page loading as it improves Cumulative layout shift */}
        {showCookieConsent && (
          <CookieConsent debug={false} buttonText="Got it" expires={30}>
            This website uses cookies to enhance the user experience.
          </CookieConsent>
        )}
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <main>
            <Switch>
              {/* --- PUBLIC ROUTES --- */}
              <Route exact path="/" component={Home} />
              <Route exact path="/watch" component={Watch} />
              <Route exact path="/About" component={About} />
              <Route
                exact
                path="/main-destination/:placeId"
                component={MainDestination}
              />
              <Route
                exact
                path="/final-destination/:placeId"
                component={FinalDestination}
              />
              <Route
                exact
                path="/md-places/:placeId"
                component={AllFinalDestinationsOfMainDestination}
              />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/change-password/:token"
                component={ChangePasswordPage}
              />
              <Route exact path="/thank-you" component={ThankYou} />
              <Route
                exact
                path="/article/:articleId"
                component={SingleArticlePage}
              />
              <Route exact path="/my-bucket" component={MyBucket} />
              <Route
                exact
                path="/privacy-policy"
                component={PrivacyPolicyPage}
              />
              <Route exact path="/disclaimer" component={DisclaimerPage} />
              <Route
                exact
                path="/copyright-report"
                component={CopyrightReportPage}
              />

              {/* --- PRIVATE ROUTES --- */}
              <PrivateRoute
                exact
                path="/single-bucket/:bucketId"
                component={SingleBucketPage}
              />
              <PrivateRoute
                exact
                path="/my-profile"
                component={MyProfilePage}
              />

              {/* --- ADMIN ROUTES --- */}
              <AdminRoute exact path="/admin" component={AdminHome} />
              <AdminRoute
                exact
                path="/admin/editor/:actionType/:articleId"
                component={EditorPage}
              />

              <AdminRoute
                exact
                path="/admin/articles"
                component={ArticleListing}
              />
              <AdminRoute
                exact
                path="/admin/add-destination"
                component={AddDestination}
              />
              <AdminRoute
                exact
                path="/admin/add-subdestination/:mainDestinationId"
                component={AddSubDestination}
              />
              <AdminRoute
                exact
                path="/admin/add-neighbour/:mainDestinationId"
                component={AddNeighbour}
              />
              <AdminRoute
                exact
                path="/admin/destination-listing/:placeId"
                component={DestinationListing}
              />
              <AdminRoute
                exact
                path="/admin/destination-images/:placeId"
                component={DestinationImages}
              />
              <AdminRoute
                exact
                path="/admin/image-listing/:mediumType/:mediumId"
                component={ImageListing}
              />
              <AdminRoute
                exact
                path="/admin/edit-destination/:placeType/:placeId"
                component={EditDestination}
              />
              <AdminRoute
                exact
                path="/admin/add-place-type"
                component={AddPlaceTypes}
              />
              <AdminRoute
                exact
                path="/admin/add-category-&-theme"
                component={AddCategoryNTheme}
              />
              {/* TEMP routes- for editor */}
              <AdminRoute
                exact
                path="/admin/editor/tinymce"
                component={TinyMainEditor}
              />
              <AdminRoute
                exact
                path="/admin/editor/ck"
                // path="/temp/editor/ck"
                component={CKMainEditor}
              />

              <Route exact path="*" component={PageNotFound} />

              {/* Temp routes - design practice routes */}

              <Route
                exact
                path="/design-inspiration"
                component={DesignInspiration}
              />
            </Switch>
          </main>
        </ErrorBoundary>
        <Footer />
      </div>
    </Router>
  );
}

// export default App;
export default Sentry.withProfiler(App);

/**
 * Error Boundary
 * (blog) - https://blog.logrocket.com/react-error-handling-react-error-boundary/
 * (react-error-boundary docs) - https://github.com/bvaughn/react-error-boundary
 * (blog) - https://kentcdodds.com/blog/use-react-error-boundary-to-handle-errors-in-react
 *
 */

/**
 * Install google tag in react js
 * https://dev.classmethod.jp/articles/google-analytics-and-google-tag-manager-setup-into-react-project/
 * https://dwe.dev/posts/adding-google-tag-manager-to-react-apps/
 *
 */

/**
 * Whether to use GTM or GA in WEBSITE.
 * https://www.analyticsmania.com/post/google-tag-manager-vs-google-analytics/
 * https://www.optimizesmart.com/15-key-differences-between-google-tag-manager-gtm-and-google-analytics-ga/
 * https://www.mparticle.com/blog/google-tag-manager-vs-google-analytics/
 */

// things we can measure through GA & how to implement them [blogs, course on homepage]
