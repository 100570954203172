import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";

function ForgotPasswordPage() {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [url, setUrl] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  const handleForgotPassword = async (data) => {
    const userInfo = {
      email: data.email,
    };
    await makeApiCall("POST", `/forgotPassword`, userInfo);
    if (resultRef.current) {
      if (resultRef.current.status) {
        const { message, link } = resultRef.current;
        setUrl(link);
        setIsEmailSent(true);
        toast.success("Wohoo... email sent successfully!");
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const forgotPasswordForm = () => {
    return (
      <div className="uk-form-width-large form-container-small">
        <div className="uk-card uk-card-default uk-card-body uk-card-small uk-card-hover">
          <form
            class="uk-form-stacked "
            onSubmit={handleSubmit(handleForgotPassword)}
          >
            <div class="uk-margin">
              <label class="uk-form-label">Email</label>
              <input
                class="uk-input"
                type="text"
                placeholder="Your email"
                {...register("email", {
                  required: {
                    value: true,
                    message: "email is required",
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "This value should be a valid email.",
                    //   message: "not a valid email format",
                    //   value: /\S+@\S+\.\S+/,
                  },
                })}
              />
              <span className="uk-text-danger">{errors.email?.message}</span>
            </div>

            <button
              className="uk-button uk-button-secondary uk-margin-small-bottom"
              type="submit"
              disabled={isLoading}
            >
              Send
            </button>
          </form>
        </div>
      </div>
    );
  };

  const restPasswordInfo = () => {
    return (
      <div className="uk-container uk-container-small uk-margin-medium-top">
        <h2>Reset Password</h2>
        <h6>For now please click the link & reset your password - </h6>
        <a href={url}>{url}</a>

        {/* <h6>Check your email</h6>
        <p>
          Thanks. If there's an account associated with this email address,
          we'll send the password reset instructions.
        </p>
        <p>- This link is valid for the next 24 hours.</p>
        <p>
          - If you don't receive an email within 10 minutes, check your spam
          folder first and then try again.
        </p>
        <p>
          - If you did find it in your spam folder, we recommend adding
          'support@travelbucket.co.in' to your trusted sender list.
        </p>
        <p>
          - If you are still having trouble, please email us at
          support@travelbucket.co.in
        </p> */}
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger uk-margin-remove-top" data-uk-alert>
            <p className="uk-text-danger uk-text-cente">{error.msg}</p>
          </div>
        </>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  //   MAIN UI
  return (
    <Wrapper>
      <Helmet>
        <title>
          {isEmailSent ? "Reset Password" : "Forgot Password"} - travelBucket{" "}
        </title>
      </Helmet>

      {!isEmailSent && (
        <div className="inner-container">
          <div className="uk-flex uk-flex-center">
            <div>
              {/* (Recover password) / ( Forgot your password?)*/}
              <h2 className="uk-text-center">
                Forgot your password?
                {/* {isEmailSent ? "Reset Password " : "Forgot your password?"} */}
              </h2>
              {showLoading()}
              {errorMessage()}
              {forgotPasswordForm()}
            </div>
          </div>
        </div>
      )}
      {isEmailSent && restPasswordInfo()}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .inner-container {
    background: #ebf0f4;
    padding: 3rem 0;
  }
  @media screen and (max-width: 576px) {
    .form-container-small {
      width: 320px;
    }
  }
  @media screen and (max-width: 320px) {
    .form-container-small {
      width: 280px;
    }
  }
`;
export default ForgotPasswordPage;
