import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

import mockData from "./../mockDb";
import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";
import {
  defaultImage,
  getUser,
  errorImage,
  imageNotFound,
} from "../utils/utility";
import { FaRegBookmark, FaBookmark, FaHeart, FaRegHeart } from "react-icons/fa";
import BucketListAndForm from "../components/BucketListAndForm";
import CustomModal from "../components/CustomModal";
import BookmarkModal from "../components/BookmarkModal";
import useModal from "../utils/custom-hooks/useModal";
import { useAppContext } from "../contexts/appContext";

const mockCategories = [
  "Beachs",
  "Adventure",
  "Arts",
  "Desert",
  "Entertainment",
  "Experiences",
  "Food & Drink",
  "History & Heritage",
  "Sports",
  "Theme Park",
  "Museums",
  "Nature",
  "NightLife",
  "Shopping",
];

function PlaceCategory({ category, index, filterData }) {
  const [isShow, setIsShow] = useState(false);

  const categoryBtnClickHandler = () => {
    setIsShow((oldValue) => {
      return !oldValue;
    });
    filterData(category, isShow);
  };

  return (
    <>
      <button
        className={isShow ? `category-btn selected` : `category-btn`}
        onClick={categoryBtnClickHandler}
      >
        {category}
      </button>
    </>
  );
}

function AllFinalDestinationsOfMainDestination() {
  const { placeId } = useParams();
  const user = getUser();

  const [categories, setCategories] = useState(mockCategories);
  const [destinationPlaces, setDestinationPlaces] = useState(
    mockData[0].destination_places
  );

  const [destination, setDestination] = useState();
  const [finalDestinations, setFinalDestinations] = useState([]);
  const [sortedFds, setSortedFds] = useState([]);
  const [unsortedFds, setUnsortedFds] = useState([]);
  const [neighbours, setNeighbours] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const { isShow, toggleModal } = useModal();

  const [modalPlaceId, setModalPlaceId] = useState("");
  const [bookmarkClickCount, setBookmarkClickCount] = useState(0);
  const [alreadyBookmarked, setAlreadyBookmarked] = useState(false);

  const { addInCache, getFromCache } = useAppContext();

  useEffect(() => {
    // fetchMainDestinationAllInfo();

    // const savedData = sessionStorage.getItem(`${placeId}`);
    // const result = savedData ? JSON.parse(savedData) : null;

    const result = getFromCache(placeId);

    if (result) {
      onDataReceived(result);
    } else {
      fetchMainDestinationAllInfo();
    }
  }, []);

  const fetchMainDestinationAllInfo = async () => {
    await makeApiCall("GET", `/main-destination-all-info/${placeId}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        onDataReceived(resultRef.current);
        // sessionStorage.setItem(`${placeId}`, JSON.stringify(resultRef.current));
        addInCache(placeId, resultRef.current);
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const onDataReceived = (data) => {
    const { destination, finalDestinations, neighbours } = data;
    setDestination(destination);
    setFinalDestinations(finalDestinations);
    setUnsortedFds(finalDestinations);
    setNeighbours(neighbours);
    const uniqueCatgs = [
      ...new Set(finalDestinations.map((item) => item.category_type)),
    ];
    setUniqueCategories(uniqueCatgs);

    setTimeout(() => {
      handleScrollPosition();
    }, 500);
  };

  const itemClick = () => {
    sessionStorage.setItem("scrollPosition-f", window.pageYOffset);
  };

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition-f");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition-f");
    }
  };

  const filterData = (selectedCategory, isSelected) => {
    let data;
    if (!isSelected) {
      //add-items
      const fds = unsortedFds.filter(
        (destination) => destination.category_type === selectedCategory
      );
      data = [...sortedFds, ...fds];
    } else {
      //remove-items
      const fds = sortedFds.filter(
        (destination) => destination.category_type !== selectedCategory
      );
      data = [...fds];
    }

    if (data.length == 0) {
      //selected items unselected
      data = unsortedFds;
      setSortedFds([]);
    } else {
      setSortedFds(data);
    }

    setFinalDestinations(data);
  };

  const bookmarkBtnClick = (id, isBookmarked) => {
    // setBookmarkClickCount(bookmarkClickCount + 1);
    setModalPlaceId(id);
    setAlreadyBookmarked(isBookmarked);
    toggleModal();
  };

  const updateData = (placeId, newValue) => {
    let fds = finalDestinations.map((dest) => {
      if (dest.place_id === placeId) {
        return {
          ...dest,
          is_bookmarked: newValue,
        };
      }
      return dest;
    });
    let fds2 = unsortedFds.map((dest) => {
      if (dest.place_id === placeId) {
        return {
          ...dest,
          is_bookmarked: newValue,
        };
      }
      return dest;
    });
    let fds3 = sortedFds.map((dest) => {
      if (dest.place_id === placeId) {
        return {
          ...dest,
          is_bookmarked: newValue,
        };
      }
      return dest;
    });
    setFinalDestinations(fds);
    setUnsortedFds(fds2);
    setSortedFds(fds3);
  };

  if (isLoading) {
    return <Loading />;
  }

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
          </div>
          <img src={errorImage} alt="error" className="error-image" />
        </>
      );
    }
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      {errorMessage()}
      {destination && (
        <>
          <Helmet>
            <title>
              All Places around {destination.place_name} - travelBucket
            </title>
          </Helmet>
          <h2>Explore all places around {destination.place_name}</h2>

          <section className="uk-flex uk-flex-row uk-flex-wrap stick-item">
            {uniqueCategories.map((category, index) => {
              return (
                <PlaceCategory category={category} filterData={filterData} />
              );
            })}
          </section>

          {/*  */}
          <section
            className="uk-margin-medium-top"
            data-uk-scrollspy="cls: uk-animation-fade; target: .change-direction; delay:200"
          >
            {finalDestinations.map((finalDest) => {
              return (
                <article>
                  <div className="uk-flex uk-flex-row change-direction">
                    <div className="uk-width-1-3@s img-container">
                      <img
                        src={
                          finalDest.images[0]
                            ? finalDest.images[0].image_url
                            : defaultImage
                        }
                        alt={finalDest.place_name}
                      />
                      <div className="bookmark-container">
                        {user && finalDest.is_bookmarked ? (
                          <FaHeart
                            className="bookmark-icon"
                            onClick={() =>
                              bookmarkBtnClick(finalDest.place_id, true)
                            }
                          />
                        ) : (
                          <FaRegHeart
                            className="bookmark-icon"
                            data-uk-tooltip="title: add me in your bucket; pos: bottom-left"
                            onClick={() =>
                              bookmarkBtnClick(finalDest.place_id, false)
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="uk-width-2-3@s text-container">
                      <Link
                        to={`/final-destination/${finalDest.place_id}`}
                        className="remove-underline"
                      >
                        <h4 className="place-name" onClick={itemClick}>
                          {finalDest.place_name}
                        </h4>
                      </Link>
                      <p className="uk-margin-remove-top line-limit">
                        {finalDest.place_info}
                      </p>
                    </div>
                  </div>
                  <div className="border uk-margin-small-top uk-margin-medium-bottom"></div>
                </article>
              );
            })}
          </section>

          {/*  */}
          {/* {modalPlaceId && (
        <BucketListAndForm
          modalPlaceId={modalPlaceId}
          bookmarkClickCount={bookmarkClickCount}
          alreadyBookmarked={alreadyBookmarked}
          updateData={updateData}
        />
      )} */}
          {/* MODAL SECTION */}
          <CustomModal isShow={isShow} closeModal={toggleModal}>
            <BookmarkModal
              modalPlaceId={modalPlaceId}
              alreadyBookmarked={alreadyBookmarked}
              updateData={updateData}
            />
          </CustomModal>
        </>
      )}
    </Wrapper>
  );
}

export default AllFinalDestinationsOfMainDestination;

const Wrapper = styled.main`
  p {
    text-align: justify;
  }
  .category-btn {
    background: transparent;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    margin: 0.5rem;
    line-height: 18px;
    border: 1px solid #dadfe6;
    border-radius: 2px;
    color: #0f294d;
  }
  .category-btn:hover {
    /* border-color: rgba(50, 100, 255, 0.0784313725490196); */
    /* color: #3264ff; */
    /* background-color: rgba(50, 100, 255, 0.0784313725490196); */
  }
  .selected {
    background: #007aff;
    color: #fff;
  }

  .b-img {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    /* background-size: cover; */
    background-color: "#ebe4e4";
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }

  .border {
    background-color: #c5c5c5;
    height: 1px;
    width: 100%;
    margin-top: 0.5rem;
  }
  .remove-underline {
    text-decoration: none;
  }
  .img-container {
    position: relative;
    background: var(--imageBackgroundColor);
    /* border: 2px solid red; */
  }
  .bookmark-container {
    position: absolute;
    /* width: 2rem; */
    /* height: 2rem; */
    padding: 0.45rem;
    background: #fff;
    right: 1rem;
    bottom: 1rem;
    z-index: 5;
    /* border-radius: 50%; */
  }
  .place-name {
    margin-bottom: 10px;
  }
  .text-container {
    margin-left: 10px;
  }

  .line-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .stick-items {
    position: sticky;
    top: 4rem;
    z-index: 12;
    /* background: #abd4f5; */
    background: #f8f8f8;
  }

  @media screen and (max-width: 576px) {
    .text-container {
      margin-left: 0;
    }
    .change-direction {
      flex-direction: column;
    }
    .place-name {
      margin: 20px 0 10px;
    }
  }
`;
