import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

import Loading from "../../../components/Loading";
import useApi from "../../../utils/custom-hooks/useApi";
import { categories, mandatoryField, placeTypes } from "../../../utils/utility";

function EditSubDestination({ placeId }) {
  const [mainDestinationId, setMainDestinationId] = useState("");
  const [redirect, setRedirect] = useState(false);

  const [travelTipId, setTravelTipId] = useState(null);
  const [travelTip, setTravelTip] = useState("");
  const [travelTipList, setTravelTipList] = useState([]);

  const [notToMissId, setNotToMissId] = useState(null);
  const [notToMiss, setNotToMiss] = useState("");
  const [notToMissList, setNotToMissList] = useState([]);
  const [makePublish, setMakePublish] = useState(false);
  const history = useHistory();

  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    fetchFinalDestination();
  }, []);

  const fetchFinalDestination = async () => {
    await makeApiCall("GET", `/final-destination/${placeId}`);
    if (resultRef.current && resultRef.current.status) {
      const destination = resultRef.current.destination;
      setValue("placeName", destination.place_name);
      setValue("placeOverview", destination.place_info);
      setValue("placeUniqueness", destination.place_uniqueness);
      // setValue("whatPlaceOffers", destination.what_place_offers);
      setValue(
        "countryRadBtn",
        destination.popular_in_country === true ? "yes" : "no"
      );
      setValue(
        "stateRadBtn",
        destination.popular_in_state === true ? "yes" : "no"
      );
      setValue(
        "mainDestinationRadBtn",
        destination.popular_in_main_destination === true ? "yes" : "no"
      );
      setValue("categoryType", destination.category_type);
      setValue("placeType", "finaldestination");
      setValue("streetAddress", destination.address.street_address);
      setValue("latLong", destination.address.lat_long);
      setValue("country", destination.address.country);
      setValue("state", destination.address.state);
      setMainDestinationId(destination.main_destination_id);
      setTravelTipList(destination.travel_tips);
      setNotToMissList(destination.not_to_miss);
      setMakePublish(destination.is_published);
    }
  };

  const onSubmit = async (data) => {
    const pCountry = data.countryRadBtn === "yes" ? true : false;
    const pState = data.stateRadBtn === "yes" ? true : false;
    const pMainDestination =
      data.mainDestinationRadBtn === "yes" ? true : false;

    const publishCheckboxElement = document.getElementById("publish-checkbox");
    const isPublished = publishCheckboxElement.checked;

    const address = {
      street_address: data.streetAddress,
      lat_long: data.latLong,
      country: data.country,
      state: data.state,
    };
    const dest = {
      place_id: placeId,
      place_name: data.placeName,
      place_uniqueness: data.placeUniqueness,
      place_info: data.placeOverview,
      // what_place_offers: data.whatPlaceOffers,
      not_to_miss: notToMissList,
      travel_tips: travelTipList,
      popular_in_country: pCountry,
      popular_in_state: pState,
      popular_in_main_destination: pMainDestination,
      category_type: data.categoryType,
      place_type: "finaldestination",
      destination_type: "finaldestination",
      address: address,
      is_published: isPublished,
    };
    await makeApiCall("PUT", `/admin/final-destination/edit`, dest);

    setTimeout(() => {
      if (resultRef.current && resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        history.replace(
          `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
        );
        //  setplaceId(resultRef.current.destination.place_name);
        // setRedirect(true);
      } else {
        alert(`FAIL: ${resultRef.current && resultRef.current.message}`);
      }
    }, 1000);
  };

  const handleDeleteFinalDestination = async () => {
    await makeApiCall("DELETE", `/admin/final-destination/${placeId}`);

    setTimeout(() => {
      if (resultRef.current && resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        history.replace(
          `/admin/destination-listing/${mainDestinationId}?refreshRequired=true`
        );
        //  setplaceId(resultRef.current.destination.place_name);
        // setRedirect(true);
      } else {
        alert(`FAIL: ${resultRef.current && resultRef.current.message}`);
      }
    }, 1000);
  };

  const updateTravelTip = (event) => {
    event.preventDefault();
    if (!travelTip) {
      alert("Enter info");
      return;
    }

    let updatedTravelTipList;
    if (travelTipId) {
      // not `null` means editing
      updatedTravelTipList = travelTipList.map((trvTip) => {
        if (trvTip._id === travelTipId) {
          return {
            ...trvTip,
            item: travelTip,
          };
        }
        return trvTip;
      });
    } else {
      const newItem = {
        item: travelTip,
      };
      updatedTravelTipList = [...travelTipList, newItem];
    }

    setTravelTipList(updatedTravelTipList);
    setTravelTipId(null);
    setTravelTip("");
  };
  const travelTipItemClick = (item) => {
    setTravelTipId(item._id);
    setTravelTip(item.item);
  };

  const travelTipItemDelete = (item) => {
    if (travelTip) {
      //if value exist means editing or adding operation is going on
      alert("please complete your edit or add operation first.");
      return;
    }

    let newList = travelTipList.filter((it) => it._id !== item._id);
    setTravelTipList(newList);
  };

  const updateNotToMiss = (event) => {
    event.preventDefault();
    if (!notToMiss) {
      alert("Enter info");
      return;
    }

    let updatedNotToMissList;
    if (notToMissId) {
      // not `null` means editing
      updatedNotToMissList = notToMissList.map((ntm) => {
        if (ntm._id === notToMissId) {
          return {
            ...ntm,
            item: notToMiss,
          };
        }
        return ntm;
      });
    } else {
      const newItem = {
        item: notToMiss,
      };
      updatedNotToMissList = [...notToMissList, newItem];
    }

    setNotToMissList(updatedNotToMissList);
    setNotToMissId(null);
    setNotToMiss("");
  };

  const notToMissItemClick = (item) => {
    setNotToMissId(item._id);
    setNotToMiss(item.item);
  };

  const notToMissItemDelete = (item) => {
    if (notToMiss) {
      //if value exist means editing or adding operation is going on
      alert("please complete your edit or add operation first.");
      return;
    }

    let newList = notToMissList.filter((it) => it._id !== item._id);
    setNotToMissList(newList);
  };

  const checkboxClickListener = () => {
    setMakePublish(!makePublish);
  };

  const editSubDestinationForm = () => {
    return (
      <div>
        <form class="uk-form-stacked" onSubmit={handleSubmit(onSubmit)}>
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              id="publish-checkbox"
              checked={makePublish}
              onClick={checkboxClickListener}
            />
            {`  make publish`}
          </label>

          <p className="uk-text-success ">Key Info(fields)</p>
          {/* <--  Name --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Name</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Place name..."
              // onChange={(e) => setName(e.target.value)}
              // value={name}
              {...register("placeName", {
                required: {
                  value: true,
                  message: "field is required",
                },
                minLength: {
                  value: 4,
                  message: "Place name must be at least 4 characters",
                },
              })}
            />
            <span className="uk-text-danger">{errors.placeName?.message}</span>
          </div>

          {/* <-- Uniqueness --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Uniqueness</label>
            <input
              class="uk-input"
              type="text"
              placeholder="Place uniqueness with 3-4 words..."
              // onChange={(e) => setPlaceUniqueness(e.target.value)}
              // value={placeUniqueness}
              {...register("placeUniqueness", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.placeUniqueness?.message}
            </span>
          </div>

          {/* <-- Overview (Place Info) --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Overview ( place info in short )
            </label>
            <textarea
              class="uk-textarea"
              rows="4"
              placeholder="Place overview..."
              // onChange={(e) => setOverview(e.target.value)}
              // value={overview}
              {...register("placeOverview", mandatoryField)}
            ></textarea>
            <span className="uk-text-danger">
              {errors.placeOverview?.message}
            </span>
          </div>

          {/* <-- What place offers --> */}
          {/* <div class="uk-margin">
            <label class="uk-form-label">What place Offers</label>
            <textarea
              class="uk-textarea"
              type="text"
              rows="4"
              placeholder="what are the things place offers..."
              // onChange={(e) => setPlaceOffers(e.target.value)}
              // value={placeOffers}
              {...register("whatPlaceOffers")}
            />
            <span className="uk-text-danger">
              {errors.whatPlaceOffers?.message}
            </span>
          </div> */}

          {/* <-- Not to miss --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Not to miss ( bullet list using regrex or other way)
            </label>
            <input
              class="uk-input"
              type="text"
              placeholder="things not to miss in the place..."
              value={notToMiss}
              onChange={(e) => setNotToMiss(e.target.value)}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={updateNotToMiss}
            >
              {notToMissId ? "update" : "add"}
            </button>
            <ul>
              {notToMissList.map((ntm) => {
                return (
                  <li>
                    {ntm.item}
                    {ntm._id && (
                      <>
                        <FiEdit2
                          className="edit-icon uk-margin-small-left uk-margin-small-right"
                          data-uk-tooltip="title: edit; pos: bottom-left"
                          onClick={() => notToMissItemClick(ntm)}
                        />
                        <FiTrash
                          className="delete-icon"
                          data-uk-tooltip="title: delete; pos: bottom-left"
                          onClick={() => notToMissItemDelete(ntm)}
                        />
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          {/* <-- Travel tips --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Travel tips (bullet list, optional)
            </label>
            <input
              class="uk-input"
              type="text"
              placeholder="suggestion or info aware to be, you want to include..."
              onChange={(e) => setTravelTip(e.target.value)}
              value={travelTip}
            />
            <button
              className="uk-button uk-button-small uk-button-primary uk-margin-small-top"
              onClick={updateTravelTip}
            >
              {travelTipId ? "update" : "add"}
            </button>
            <ul>
              {travelTipList.map((trvTip) => {
                return (
                  <li>
                    {trvTip.item}
                    {trvTip._id && (
                      <>
                        <FiEdit2
                          className="edit-icon uk-margin-small-left uk-margin-small-right"
                          data-uk-tooltip="title: edit; pos: bottom-left"
                          onClick={() => travelTipItemClick(trvTip)}
                        />
                        <FiTrash
                          className="delete-icon"
                          data-uk-tooltip="title: delete; pos: bottom-left"
                          onClick={() => travelTipItemDelete(trvTip)}
                        />
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Secondary Info(fields)
          </p>
          {/* <-- popular in country --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in country</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("countryRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("countryRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.countryRadBtn?.message}
            </span>
          </div>

          {/* <-- popular in state --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in state</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("stateRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("stateRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.stateRadBtn?.message}
            </span>
          </div>

          {/* <-- pupular in main destination --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">Popular in main destination</label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="yes"
                {...register("mainDestinationRadBtn", mandatoryField)}
              />
              Yes
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                value="no"
                {...register("mainDestinationRadBtn", mandatoryField)}
              />
              No
            </label>
            <span className="uk-text-danger">
              {errors.mainDestinationRadBtn?.message}
            </span>
          </div>

          {/* <-- Category type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">Category Type</label>
            <select
              class="uk-select"
              id="category-select-sd"
              {...register("categoryType", { required: true })}
            >
              {categories.map((category) => {
                return <option>{category}</option>;
              })}
            </select>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Backend Info(fields)
          </p>

          {/* <-- Place type --> */}
          <div class="uk-margin">
            <label class="uk-form-label">
              Place Type (FIXED - finaldestination)
            </label>
            <select
              class="uk-select"
              id="place-type-select-sd"
              disabled
              {...register("placeType", { required: true })}
            >
              {placeTypes.map((placeType) => {
                return <option>{placeType}</option>;
              })}
            </select>
          </div>

          {/* <-- destination type --> */}
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label className="uk-form-label">
              Destination type (FIXED - finaldestination)
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                name="dtRadBtn"
                value="md"
                disabled
              />
              main destination
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                name="dtRadBtn"
                value="fd"
                checked
              />
              final destination
            </label>
            <label>
              <input
                class="uk-radio"
                type="radio"
                name="dtRadBtn"
                value="none"
                disabled
              />
              none
            </label>
          </div>

          <p className="uk-text-success uk-margin-medium-top">
            Address Info(fields)
          </p>

          <div class="uk-margin">
            <input
              class="uk-input"
              type="text"
              placeholder="place-address (street address) (location-address)"
              // onChange={(e) => setAddress(e.target.value)}
              // value={address}
              {...register("streetAddress", mandatoryField)}
            />
            <span className="uk-text-danger">
              {errors.streetAddress?.message}
            </span>
          </div>

          <div className="uk-margin uk-flex uk-flex-between">
            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="lat-long (optional)"
                // onChange={(e) => setLatLong(e.target.value)}
                // value={latLong}
                {...register("latLong")}
              />
            </div>
            <div>
              <input
                class="uk-input uk-margin-small-right"
                type="text"
                placeholder="country"
                // onChange={(e) => setCountry(e.target.value)}
                // value={country}
                {...register("country", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.country?.message}</span>
            </div>

            <div>
              <input
                class="uk-input"
                type="text"
                placeholder="state"
                // onChange={(e) => setState(e.target.value)}
                // value={state}
                {...register("state", mandatoryField)}
              />
              <span className="uk-text-danger">{errors.state?.message}</span>
            </div>
          </div>

          <button
            className="uk-button uk-button-secondary uk-margin-medium-bottom"
            // onClick={handleSubmit}
            type="submit"
          >
            update
          </button>
        </form>

        {/* <--  Delete --> */}
        <button
          className="uk-button uk-button-danger uk-margin-small-bottom"
          onClick={handleDeleteFinalDestination}
        >
          delete
        </button>
      </div>
    );
  };

  const errorMessage = () => {
    if (error.show) {
      return <h4 className="uk-text-danger">{error.msg}</h4>;
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  // Main UI
  return (
    <Wrapper>
      {editSubDestinationForm()}
      {showLoading()}
      {errorMessage()}
      {/* {redirect && (
        <Redirect to={`/admin/destination-listing/${mainDestinationId}`} />
      )} */}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default EditSubDestination;
