import React from "react";
import styled from "styled-components";

function ColorCode() {
  return (
    <div className="color-items">
      <div className="color-item" style={{ backgroundColor: `#0f294d` }}></div>
      <div className="color-item" style={{ backgroundColor: `#8592a6` }}></div>
      <div className="color-item" style={{ backgroundColor: `#3264ff` }}></div>
      <div
        className="color-item"
        style={{ backgroundColor: `rgba(50, 100, 255, 0.0784313725490196)` }}
      ></div>
      <div className="color-item" style={{ backgroundColor: `#dadfe6` }}></div>
      <div className="color-item" style={{ backgroundColor: `#ddd` }}></div>
    </div>
  );
}

function Trip() {
  return (
    <Wrapper>
      <ColorCode />
      {/* MATERIAL-COMPONENTS-FOR-WEB (MATERIAL DESIGN)
       - https://material-components.github.io/material-components-web-catalog/#/component/card
        */}
      <div>
        Headline 1 Headline 2 Headline 3 Headline 4 Headline 5 Headline 6
        Subtitle 1 Subtitle 2 Body 1 Body 2 Caption Button Overline
      </div>
      <div>
        prose-sm 0.875rem (14px) prose-base (default) 1rem (16px) prose-lg
        1.125rem (18px) prose-xl 1.25rem (20px) prose-2xl 1.5rem (24px)
      </div>
      <div style={{ color: "red" }}>
        If text is for heading or highlighted & big & bold use h-tag or div/span
        tag
      </div>
      {/* -- HEADING -- */}
      <div className="h-36">Popular Attractions - 36px (h1)</div>
      <div className="h-32">Popular Attractions - 32px (h1)</div>
      <div className="h-28">Popular Attractions - 28px(h2)</div>
      <div className="h-24">Popular Attractions - 24px (h3)</div>
      <div className="h-16">Popular Attractions - 16px</div>

      <div style={{ color: "red" }}>
        If text is normal use p-tag or div/span tag
      </div>
      {/* --NORMAL TEXT -- */}
      <p className="p-24">Learn more - 18px </p>
      <p className="p-16">Learn more - 16px </p>
      <p className="p-14">
        No matter where you are, contact us when it works for you - 14px
      </p>
      <p className="p-14-b">Learn more - 14px </p>

      {/* --TAB-- */}
      <div className="tab">Mumbai</div>
      <div className="tab">Kolkata</div>
      {/* --IMAGE SECTION-- */}
      <div>
        <div
          class="image"
          style={{
            backgroundImage: `url("https://ak-d.tripcdn.com/images/100d0700000025hhh121B_C_550_412.jpg")`,
          }}
        ></div>
        <div
          class="image"
          style={{
            backgroundImage: `url("https://ak-d.tripcdn.com/images/10020t000000igk5u4FDB_C_550_412.jpg")`,
          }}
        ></div>
        <div
          class="icon"
          style={{
            backgroundImage: `url("https://pages.trip.com/images/components/Usp-icon/Usp-Google.png")`,
          }}
        ></div>

        <div class="image-container">
          <img src="https://ak-d.tripcdn.com/images/100j0y000000lzasgF994_C_400_280.jpg?proc=source%2ftrip" />
        </div>
      </div>

      {/* 
- https://ak-d.tripcdn.com/images/10020t000000igk5u4FDB_C_550_412.jpg
 */}
      <button className="button">Send App Link</button>

      <div className="border" style={{ backgroundColor: `#dadfe6` }}></div>
      <div className="border" style={{ backgroundColor: `#ddd` }}></div>
    </Wrapper>
  );
}

export default Trip;

const Wrapper = styled.section`
  .color-items {
    display: flex;
    margin: 8px 0;
  }
  .color-item {
    width: 4rem;
    height: 4rem;
    margin-right: 16px;
  }
  /* -- HEADING -- */
  .h-36 {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 20px;
    color: #0f294d;
  }
  .h-32 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 20px;
    color: #0f294d;
  }
  .h-28 {
    font-size: 28px;
    color: #0f294d;
    letter-spacing: 0;
    line-height: 34px;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .h-24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
    color: #0f294d;
  }
  .h-16 {
    color: #0f294d;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  /* -- NORMAL TEXT -- */
  .p-18 {
    line-height: 22px;
    /* box-sizing: border-box; */
    padding-top: 4px;
    font-size: 18px;
    color: #0f294d;
  }
  .p-16 {
    line-height: 18px;
    /* box-sizing: border-box; */
    padding-top: 4px;
    font-size: 16px;
    color: #0f294d;
  }

  .p-14 {
    line-height: 18px;
    padding-top: 4px;
    font-size: 14px;
    color: #8592a6;
  }
  .p-14-b {
    line-height: 18px;
    padding-top: 4px;
    font-size: 14px;
    color: #3264ff;
    margin-bottom: 20px;
  }

  /* -- TAB SECTION-- */
  .tab {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 16px;
    border: 1px solid #dadfe6;
    border-radius: 2px;
    color: #0f294d;
    margin-right: 16px;
    margin-bottom: 20px;
  }
  .tab:hover {
    font-weight: 700;
    border-color: rgba(50, 100, 255, 0.0784313725490196);
    color: #3264ff;
    background-color: rgba(50, 100, 255, 0.0784313725490196);
  }

  /* -- IMAGE SECTION -- */
  .image {
    /* width: 100%; */
    /* height: 0; */
    /* padding-top: 197px; */
    width: 8rem;
    height: 8rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-color: #e9cdcd;
    margin-right: 16px;
    display: inline-block;
  }
  .button {
    font-size: 16px;
    background-color: #3264ff;
    color: #fff;
    padding: 0.5rem 1rem;
    border: transparent;
    margin: 20px 0;
  }

  .icon {
    width: 40px;
    height: 40px;
    background-size: 40px;
    margin-right: 16px;
    display: inline-block;
  }
  .image-container {
    width: 229px;
    height: 162px;
    margin-right: 18px;
    background: grey;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2px;
    display: inline-block;
  }
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    inset: 0px;
    transition: all 1.2s ease 0s;
  }
  .border {
    width: 100%;
    height: 1px;
    margin-bottom: 16px;
  }
`;
