import React, { useState, useEffect, useRef } from "react";
import mockData from "./../mockDb";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { FaThumbsUp } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import myPortfolioImage from "./../assets/my_portfolio_image.jpg";
import myPortfolioImage1 from "./../assets/my_portfolio_image_1.jpg";
import { contactEmail } from "../utils/utility";

function About() {
  const intersectTarget = useRef(null);

  //Use the observer inside the component you want to track scroll
  useEffect(() => {
    // const opts = {
    //   root: null,
    //   rootMargin: "0px",
    //   threshold: 0,
    // };

    // const callback = (list) => {
    //   list.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       console.log("SCROLL-FAQ:- ", entry);
    //       ReactGA.event({
    //         category: "Scroll",
    //         action: "Scrolled to FAQ header About page",
    //         value: entry.intersectionRatio,
    //       });
    //     }
    //   });
    // };

    // const observerScroll = new IntersectionObserver(callback, opts);
    // observerScroll.observe(intersectTarget.current);

    const onPageLoad = () => {
      // alert("loaded complete");
      // observerScroll.observe(intersectTarget.current);
    };

    // Check if the page has already loaded
    // if (document.readyState === "complete") {
    //   onPageLoad();
    // } else {
    //   window.addEventListener("load", onPageLoad, false);
    //   // Remove the event listener when component unmounts
    //   return () => window.removeEventListener("load", onPageLoad);
    // }
  }, []);

  const filterAnimation = () => {
    return (
      <div data-uk-filter="target: .js-filter">
        <ul class="uk-subnav uk-subnav-pill">
          <li data-uk-filter-control=".tag-white">
            <a href="#">White</a>
          </li>
          <li data-uk-filter-control=".tag-blue">
            <a href="#">Blue</a>
          </li>
          <li data-uk-filter-control=".tag-black">
            <a href="#">Black</a>
          </li>
        </ul>

        <ul
          class="js-filter uk-child-width-1-2 uk-child-width-1-3@m uk-text-center"
          data-uk-grid
        >
          <li class="tag-white">
            <div class="uk-card uk-card-default uk-card-body">Item</div>
          </li>
          <li class="tag-blue">
            <div class="uk-card uk-card-primary uk-card-body">Item</div>
          </li>
          <li class="tag-white">
            <div class="uk-card uk-card-default uk-card-body">Item</div>
          </li>
          <li class="tag-white">
            <div class="uk-card uk-card-default uk-card-body">Item</div>
          </li>
          <li class="tag-black">
            <div class="uk-card uk-card-secondary uk-card-body">Item</div>
          </li>
          <li class="tag-black">
            <div class="uk-card uk-card-secondary uk-card-body">Item</div>
          </li>
          <li class="tag-blue">
            <div class="uk-card uk-card-primary uk-card-body">Item</div>
          </li>
          <li class="tag-black">
            <div class="uk-card uk-card-secondary uk-card-body">Item</div>
          </li>
          <li class="tag-blue">
            <div class="uk-card uk-card-primary uk-card-body">Item</div>
          </li>
          <li class="tag-white">
            <div class="uk-card uk-card-default uk-card-body">Item</div>
          </li>
          <li class="tag-blue">
            <div class="uk-card uk-card-primary uk-card-body">Item</div>
          </li>
          <li class="tag-black">
            <div class="uk-card uk-card-secondary uk-card-body">Item</div>
          </li>
        </ul>
      </div>
    );
  };

  const slideShow = () => {
    return (
      <div
        class="uk-container uk-container-small custom-style"
        data-uk-slideshow="animation: scale"
      >
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
        >
          <ul class="uk-slideshow-items">
            <li>
              <img
                src="https://res.cloudinary.com/dbeeghycn/image/upload/v1671171501/destinations/xrrfcokqpnlr37avhyoq.jpg"
                alt=""
                data-uk-cover
              />
            </li>
            <li>
              <img
                src="https://res.cloudinary.com/dbeeghycn/image/upload/v1671171504/destinations/mycjnlhdc36frwfjbbuv.jpg"
                alt=""
                data-uk-cover
              />
            </li>
            <li>
              <img
                src="https://res.cloudinary.com/dbeeghycn/image/upload/v1671171506/destinations/qopvniy9yqtmpxiz08nj.jpg"
                alt=""
                data-uk-cover
              />
            </li>
          </ul>

          <a
            class="uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            data-uk-slidenav-previous
            data-uk-slideshow-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            data-uk-slidenav-next
            data-uk-slideshow-item="next"
          ></a>
        </div>
        <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
      </div>
    );
  };

  const openMail = () => {
    ReactGA.event({
      category: "Email",
      action: "clicked email part",
    });

    const email = contactEmail;
    const emailTitle = "Get in touch";
    const emailBody = "Your query...";

    window.open(`mailto:${email}?subject=${emailTitle}&body=${emailBody}`);
    // window.location(`mailto:${email}`);
  };

  function SecondaryInformation() {
    const name = null;
    return (
      <>
        <h4>Name {name.fullLetter()}</h4>
      </>
    );
  }

  return (
    <Wrapper>
      {/* {filterAnimation()} */}
      {/* {slideShow()} */}

      <Helmet>
        <title>About - travelBucket </title>
      </Helmet>

      <div className="about-platform-container">
        <div
          className="uk-container uk-container-small item-padding"
          data-uk-scrollspy="cls:uk-animation-fade; delay:200"
        >
          <p className="text-1 text-white">
            <span>TravelBucket</span> is a platform that helps you to make your
            travel bucket list with ease.
          </p>
          <h2 className="uk-margin-remove-top text-white text-2">How?</h2>
          <h4 className="text-white uk-margin-remove-top text-3">
            Just by two simple steps:-
          </h4>
          <p className="text-white uk-margin-remove-bottom">
            1. Explore the destination by simply watching or reading the short &
            concise article.
          </p>
          <p className="text-white uk-margin-small-top">
            2. Add destinations in your bucket list by clicking the heart icon.
          </p>
        </div>
      </div>

      {/* <SecondaryInformation /> */}
      <div className="about-me-container">
        <div
          className="uk-container uk-container-small uk-flex uk-flex-row item-padding change-direction"
          data-uk-scrollspy="cls:uk-animation-fade; delay:200"
        >
          <img src={myPortfolioImage} alt={"my-image"} className="img-style" />
          <div className="name-container">
            <h3>Hi there, my name's Gopal!</h3>
            <p className="uk-text-default">
              I am a programmer, self development nerd & travel enthusiast.
              Welcome to the platform, where I aim to simplify the way you
              create your travel bucket list. You explore the destination by
              reading short & concise articles or watching featured videos of
              the destination. Let's embark on this journey together, and I'd
              love to hear your ideas, suggestions to make this platform even
              better.
            </p>
          </div>
        </div>
      </div>

      {/* <h4 id="faq-item" style={{ color: "red" }} ref={intersectTarget}>
        FAQs
      </h4> */}

      {/* Inspired-
- https://satorigraphics.net/
- https://www.adriengervaix.com/
 */}
      <section className="get-in-touch-container">
        <div
          className="uk-container uk-container-small item-padding"
          data-uk-scrollspy="cls:uk-animation-fade; delay:200"
        >
          <p className="text-4 text-white uk-margin-small-bottom">
            Have query? Have suggestion? Connect with me?
          </p>
          <h2
            id="get-in-touch-header"
            className="text-5 uk-margin-remove-top text-white uk-margin-small-bottom"
            ref={intersectTarget}
          >
            Lets Get In Touch !
          </h2>

          <p className="text-6 small-text uk-margin-small-bottom">
            If you're a programmer, connect me on
            <a
              href="https://www.linkedin.com/in/gopaldan"
              className="uk-margin-small-left"
              target="_blank"
            >
              LinkedIn
            </a>
          </p>

          <div
            className="email-container"
            onClick={openMail}
            data-uk-tooltip="title: click to email us; pos: bottom-right"
          >
            <AiOutlineMail className="email-icon" />
            <p className="email-value">{contactEmail}</p>
          </div>
        </div>
      </section>
      {/* TODO: try to implement to consider */}
      {/* <section className="items">
        <h4 style={{ color: "red" }}>
          1. Go through the about section or portfolio of UI designer to get
          inspiration. Make about section interesting not boring.
        </h4>
        <h4 style={{ color: "red" }}>
          2. better to use bucket icon with travel inspiration rather than
          favourite or heart or bookmark icon.?
        </h4>
        <h4 style={{ color: "red" }}>
          3. Keep destination or place word synchronous rather than using it
          alternatively everywhere. confuses!
        </h4>
      </section> */}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  .text-white {
    color: #fff;
  }

  .text-1 {
    font-size: 1.5rem;
  }
  .text-1 span {
    font-size: 1.7rem;
    /* color: #fff; */
    font-weight: 600;
  }

  .text-2,
  .text-3 {
    font-size: 1.25rem;
  }
  .about-platform-container {
    /* background-color: #223c50; */
    background: linear-gradient(to right, #3a6073, #16222a);
  }

  .item-padding {
    padding: 80px 0;
  }

  .about-me-container {
    background: #ebe9eb;
  }

  .img-style {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: var(--imageBackgroundColor);
  }

  .name-container {
    margin-left: 40px;
  }

  .name-container p {
    text-align: justify;
  }

  .get-in-touch-container {
    /* background-color: #223c50; */
    background: linear-gradient(to right, #3a6073, #16222a);
    color: #fff;
  }

  .text-4 {
    font-size: 1.5rem;
  }
  .text-5 {
    font-size: 1.75rem;
  }
  --clr-grey-5: #617d98;
  .small-text {
    color: var(--clr-grey-5);
    font-size: 0.875rem;
  }

  .email-container-1 {
    border: 1px solid var(--clr-grey-5);
  }

  .email-container {
    height: 3rem;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    /* border: 1px solid var(--clr-grey-5); */
    border: 1px solid var(--color--primary);
    transition: border 0.3s linear;
  }

  .email-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 0.5rem;
    /* color: var(--clr-grey-5); */
    color: var(--color--primary);
    transition: color 0.3s linear;
  }

  .email-value {
    display: inline;
    margin-right: 1rem;
    /* color: var(--clr-grey-5); */
    color: var(--color--primary);
    transition: color 0.3s linear;
  }

  .email-container:hover {
    /* border-color: #27e0b4; */
    border-color: var(--clr-grey-5);
  }
  .email-container:hover .email-value {
    /* color: #27e0b4; */
    color: var(--clr-grey-5);
  }
  .email-container:hover .email-icon {
    /* color: #27e0b4; */
    color: var(--clr-grey-5);
  }

  .custom-style {
    width: 100%;
    height: 14rem;
    /* margin: 4rem 0 0 0; */
  }

  @media screen and (min-width: 641px) and (max-width: 960px) {
    .item-padding {
      padding: 80px 30px;
    }
    .text-1 {
      font-size: 1.25rem;
    }
    .text-1 span {
      font-size: 1.3rem;
    }
    .text-2,
    .text-3 {
      font-size: 1.12rem;
    }
    .name-container {
      margin-left: 30px;
    }
    .text-4,
    .text-5 {
      font-size: 1.25rem;
    }
  }

  @media screen and (max-width: 576px) {
    .item-padding {
      padding: 80px 15px;
    }

    .text-1 {
      font-size: 1.25rem;
    }
    .text-1 span {
      font-size: 1.3rem;
    }
    .text-2,
    .text-3 {
      font-size: 1.12rem;
    }
    .change-direction {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .name-container {
      margin-left: 0;
    }
    .name-container h3 {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0;
    }
    .name-container p {
      /* text-align: justify; */
      margin-top: 0.5rem;
    }
    .text-4 {
      font-size: 1.1rem;
    }
    .text-5 {
      font-size: 1.25rem;
    }
  }
`;
export default About;
