//beach, mountain, building, forest
const destinations = [
  {
    id: 1,
    images: [
      "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
      "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
      "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
    ],
    name: "Digha",
    overview:
      "Nestled on the coast of the Bay of Bengal, Digha is about 4.5 hours from Kolkata. It is known for its laidback beaches, picturesque vistas and diverse marine life.",
    address: "purba medinipur, West Bengal 700013, Kolkata, India",
    how_to_reach: {
      via_plane: "Dumdum airport (44 KM )",
      via_train: "digha railway station ( 2 KM 1)",
      via_bus: " Esplanade from kolkata ( 15 KM )",
    },
    only_watch: {
      video_url: "beach",
      hindi_video_url: "beach",
    },

    best_time_to_visit: {
      ideal_season: "OCT-FEB",
      off_season: "JUL-SEP",
    },
    destination_places: [
      {
        id: 1,
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Talsari Beach",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
        reviews: [
          {
            reviewer_id: "617",
            reviewer_name: "Pretty",
            reviewer_experience:
              " This is the rewview of this place according to me it is a go to place.",
          },
          {
            reviewer_id: "618",
            reviewer_name: "Joya",
            reviewer_experience:
              " This is the ralking across the sand dunes along the flat beacto place.",
          },
          {
            reviewer_id: "619",
            reviewer_name: "Randeep",
            reviewer_experience:
              " u can relish ocean-fresh seafood her This is the rewview of this place according to me it is a go to place.",
          },
        ],
      },
      {
        id: 2,
        images: [
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Digha Beach",
        overview:
          "Discovered by the British, Digha Beach was referred to as the ‘Brighton of the East’ in the journals of Warren Hastings.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "Idyllic Beach Destination",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        id: 3,
        images: [
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Amrabati Park",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        id: 4,
        images: [
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Gulmarg Biosphere Reserve",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        id: 5,
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Alpather Lake",
        overview:
          "Discovered by the British, Digha Beach was referred to as the ‘Brighton of the East’ in the journals of Warren Hastings.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "Idyllic Beach Destination",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        id: 6,
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Gulmarg Golf Course",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        id: 7,
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Rajaji National Park",
        overview:
          "Discovered by the British, Digha Beach was referred to as the ‘Brighton of the East’ in the journals of Warren Hastings.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "Idyllic Beach Destination",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        id: 8,
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Triveni Ghat",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
    ],
    destination_reviews: [
      {
        reviewer_id: 617,
        reviewer_name: "Raman raghav",
        reviewer_experience:
          " This is the rewview of this place according to ralking across the sand dunes along the flat  me it is a go to place. ralking across the sand dunes along the flat",
      },
      {
        reviewer_id: 618,
        reviewer_name: "Intair Roy",
        reviewer_experience:
          " This is the ralking across the sand dunes along the flat beacto place.",
      },
      {
        reviewer_id: 619,
        reviewer_name: "Shreya toy",
        reviewer_experience:
          " u can relish ocean-fresh seafood her  ralking across the sand dunes along the flat This is the rewview of this place according to me it is a go to place.",
      },
    ],
  },

  {
    id: 2,
    images: [
      "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
      "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
      "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
    ],
    name: "Gulmarg",
    overview:
      "Gulmarg is the crowning glory of Jammu & Kashmir and is located 2,650 meters above sea level. Known as the “Meadow of Flowers”, it boasts of the world’s highest golf-course.",
    how_to_reach: {
      via_plane: "Dumdum airport (44 KM )",
      via_train: "digha railway station ( 2 KM 1)",
      via_bus: " Esplanade from kolkata ( 15 KM )",
    },
    only_watch: {
      video_url: "beach",
      hindi_video_url: "beach",
    },

    best_time_to_visit: {
      ideal_season: "OCT-FEB",
      off_season: "JUL-SEP",
    },
    destination_places: [
      {
        images: [
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Gulmarg Biosphere Reserve",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Alpather Lake",
        overview:
          "Discovered by the British, Digha Beach was referred to as the ‘Brighton of the East’ in the journals of Warren Hastings.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "Idyllic Beach Destination",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Gulmarg Golf Course",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
    ],
    destination_reviews: [
      {
        reviewer_id: "617",
        reviewer_name: "Ra",
        reviewer_experience:
          " This is the rewview of this place according to me it is a go to place.",
      },
      {
        reviewer_id: "618",
        reviewer_name: "Roy",
        reviewer_experience:
          " This is the ralking across the sand dunes along the flat beacto place.",
      },
      {
        reviewer_id: "619",
        reviewer_name: "Shreya",
        reviewer_experience:
          " u can relish ocean-fresh seafood her This is the rewview of this place according to me it is a go to place.",
      },
    ],
  },

  {
    id: 3,
    images: [
      "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
      "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
      "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
    ],
    name: "Puri",
    overview:
      "About 2 hours from Bhubaneshwar, Puri is a coastal temple town along the Bay of Bengal in Odisha. It lies close to tourist hotspots like Konark Temple and Dhabaleswar Temple.",
    how_to_reach: {
      via_plane: "Dumdum airport (44 KM )",
      via_train: "digha railway station ( 2 KM 1)",
      via_bus: " Esplanade from kolkata ( 15 KM )",
    },
    only_watch: {
      video_url: "beach",
      hindi_video_url: "beach",
    },

    best_time_to_visit: {
      ideal_season: "OCT-FEB",
      off_season: "JUL-SEP",
    },
    destination_places: [
      {
        images: [
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Shree Jagannath Temple",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Puri beach",
        overview:
          "Discovered by the British, Digha Beach was referred to as the ‘Brighton of the East’ in the journals of Warren Hastings.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "Idyllic Beach Destination",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Konark",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
    ],
    destination_reviews: [
      {
        reviewer_id: "618",
        reviewer_name: "Rahul",
        review:
          " This is the rewview of this place according to me it is a go to place.",
      },
    ],
  },

  {
    id: 4,
    images: [
      "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
      "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
    ],
    name: "Rishikesh",
    overview:
      "Known as the ‘Yoga Capital of the World’, Rishikesh is a serene Himalayan town nestled on the banks of River Ganges. It is famous for its ancient temples, hippie cafes and dare-devil adventure sports.",
    how_to_reach: {
      via_plane: "Dumdum airport (44 KM )",
      via_train: "digha railway station ( 2 KM 1)",
      via_bus: " Esplanade from kolkata ( 15 KM )",
    },
    only_watch: {
      video_url: "beach",
      hindi_video_url: "beach",
    },

    best_time_to_visit: {
      ideal_season: "OCT-FEB",
      off_season: "JUL-SEP",
    },
    destination_places: [
      {
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Laxman Jhula",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Rajaji National Park",
        overview:
          "Discovered by the British, Digha Beach was referred to as the ‘Brighton of the East’ in the journals of Warren Hastings.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "Idyllic Beach Destination",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
      {
        images: [
          "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
          "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
          "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1470115636492-6d2b56f9146d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        ],
        name: "Triveni Ghat",
        overview:
          "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
        address: "purba medinipur, West Bengal 700013, Kolkata, India",
        place_category: "beach",
        category_uniqueness: "placce is famous for witin 2-3 words",
        what_place_offers:
          "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
        travel_tips: "Avoid travelling after sunset for your safety.",
        not_to_miss: "You can relish ocean-fresh seafood here.",
      },
    ],
    destination_reviews: [
      {
        reviewer_id: "618",
        reviewer_name: "Rahul",
        review:
          " This is the rewview of this place according to me it is a go to place.",
      },
    ],
  },
];

export const videoList = [
  // https://www.youtube.com/embed/qD0U7ZiCGxU
  // 1. https://www.youtube.com/embed/kuniEyt3Qks [tohfa]
  // 2. https://www.youtube.com/embed/qD0U7ZiCGxU [mascara]
  // 3. https://www.youtube.com/embed/u0Y3EHuMktE [ghana kasoota]
  // 4. https://www.youtube.com/embed/mfij77Vh98w [ranjha]

  {
    id: 1,
    language: "english",
    video_url: "https://www.youtube.com/embed/qD0U7ZiCGxU",
    video_name: "Mascara : Digha - Travel Guide",
    video_thumbnail:
      "https://images.unsplash.com/photo-1520520731457-9283dd14aa66?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80",
  },
  {
    id: 2,
    language: "english",
    video_url: "https://www.youtube.com/embed/mfij77Vh98w",
    video_name: "Ranjha : Top things to do in Gulmarg ",
    video_thumbnail:
      "https://images.unsplash.com/photo-1505855265981-d52719d1f64e?=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=465&q=80",
  },
  {
    id: 3,
    language: "hindi",
    video_url: "https://www.youtube.com/embed/kuniEyt3Qks",
    video_name: "Tohfa : Have you ever visited this part of Puri, Odisha?",
    video_thumbnail:
      "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
  },

  {
    id: 4,
    language: "english",
    video_url: "https://www.youtube.com/embed/u0Y3EHuMktE",
    video_name:
      "Ghana Kasoota : Have you ever visited this part of Puri, Odisha?",
    video_thumbnail:
      "https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
  },
  {
    id: 5,
    language: "hindi",
    video_url: "https://www.youtube.com/embed/qD0U7ZiCGxU",
    video_name: "Mascara : Have you ever visited this part of Puri, Odisha?",
    video_thumbnail:
      "https://image.unsplash.com/photo-1471039497385-b6d6ba609f9c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
  },
];

export const mainDestinations = [
  // DIGHA
  {
    place_name: "Digha",
    place_info:
      "Nestled on the coast of the Bay of Bengal, Digha is about 4.5 hours from Kolkata. It is known for its laidback beaches, picturesque vistas and diverse marine life.",
    more_info: [
      {
        title: "Food & Drink to try in Digha",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Culture in Digha",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Shopping in Digha",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
    ],
    how_to_reach: [
      {
        title: "Overview",
        info: "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
      },
      {
        title: "Flight",
        info: "Digha does not have its own airport. The nearest airport is the Netaji Subash Chandra Bose International Airport of Kolkata. The airport is well connected to many prominent cities of India. There are bus services available to commute between the airport and Digha. Alternatively, you can also hire a taxi.",
      },
      {
        title: "Road",
        info: "If you are travelling to Digha from nearby towns, hiring a taxi or self-driving is most suitable. Also, there are two bus stands in Digha- SBSTC Bus Depot and Old Digha Bus Stop. Both of them are located at a walking distance. Regular buses connect Digha to neighbouring cities. So, you can choose to travel by bus also",
      },
      {
        title: "Train",
        info: "There is a railway station in Digha. It is located at a distance of around 450 metres from Digha. It takes only 2 minutes to reach the city. The railway station started its operations properly in 2004 and is well connected through regular trains to all the nearby cities like Kolkata.",
      },
    ],
    popular_in_country: true,
    popular_in_state: false,
    theme_type: "Beach",
    place_type: "maindestination",
    destination_type: "maindestination",
    best_time_to_go: [
      {
        title: "Overview",
        info: "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
      },
      {
        title: "Digha in Winter (October - February)",
        info: "weather condition in this period",
      },
      {
        title: "Digha in Summer (March-June) ",
        info: "weather condition in this period",
      },
      {
        title: "Digha in Monsoon (July-September) ",
        info: "weather condition in this period",
      },
    ],
    things_to_know: [
      {
        item: "Avoid travelling after sunset for your safety.",
      },
      { item: "You can relish ocean-fresh seafood here." },
      {
        item: "You can enjoy walking across the sand dunes along the flat beach",
      },
    ],
    address: {
      street_address: "East midanpur,kolkata, westbengal",
      lat_long: "23.45425, 86.6481",
      country: "India",
      state: "West bengal",
    },
  },

  // PURI
  {
    place_name: "Puri",
    place_info:
      "About 2 hours from Bhubaneshwar, Puri is a coastal temple town along the Bay of Bengal in Odisha. It lies close to tourist hotspots like Konark Temple and Dhabaleswar Temple.",

    more_info: [
      {
        title: "Food & Drink to try in Puri",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Culture in Puri",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Shopping in Puri",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
    ],
    how_to_reach: [
      {
        title: "Overview",
        info: "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
      },
      {
        title: "Flight",
        info: "Digha does not have its own airport. The nearest airport is the Netaji Subash Chandra Bose International Airport of Kolkata. The airport is well connected to many prominent cities of India. There are bus services available to commute between the airport and Digha. Alternatively, you can also hire a taxi.",
      },
      {
        title: "Road",
        info: "If you are travelling to Digha from nearby towns, hiring a taxi or self-driving is most suitable. Also, there are two bus stands in Digha- SBSTC Bus Depot and Old Digha Bus Stop. Both of them are located at a walking distance. Regular buses connect Digha to neighbouring cities. So, you can choose to travel by bus also",
      },
      {
        title: "Train",
        info: "There is a railway station in Digha. It is located at a distance of around 450 metres from Digha. It takes only 2 minutes to reach the city. The railway station started its operations properly in 2004 and is well connected through regular trains to all the nearby cities like Kolkata.",
      },
    ],
    popular_in_country: true,
    popular_in_state: false,
    theme_type: "Beach",
    place_type: "maindestination",
    destination_type: "maindestination",
    best_time_to_go: [
      {
        title: "Overview",
        info: "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
      },
      {
        title: "Puri in Winter (October - February)",
        info: "weather condition in this period",
      },
      {
        title: "Puri in Summer (March-June) ",
        info: "weather condition in this period",
      },
      {
        title: "Puri in Monsoon (July-September) ",
        info: "weather condition in this period",
      },
    ],
    things_to_know: [
      {
        item: "Avoid travelling after sunset for your safety.",
      },
      { item: "You can relish ocean-fresh seafood here." },
      {
        item: "You can enjoy walking across the sand dunes along the flat beach",
      },
    ],
    address: {
      street_address: "East bhubneswar, Odisa",
      lat_long: "23.45425, 86.6481",
      country: "India",
      state: "Odisa",
    },
  },

  // GOA
  {
    place_name: "Goa",
    place_info:
      "A kaleidoscopic blend of Indian and Portuguese cultures, sweetened with sun, sea, sand, seafood, susegad and spirituality, Goa is India's pocket-sized paradise.",
    more_info: [
      {
        title: "Food & Drink to try in Goa",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Culture in Goa",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Shopping in Goa",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
    ],
    how_to_reach: [
      {
        title: "Overview",
        info: "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
      },
      {
        title: "Flight",
        info: "Digha does not have its own airport. The nearest airport is the Netaji Subash Chandra Bose International Airport of Kolkata. The airport is well connected to many prominent cities of India. There are bus services available to commute between the airport and Digha. Alternatively, you can also hire a taxi.",
      },
      {
        title: "Road",
        info: "If you are travelling to Digha from nearby towns, hiring a taxi or self-driving is most suitable. Also, there are two bus stands in Digha- SBSTC Bus Depot and Old Digha Bus Stop. Both of them are located at a walking distance. Regular buses connect Digha to neighbouring cities. So, you can choose to travel by bus also",
      },
      {
        title: "Train",
        info: "There is a railway station in Digha. It is located at a distance of around 450 metres from Digha. It takes only 2 minutes to reach the city. The railway station started its operations properly in 2004 and is well connected through regular trains to all the nearby cities like Kolkata.",
      },
    ],
    popular_in_country: true,
    popular_in_state: false,
    theme_type: "Beach",
    place_type: "maindestination",
    destination_type: "maindestination",
    best_time_to_go: [
      {
        title: "Overview",
        info: "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
      },
      {
        title: "Goa in Winter (October - February)",
        info: "weather condition in this period",
      },
      {
        title: "Goa in Summer (March-June) ",
        info: "weather condition in this period",
      },
      {
        title: "Goa in Monsoon (July-September) ",
        info: "weather condition in this period",
      },
    ],
    things_to_know: [
      {
        item: "Avoid travelling after sunset for your safety.",
      },
      { item: "You can relish ocean-fresh seafood here." },
      {
        item: "You can enjoy walking across the sand dunes along the flat beach",
      },
    ],
    address: {
      street_address: "East goa, Goa",
      lat_long: "23.45425, 86.6481",
      country: "India",
      state: "Goa",
    },
  },

  // DUBAI
  {
    place_name: "Dubai",
    place_info:
      "Dubai is a stirring alchemy of profound traditions and ambitious futuristic vision wrapped into starkly evocative desert splendour.",
    more_info: [
      {
        title: "Food & Drink to try in Dubai",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Culture in Dubai",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Shopping in Dubai",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
    ],
    how_to_reach: [
      {
        title: "Overview",
        info: "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
      },
      {
        title: "Flight",
        info: "Digha does not have its own airport. The nearest airport is the Netaji Subash Chandra Bose International Airport of Kolkata. The airport is well connected to many prominent cities of India. There are bus services available to commute between the airport and Digha. Alternatively, you can also hire a taxi.",
      },
      {
        title: "Road",
        info: "If you are travelling to Digha from nearby towns, hiring a taxi or self-driving is most suitable. Also, there are two bus stands in Digha- SBSTC Bus Depot and Old Digha Bus Stop. Both of them are located at a walking distance. Regular buses connect Digha to neighbouring cities. So, you can choose to travel by bus also",
      },
      {
        title: "Train",
        info: "There is a railway station in Digha. It is located at a distance of around 450 metres from Digha. It takes only 2 minutes to reach the city. The railway station started its operations properly in 2004 and is well connected through regular trains to all the nearby cities like Kolkata.",
      },
    ],
    popular_in_country: true,
    popular_in_state: false,
    theme_type: "Beach",
    place_type: "maindestination",
    destination_type: "maindestination",
    best_time_to_go: [
      {
        title: "Overview",
        info: "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
      },
      {
        title: "Dubai in Winter (October - February)",
        info: "weather condition in this period",
      },
      {
        title: "Dubai in Summer (March-June) ",
        info: "weather condition in this period",
      },
      {
        title: "Dubai in Monsoon (July-September) ",
        info: "weather condition in this period",
      },
    ],
    things_to_know: [
      {
        item: "Avoid travelling after sunset for your safety.",
      },
      { item: "You can relish ocean-fresh seafood here." },
      {
        item: "You can enjoy walking across the sand dunes along the flat beach",
      },
    ],
    address: {
      street_address: "East dubai",
      lat_long: "23.45425, 86.6481",
      country: "UAE",
      state: "dubai",
    },
  },

  // LA
  {
    place_name: "Los Angeles",
    place_info:
      "Ruggedly good looking, deeply creative, with a sunny disposition to boot…if LA were on Tinder, the app would crash",
    more_info: [
      {
        title: "Food & Drink to try in Los Angeles",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Culture in Los Angeles",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Shopping in Los Angeles",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
    ],
    how_to_reach: [
      {
        title: "Overview",
        info: "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
      },
      {
        title: "Flight",
        info: "Digha does not have its own airport. The nearest airport is the Netaji Subash Chandra Bose International Airport of Kolkata. The airport is well connected to many prominent cities of India. There are bus services available to commute between the airport and Digha. Alternatively, you can also hire a taxi.",
      },
      {
        title: "Road",
        info: "If you are travelling to Digha from nearby towns, hiring a taxi or self-driving is most suitable. Also, there are two bus stands in Digha- SBSTC Bus Depot and Old Digha Bus Stop. Both of them are located at a walking distance. Regular buses connect Digha to neighbouring cities. So, you can choose to travel by bus also",
      },
      {
        title: "Train",
        info: "There is a railway station in Digha. It is located at a distance of around 450 metres from Digha. It takes only 2 minutes to reach the city. The railway station started its operations properly in 2004 and is well connected through regular trains to all the nearby cities like Kolkata.",
      },
    ],
    popular_in_country: true,
    popular_in_state: false,
    theme_type: "Beach",
    place_type: "maindestination",
    destination_type: "maindestination",
    best_time_to_go: [
      {
        title: "Overview",
        info: "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
      },
      {
        title: "Los Angeles in Winter (October - February)",
        info: "weather condition in this period",
      },
      {
        title: "Los Angeles in Summer (March-June) ",
        info: "weather condition in this period",
      },
      {
        title: "Los Angeles in Monsoon (July-September) ",
        info: "weather condition in this period",
      },
    ],
    things_to_know: [
      {
        item: "Avoid travelling after sunset for your safety.",
      },
      { item: "You can relish ocean-fresh seafood here." },
      {
        item: "You can enjoy walking across the sand dunes along the flat beach",
      },
    ],
    address: {
      street_address: "la florida usa",
      lat_long: "23.45425, 86.6481",
      country: "US",
      state: "Florida",
    },
  },
];

export const finalDestinations = [
  // DIGHA [7. Udaipur beach, 8. Digha Ropeway, 9. Digha Mohana Watch Point]
  [
    {
      place_name: "New Digha Beach",
      place_uniqueness: "Newly-Developed Beach",
      place_info:
        "About 10 minutes from the Old Digha Beach, the New Digha Beach was developed to have a larger area and provide easier accessibility.",
      what_place_offers:
        "You can go for long walks, soak in the glorious sunrise and sunset views, and take a dip in the foamy waters of the Bay of Bengal.",
      not_to_miss: [],
      travel_tips: [
        {
          item: "Follow all the safety instructions before taking a plunge into the sea.",
        },
        {
          item: "Avoid visiting the beach late in the evening for your safety. ",
        },
      ],
      popular_in_country: false,
      popular_in_state: true,
      popular_in_main_destination: true,
      category_type: "Temple",
      place_type: "finaldestination",
      destination_type: "finaldestination",
      address: {
        street_address: "goa address",
        lat_long: "23.45425, 86.6481",
        country: "India",
        state: "Goa",
      },
      main_destination_id: "",
    },
    {
      place_name: "Amrabati Park",
      place_uniqueness: "Family-Friendly Getaway",
      place_info:
        "Located close to the New Digha Beach, Amrabati Park is a delightfully pristine verdant pocket. It has immaculate lawns, well-paved walkways and a crystal clear lake.",
      what_place_offers:
        "You can sign up for a scenic boating experience, challenge yourself to a ropeway ride and enjoy the natural flora and fauna of the park.",
      not_to_miss: [],
      travel_tips: [
        {
          item: "The boat and ropeway rides are chargeable",
        },
        {
          item: "The park is open every day from 7:30 a.m. to 7:30 p.m.",
        },
      ],
      popular_in_country: false,
      popular_in_state: true,
      popular_in_main_destination: true,
      category_type: "Temple",
      place_type: "finaldestination",
      destination_type: "finaldestination",
      address: {
        street_address: "East midanpur,kolkata, westbengal",
        lat_long: "23.45425, 86.6481",
        country: "India",
        state: "West bengal",
      },
      main_destination_id: "",
    },
    {
      place_name: "Mandarmani",
      place_uniqueness: "Seaside Resort Village",
      place_info:
        "About 29 kms from Digha, Mandarmani is a pristine beach resort village in West Bengal. It is an ideal getaway destination to relax and beat your city blues.",
      what_place_offers:
        "You can take serene walks along the powder-soft Mandarmani Beach, enjoy magnificent sunrise and sunset views, and soak in the tranquil environs of the place.",
      not_to_miss: [],
      travel_tips: [
        {
          item: "Follow all the safety instructions before taking a plunge into the sea.",
        },
        {
          item: "Avoid visiting the beach late in the evening for your safety. ",
        },
      ],

      popular_in_country: false,
      popular_in_state: true,
      popular_in_main_destination: true,
      category_type: "Temple",
      place_type: "finaldestination",
      destination_type: "finaldestination",
      address: {
        street_address: "East midanpur,kolkata, westbengal",
        lat_long: "23.45425, 86.6481",
        country: "India",
        state: "West bengal",
      },
      main_destination_id: "",
    },
    {
      place_name: "Marine Aquarium and Regional Centre",
      place_uniqueness: "Diverse Marine Life",
      place_info:
        "Established in 1989, the Marine Aquarium and Regional Centre carries out regular studies and researches on the aquatic flora and fauna of the region.",
      what_place_offers:
        "Considered to be the largest of its kind in India, here you can explore the marine wildlife while learning about their food habits and natural habitat.",
      not_to_miss: [],
      travel_tips: [
        {
          item: "Follow all the safety instructions before taking a plunge into the sea.",
        },
        {
          item: "Avoid visiting the beach late in the evening for your safety. ",
        },
      ],

      popular_in_country: false,
      popular_in_state: true,
      popular_in_main_destination: true,
      category_type: "Temple",
      place_type: "finaldestination",
      destination_type: "finaldestination",
      address: {
        street_address: "East midanpur,kolkata, westbengal",
        lat_long: "23.45425, 86.6481",
        country: "India",
        state: "West bengal",
      },
      main_destination_id: "",
    },
    {
      place_name: "Talsari Beach",
      place_uniqueness: "Picturesque Beach",
      place_info:
        "About 8 kms from Digha Beach, Talasari Beach in Odisha is known for its tranquil environs and fascinating backwaters which open into the sea.",
      what_place_offers:
        "You can enjoy walking across the sand dunes along the flat beach, soak in the magical moonlit nights and revel in the serenity of the whole place.",
      not_to_miss: [],
      travel_tips: [
        {
          item: "Follow all the safety instructions before taking a plunge into the sea.",
        },
      ],

      popular_in_country: false,
      popular_in_state: true,
      popular_in_main_destination: true,
      category_type: "Temple",
      place_type: "finaldestination",
      destination_type: "finaldestination",
      address: {
        street_address: "East midanpur,kolkata, westbengal",
        lat_long: "23.45425, 86.6481",
        country: "India",
        state: "West bengal",
      },
      main_destination_id: "",
    },
    {
      place_name: "Digha Science Centre Park",
      place_uniqueness: "Kid-friendly Knowledge Destination",
      place_info:
        "Offering a fun yet informative outlook on modern science experiments, Digha Science Centre Park has become a popular tourist attraction amongst families travelling with kids.",
      what_place_offers:
        "You can check out the interesting science experiments, learn about amusing day-to-day activities and take a walk across the well-maintained gardens here.",
      not_to_miss: [],
      travel_tips: [
        {
          item: "Follow all the safety instructions before taking a plunge into the sea.",
        },
      ],

      popular_in_country: false,
      popular_in_state: true,
      popular_in_main_destination: true,
      category_type: "Temple",
      place_type: "finaldestination",
      destination_type: "finaldestination",
      address: {
        street_address: "East midanpur,kolkata, westbengal",
        lat_long: "23.45425, 86.6481",
        country: "India",
        state: "West bengal",
      },
      main_destination_id: "",
    },
  ],

  // PURI
  [
    {
      place_name: "Shree Jagannath Temple",
      place_name: "Bhitarkanika Wildlife Sanctuary",
      place_name: "Lingaraj Mandir",
      place_name: "Nalabana Island",
      place_name: "Satkosia Tiger Sanctuary",
      place_name: "Nandankanan Zoological Park",
      place_name: "Model Beach",
      place_name: "Rajarani Mandir",
    },
  ],

  // GOA
  [
    {
      neighbour: {
        name: "North Goa: ",
        places: [
          "Reis Magos Fort",
          "Fort Aguada",
          "Museum of Goa",
          "Terekhol Fort",
          "Mandrem Beach",
          "Chapora Fort",
          "Church of Nossa Senhora de Penha de Franca",
        ],
      },
      neighbour: {
        name: "South Goa: ",
        places: [
          "Goa Chitra",
          "Braganza House",
          "Palácio do Deão",
          "Shri Chandreshwar Temple",
          "Patnem Beach",
          "Agonda Beach",
          "Church of the Holy Spirit",
          "Cotigao Wildlife Sanctuary",
        ],
      },
      neighbour: {
        name: "Central Goa: ",
        places: [
          "Basilica de Bom Jesus",
          "Sé Cathedral",
          "Church of Our Lady of the Immaculate Conception",
          "Dudhsagar Falls",
          "Church & Convent of St Cajetan",
          "Shri Mahadeva Temple",
          "Dr Salim Ali Bird Sanctuary",
          "Goa State Museum & Secretariat Building",
        ],
      },
    },
  ],

  // DUBAI
  [
    {
      neighbour: {
        name: "Downtown: ",
        info: "Dubai's vibrant urban centrepiece is anchored by the 828m-high Burj Khalifa, the world's tallest structure, and also brims with other distinctive and futuristic architecture, especially along Sheikh Zayed Rd and in the Dubai Design District – it's no surprise that famed British architect Norman Foster recently opened an office here.",
        places: [
          {
            name: "Burj Khalifa",
            info: "The Burj Khalifa is a stunning feat of architecture and engineering, with two observation decks on the 124th and 148th floors and a restaurant-bar on the…",
          },
          {
            name: "Alserkal Avenue",
            info: "Edgy contemporary art from the Middle East and beyond has found a home in Dubai thanks to the vision of Abdelmonem Bin Eisa Alserkal. The local developer…",
          },
          {
            name: "Dubai Aquarium & Underwater Zoo",
            info: "Dubai Mall’s most mesmerising sight is this gargantuan aquarium where thousands of beasties flit and dart amid artificial coral. Sharks and rays are top…",
          },
          {
            name: "Dubai Fountain",
            info: "This dancing fountain is spectacularly set in the middle of a giant lake against the backdrop of the glittering Burj Khalifa. Water undulates as",
          },
          {
            name: "Dubai Design District",
            info: "Creative folks have a new HQ in Dubai. The fresh-off-the-drawing board Dubai Design District (d3) has drawn both regional and international talent and…",
          },
        ],
      },

      neighbour: {
        name: "Bur Dubai",
        info: "Bur Dubai may not be as sleek and sophisticated as the newer townships, but its animated street life exudes a sense of community spirit that is rarely found elsewhere. Come here to soak up the city's history in the Dubai Museum and the restored Al Fahidi and Shindagha historic districts. Wander the lanes around the souq teeming with locals, ethnic eateries and atmospheric traditional shops. Stock up on souvenirs then watch the boat traffic on Dubai Creek from a waterfront cafe in the re-energised Al Seef area.",
        places: [
          {
            name: "Dubai Museum",
            info: "The city's main historical museum charts Dubai's turbo-evolution from fishing and pearling village to global centre of commerce, finance and tourism. It…",
          },
          {
            name: "Sheikh Mohammed Centre for Cultural Understanding",
            info: "Anyone keen on delving deeper into Emirati culture and history should take advantage of the activities, Emirati meals and tours offered through this…",
          },
          {
            name: "Alserkal Cultural Foundation",
            info: "This nonprofit foundation runs the most dynamic cultural space in the Al Fahidi Historic District. Galleries showcasing traditional and cutting-edge works…",
          },
          {
            name: "Sheikh Saeed Al Maktoum House",
            info: "Recently restored as part of the new Shindagha Historic District development, this grand courtyard house served as the residence of Sheikh Saeed, the…",
          },
          {
            name: "Shindagha Historic District",
            info: "Strategically located at the mouth of Dubai Creek, Shindagha was where the ruling sheikhs and the city elite lived until the 1950s. While a few of the…",
          },
        ],
      },

      neighbour: {
        name: "Dubai Marina & Palm Jumeirah",
        info: "Dubai's southernmost districts brim with luxurious beachfront resorts and restaurants, plus some of the city's most glamorous see-and-be-seen nightclubs. Daytime diversions here include strolls along the Dubai Marina waterfront, The Walk at JBR and The Beach at JBR.",
        places: [
          {
            name: "The Beach at JBR",
            info: "Paralleling the beachfront for about 1km, The Beach at JBR is an open-plan cluster of low-lying, urban-style buildings wrapped around breezy plazas…",
          },
          {
            name: "Fairmont The Palm Beach Club",
            info: "Views of the mainland skyline are one of the most memorable aspects of a day at this family-orientated club at the swish Fairmont Hotel. Parents get to…",
          },
          {
            name: "Cayan Tower",
            info: "Stretching skyward for 307m, it may not be the tallest residential tower in the Dubai Marina, but it's certainly a building with twist: a 90-degree spiral…",
          },
          {
            name: "Elephant Clock",
            info: "If you don’t know the story behind it, you might wonder why people are taking so many pictures of an ornamental elephant. But when you learn it’s a…",
          },
        ],
      },

      neighbour: {
        name: "Deira",
        info: "Multicultural Deira (dare-uh) feels like a cross between Cairo and Karachi. Dusty, crowded and chaotic, Deira is one of Dubai's oldest and most charismatic neighbourhoods and a world away from modern Dubai.",
        places: [
          {
            name: "Gold Souq",
            info: "All that glitters is gold (and occasionally silver) along this covered arcade where dozens of shops overflow with every kind of jewelry imaginable, from…",
          },
          {
            name: "Dhow Wharfage",
            info: "Stroll down the Creek for photogenic close-ups of dozens of brightly coloured dhows docked next to the Deira souqs that load and unload everything from…",
          },
          {
            name: "Al Mamzar Beach Park",
            info: "This lushly landscaped beach park consists of a string of five lovely sandy sweeps and comes with plenty of infrastructure, including a swimming pool,…",
          },
          {
            name: "Heritage House",
            info: "Closed for renovation until the end of 2019, this 1890 courtyard house once belonged to Sheikh Ahmed Bin Dalmouk, a wealthy pearl merchant and founder of…",
          },
          {
            name: "Museum of the Poet Al Oqaili",
            info: "In 1923 this beautifully restored home tucked into the narrow lanes on the edge of the Spice Souq became the home of Saudi-born Mubarak Bin Al Oqaili …",
          },
          {
            name: "Naif Market",
            info: "The historic Naif Souq was burned down in 2008 and replaced by this mall-style version, albeit fronted by arabesque arches. It is especially popular with…",
          },
        ],
      },
    },
  ],

  // LA
  [
    {
      neighbour: {
        name: "Downtown Los Angeles & Boyle Heights",
        info: "Though still sketchy in patches, Downtown (DTLA) continues the upward swing that began a decade or more ago. New condos and hotels are sprouting like mushrooms, joined by buzz-inducing eateries, bars, boutiques and galleries, all connected by bus and rail transit. It's here that you'll find the space-age Broad museum and Walt Disney Concert Hall, which provide dramatic counterpoints to the crumbling opulence of Broadway theaters, the Spanish curves of historic Olvera St and the hulking warehouses of the booming Arts District. Across the concrete Los Angeles River lies East LA, the oldest and largest Mexican community outside of Mexico and home to earthy, vibrant Boyle Heights.",
        places: [
          {
            name: "Broad",
            info: "From the instant it opened in September 2015, the Broad (rhymes with 'road') became a must-visit for contemporary-",
          },
          {
            name: "Cathedral of Our Lady of the Angels",
            info: "LA is unlike any other city, and this cathedral is unlike any other cathedral. Seat of the US's largest Roman Catholic archdiocese (some five",
          },
          {
            name: "Walt Disney Concert Hall",
            info: "A molten blend of steel, music and psychedelic architecture, this iconic concert venue is the home base of the Los Angeles Philharmonic, but ha",
          },
          {
            name: "Grammy Museum",
            info: "The highlight of LA Live, this museum's interactive exhibits define, differentiate and link musical genres. Spanning three levels, the ",
          },
          {
            name: "OUE Skyspace LA",
            info: "Perched atop the city's tallest building to roof level, this is LA's loftiest observation deck, offering knockout view",
          },
        ],
      },
      neighbour: {
        name: "Westlake & Koreatown",
        info: "Once a glamorous enclave for silent-film stars and tycoons, Westlake is now Downtown's raffish western neighbor, tempered by lake-studded MacArthur Park, the very one that ‘melts in the dark’ in the eponymous Jimmy Webb song made famous by Donna Summer. Despite the faint whiff of gentrification, the neighborhood remains a gritty bastion of working-class Latino vida. Further west, Westlak",
        places: [
          {
            name: "Chapman Plaza",
            info: "It might look like an extravagant Spanish baroque villa, but this complex began life as one of the first drive-in markets in the western US. Opened i",
          },
          {
            name: "Bullocks Wilshire",
            info: "Clad in terracotta tiles and adorned with copper decorative motifs, this 1929 art-deco diva was the country’s first department store designed for shop...",
          },
          {
            name: "Wilshire Boulevard Temple",
            info: "The construction of this Byzantine-revival behemoth was overseen by Edgar Magnin, dubbed 'Rabbi of the Stars' due to his numerous Hollywood pals...",
          },
          {
            name: "MacArthur Park",
            info: "The centerpiece of MacArthur Park is its 14ft-deep lake. Originally a swamp, the lake reached 6th St before the extension of Wilshire Blvd sliced right…",
          },
          {
            name: "",
            info: "",
          },
        ],
      },
      neighbour: {
        name: "South Bay Beaches",
        info: "When you’ve had all the Hollywood ambition, artsy pretension, velvet ropes and mind-numbing traffic you can take, head south of the airport, where this string of beach towns along Santa Monica Bay will soothe that mess from your psyche in one sunset. Buff volleyballers brush elbows with well-to-do University of Southern California (USC) alumni and an increasingly interesting restaurant scene",
        places: [
          {
            name: "Abalone Cove Shoreline Park",
            info: "The best place to hunt for starfish, anemones and other tide-pool critters is in and around this rock-strewn eco-preserve. The walk down to the beach gets…",
          },
          {
            name: "Wayfarers Chapel",
            info: "The most stunning non-natural attraction on Palos Verdes was built by Lloyd Wright (son of Frank) in 1951, and no matter where you stand among the great…",
          },
          {
            name: "Redondo Beach Pier",
            info: "Arching from the bottom of Torrance Blvd all the way to King Harbor, this classic 1960s multilevel beast is the Redondo hub, with plenty of pierside…",
          },
          {
            name: "Hermosa Beach",
            info: "Strolling down Hermosa Beach’s Pier Ave on a summer weekend, you’re immediately struck by two things: everybody’s wearing flip-flops, tiny tees and a tan,…",
          },
          {
            name: "Manhattan Beach",
            info: "If Manhattan Beach had its own magazine, it would surely be called Gorgeous Living. Classy beachside cottages, bougainvillea-lined walk streets, bustling…",
          },
        ],
      },
      neighbour: {
        name: "Long Beach & San Pedro",
        info: "Along LA County's southern shore and adjacent to Orange County, the twin ports of Long Beach and San Pedro provide attractions from ship to hip. Ramble around the art deco ocean liner Queen Mary, scramble around the Battleship Iowa, or immerse yourself in the Aquarium of the Pacific. Then go for...",
        places: [
          {
            name: "Battleship Iowa",
            info: "This WWII to Cold War–era battleship is now permanently moored in San Pedro Bay and open to visitors as a museum. It's massive – 887ft long (that's 5ft…",
          },
          {
            name: "Aquarium of the Pacific",
            info: "Long Beach’s most mesmerizing experience, the Aquarium of the Pacific is a vast, high-tech indoor ocean where sharks dart, jellyfish dance and sea lions…",
          },
          {
            name: "Museum of Latin American Art",
            info: "This gem of a museum is the only one in the US to present art created since 1945 in Latin America and in Latino communities in the US through important…",
          },
          {
            name: "Queen Mary",
            info: "Long Beach’s ‘flagship’ attraction is this grand – and supposedly haunted! – British luxury liner. Larger and more luxurious than even the Titanic, she…",
          },
          {
            name: "Cabrillo Marine Aquarium",
            info: "This city-owned aquarium is the smaller, older, low-tech cousin of Long Beach’s Aquarium of the Pacific. It’s also a lot lighter on your wallet and less…",
          },
        ],
      },
    },
  ],
];

// FIRST store-information in google-docs for always having backup
const mainDestinationsLocal = [
  {
    place_name: "",
    place_info: "",
    more_info: [
      {
        title: "Food & Drink to try in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Culture in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Shopping in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
    ],
    how_to_reach: {
      overview:
        "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
      flight: "",
      road: "",
      train: "",
    },
    popular_in_country: true,
    popular_in_state: false,
    theme_type: "Beach",
    place_type: "maindestination",
    destination_type: "maindestination",
    best_time_to_go: {
      overview:
        "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
      place_in_winter: "(October - February) peak, off, moderate",
      place_in_summer: "(March-June) peak, off,moderate",
      place_in_monsoon: "(July-September) peak, off,moderate",
    },
    address: {
      street_address: "East midanpur,kolkata, westbengal",
      lat_long: "23.45425, 86.6481",
      country: "India",
      state: "West bengal",
    },
  },

  // DIGHA
  {
    place_name: "Digha",
    place_info:
      "Nestled on the coast of the Bay of Bengal, Digha is about 4.5 hours from Kolkata. It is known for its laidback beaches, picturesque vistas and diverse marine life.",
    more_info: [
      {
        title: "Food & Drink to try in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Culture in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Shopping in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
    ],
    how_to_reach: {
      overview:
        "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
      flight:
        "Digha does not have its own airport. The nearest airport is the Netaji Subash Chandra Bose International Airport of Kolkata. The airport is well connected to many prominent cities of India. There are bus services available to commute between the airport and Digha. Alternatively, you can also hire a taxi.",
      road: "If you are travelling to Digha from nearby towns, hiring a taxi or self-driving is most suitable. Also, there are two bus stands in Digha- SBSTC Bus Depot and Old Digha Bus Stop. Both of them are located at a walking distance. Regular buses connect Digha to neighbouring cities. So, you can choose to travel by bus also",
      train:
        "There is a railway station in Digha. It is located at a distance of around 450 metres from Digha. It takes only 2 minutes to reach the city. The railway station started its operations properly in 2004 and is well connected through regular trains to all the nearby cities like Kolkata.",
    },
    popular_in_country: true,
    popular_in_state: false,
    theme_type: "Beach",
    place_type: "maindestination",
    destination_type: "maindestination",
    best_time_to_go: {
      overview:
        "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
      place_in_winter: "(October - February) peak, off,moderate",
      place_in_summer: "(March-June) peak, off,moderate",
      place_in_monsoon: "(July-September) peak, off,moderate",
    },
    address: {
      street_address: "East midanpur,kolkata, westbengal",
      lat_long: "23.45425, 86.6481",
      country: "India",
      state: "West bengal",
    },
  },

  // PURI
  {
    place_name: "Puri",
    place_info:
      "About 2 hours from Bhubaneshwar, Puri is a coastal temple town along the Bay of Bengal in Odisha. It lies close to tourist hotspots like Konark Temple and Dhabaleswar Temple.",
    more_info: [
      {
        title: "Food & Drink to try in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Culture in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
      {
        title: "Shopping in Kolkata",
        description:
          "The downpours are also fairly intense in July, August, and September, during what's known as monsoon season. From a weather perspective, November to March are the best time to visit New Delhi, when it's coolest and driest—with temperatures in the 70s and 80s. As ",
      },
    ],
    how_to_reach: {
      overview:
        "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
      flight: "",
      road: "",
      train: "",
    },
    popular_in_country: true,
    popular_in_state: false,
    theme_type: "Beach",
    place_type: "maindestination",
    destination_type: "maindestination",
    best_time_to_go: {
      overview:
        "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
      place_in_winter: "(October - February) peak, off, moderate",
      place_in_summer: "(March-June) peak, off,moderate",
      place_in_monsoon: "(July-September) peak, off,moderate",
    },
    address: {
      street_address: "puri,odisa",
      lat_long: "23.45425, 86.6481",
      country: "India",
      state: "Odisa",
    },
  },

  // GOA
  {
    place_name: "Goa",
    place_info:
      "A kaleidoscopic blend of Indian and Portuguese cultures, sweetened with sun, sea, sand, seafood, susegad and spirituality, Goa is India's pocket-sized paradise.",
  },

  // DUBAI
  {
    place_name: "Dubai",
    place_info:
      "Dubai is a stirring alchemy of profound traditions and ambitious futuristic vision wrapped into starkly evocative desert splendour.",
  },

  // lA
  {
    place_name: "Los Angelas",
    place_info:
      "Ruggedly good looking, deeply creative, with a sunny disposition to boot…if LA were on Tinder, the app would crash",
  },
];

// holidify.com [1. either in more-info form (recommended one) or key_value pair form, 2. Can make a separate blog for both one [MAINDESTINATION only] not for every place]
// in `OVERVIEW` use makemytrip form means short & concise, no unnecessary words to keep it very short & in `DETAILS` use holidify.com
const howToReachAndBestTimeToGoKolkata = {
  how_to_reach: {
    overview:
      "The nearest airport from Digha is the Netaji Subash Chandra Bose International Airport of Kolkata which is located at a distance of around 150 km from Digha. The airport is well connected to main cities of India. Bus services are available in Digha. There are two bus stands within the city. Around 450 metres away from Digha is the railway station which is connected to nearby cities through regular trains.",
    flight:
      "Digha does not have its own airport. The nearest airport is the Netaji Subash Chandra Bose International Airport of Kolkata. The airport is well connected to many prominent cities of India. There are bus services available to commute between the airport and Digha. Alternatively, you can also hire a taxi.",
    road: "If you are travelling to Digha from nearby towns, hiring a taxi or self-driving is most suitable. Also, there are two bus stands in Digha- SBSTC Bus Depot and Old Digha Bus Stop. Both of them are located at a walking distance. Regular buses connect Digha to neighbouring cities. So, you can choose to travel by bus also",
    train:
      "There is a railway station in Digha. It is located at a distance of around 450 metres from Digha. It takes only 2 minutes to reach the city. The railway station started its operations properly in 2004 and is well connected through regular trains to all the nearby cities like Kolkata.",
  },
  best_time_to_go: {
    overview:
      "October to February is the best time to hit the beaches at Digha. Apart from beaches, there are aquariums and gardens to cover, for which the most comfortable time is during these months. Winter is also the time for the biggest celebration in the whole of Bengal- Durga Puja. Summers in Digha are hot and uncomfortable with temperatures rising up to 37 degrees celcius. This isn't an ideal time to visit the palce. Whereas in July the monsoon rains might wash away your plans to enjoy both beaches and amusement parks.The level of humidity is higher making it less pleasant to travel",
    place_in_winter: "(October - February) peak, off,moderate",
    place_in_summer: "(March-June) peak, off,moderate",
    place_in_monsoon: "(July-September) peak, off,moderate",
  },
};

const finalDestinationsLocal = [
  {
    place_name: "",
    place_uniqueness: "",
    place_info: "",
    what_place_offers: "",
    not_to_miss: "",
    travel_tips: "",

    popular_in_country: false,
    popular_in_state: true,
    popular_in_main_destination: true,
    category_type: "Temple",
    place_type: "finaldestination",
    destination_type: "finaldestination",
    address: {
      street_address: "East midanpur,kolkata, westbengal",
      lat_long: "23.45425, 86.6481",
      country: "India",
      state: "West bengal",
    },
    main_destination_id: "",
  },
];

const places = {
  neighbour: {
    name: "",
    info: "",
    places: [
      {
        name: "",
        info: "",
      },
      {
        name: "",
        info: "",
      },
      {
        name: "",
        info: "",
      },
      {
        name: "",
        info: "",
      },
      {
        name: "",
        info: "",
      },
    ],
  },
};

export default destinations;
