import React, { useState } from "react";
import styled from "styled-components";
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function PageNotFound() {
  return (
    <Wrapper>
      <Helmet>
        <title>Page Not Found - travelBucket </title>
      </Helmet>
      <div className="container uk-flex uk-flex-column uk-flex-middle uk-flex-center">
        <p>Oops! Could not find the page you are looking for.</p>
        <Link to="/" className="uk-button uk-button-secondary">
          go to home
        </Link>
      </div>
    </Wrapper>
  );
}

export default PageNotFound;

const Wrapper = styled.main`
  .container {
    height: 60vh;
  }
`;
