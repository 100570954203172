import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";

//TODO: In order to use TinyMCE in production, you have to register your your domain on its platform.
function TinyMainEditor() {
  const [value, setValue] = useState("");
  const [text, setText] = useState("");
  const editorRef = useRef(null);

  const plugins = `
fullscreen 
image
editimage
accordion
footnotes
lists
advlist
anchor
autolink
code
codesample 
emoticons 
importcss 
insertdatetime 
link
lists 
media 
nonbreaking 
pagebreak 
preview 
table 
searchreplace 
template  
wordcount 
visualchars`;

  const toolbars = `
undo redo |
blocks | 
bold italic | 
alignleft aligncenter alignright alignjustify | 
bullist numlist outdent indent |
help`;

  const onEditorInputChange = (newValue, editor) => {
    setValue(newValue);
    setText(editor.getContent({ format: "text" }));
  };

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <h4>Tiny MCE Editor</h4>
      <Editor
        apiKey="brarhy7m550pnu1owx89yf05ozfoggr9swotxconxmi2clxu"
        onEditorChange={(newValue, editor) =>
          onEditorInputChange(newValue, editor)
        }
        onInit={(evt, editor) => setText(editor.getContent({ format: "text" }))}
        value={value}
        initialValue={"Write your thoughts here..."}
        init={{
          plugins: plugins,
          toolbar: toolbars,
        }}
      />
      {/* <Editor
        apiKey="brarhy7m550pnu1owx89yf05ozfoggr9swotxconxmi2clxu"
        init={{
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown",
          toolbar:
            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          mergetags_list: [
            { value: "First.Name", title: "First Name" },
            { value: "Email", title: "Email" },
          ],
          ai_request: (request, respondWith) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        initialValue="Welcome to TinyMCE!"
      /> */}
      {/* <Editor
        apiKey="brarhy7m550pnu1owx89yf05ozfoggr9swotxconxmi2clxu"
        onInit={(evt, editor) => {
          editorRef.current = editor;
          console.log("TinyMCE Editor initialized:", editor);
        }}
        initialValue="<p>This is the initial content of the editor.</p>"
        init={{
          height: 500,
          menubar: false,
          readonly: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      /> */}
      /<button onClick={log}>Log editor content</button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .editor-style {
    // min-height: 5rem;
  }
`;

export default TinyMainEditor;
