import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getUser } from "../../utils/utility";
import useApi from "../../utils/custom-hooks/useApi";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function CKMainEditor() {
  const articleId = "6651e57207699f39282cc883";
  const [editorValue, setEditorValue] = useState("");
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  const user = getUser();

  useEffect(() => {
    fetchArticle();
  }, []);

  const fetchArticle = async () => {
    await makeApiCall("GET", `/article/${articleId}`);
    if (resultRef.current) {
      if (resultRef.current.status) {
        const storedBody = resultRef.current.article.body;
        const doc = new DOMParser().parseFromString(storedBody, "text/html");
        const decodedHtml = doc.documentElement.textContent.trim();
        setEditorValue(decodedHtml);
        //  setAllData(resultRef.current);
        //  addInCache(`article-id-${articleId}`, resultRef.current);

        //
        // setTitle(resultRef.current.article.title);
        // const storedBody = resultRef.current.article.body;
        // const doc = new DOMParser().parseFromString(storedBody, "text/html");
        // const decodedHtml = doc.documentElement.textContent.trim();
        // setValue(decodedHtml);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const submitArticle = async () => {
    console.log("DATA: ", editorValue);

    const data = {
      title: "CK Editor Test Title",
      body: editorValue,
      createdBy: user.user_id,
      updatedBy: user.user_id,
    };

    await makeApiCall("POST", `/admin/article`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);
        console.log("SUBMITTED: ARTICLE", resultRef.current);
        //  const newlyCreatedArticleId = resultRef.current.article.article_id;
        //  addInCache(`article-id-${newlyCreatedArticleId}`, resultRef.current);
        //  history.replace(`/article/${newlyCreatedArticleId}`);

        // setData(resultRef.current.article);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const updateArticle = async () => {
    const data = {
      article_id: articleId,
      title: "CK Editor Test Title",
      body: editorValue,
      updatedBy: user.user_id,
    };

    await makeApiCall("PUT", `/admin/article`, data);
    if (resultRef.current) {
      if (resultRef.current.status) {
        alert(`SUCCESS:  ${resultRef.current.message}`);

        // addInCache(`article-id-${articleId}`, resultRef.current);
        // history.push(`/article/${articleId}`);
        // setData(resultRef.current.article);
      } else {
        alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const config1 = {
    toolbar: {
      items: [
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "|",
        "heading",
        "|",
        "undo",
        "redo",
      ],
    },
  };
  const config2 = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "outdent",
        "indent",
        "|",
        "uploadImage",
        "blockQuote",
        "insertTable",
        "codeBlock",
        "mediaEmbed",
        "undo",
        "redo",
      ],
    },
    image: {
      toolbar: [
        "imageStyle:inline",
        "imageStyle:block",
        "imageStyle:side",
        "|",
        "toggleImageCaption",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: "en",
  };

  const config = {
    toolbar: {
      items: [
        "heading",
        "fontSize",
        "fontFamily",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "outdent",
        "indent",
        "|",
        "uploadImage",
        "blockQuote",
        "insertTable",
        "codeBlock",
        "mediaEmbed",
        "undo",
        "redo",
        "|",
        // "alignment",
        // "|",
        // "fontSize",
        // "fontFamily",
        // "fontColor",
        // "fontBackgroundColor",
        // "highlight",
        // "|",
      ],
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    fontFamily: {
      options: [
        "default",
        "Arial, Helvetica, sans-serif",
        "Courier New, Courier, monospace",
        "Georgia, serif",
        "Lucida Sans Unicode, Lucida Grande, sans-serif",
        "Tahoma, Geneva, sans-serif",
        "Times New Roman, Times, serif",
        "Trebuchet MS, Helvetica, sans-serif",
        "Verdana, Geneva, sans-serif",
      ],
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    image: {
      toolbar: [
        "imageStyle:inline",
        "imageStyle:block",
        "imageStyle:side",
        "|",
        "toggleImageCaption",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: "en",
  };
  const writeArticleSegment = () => {
    return (
      <>
        <h4>CK Editor: Write</h4>
        <button
          className="uk-button uk-button-secondary"
          onClick={submitArticle}
        >
          submit
        </button>
        <CKEditor
          editor={ClassicEditor}
          data="<p>Hello from CKEditor&nbsp;5!</p>"
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorValue(data);
            // console.log(event);
          }}
          onBlur={(event, editor) => {
            // console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            // console.log("Focus.", editor);
          }}
        />
      </>
    );
  };

  const previewArticleSegment = () => {
    return (
      <>
        <h4>CK Editor: Preview</h4>

        <CKEditor editor={ClassicEditor} data={editorValue} disabled={true} />
      </>
    );
  };

  const editArticleSegment = () => {
    return (
      <>
        <h4>CK Editor: Edit</h4>
        <button
          className="uk-button uk-button-secondary"
          onClick={updateArticle}
        >
          update
        </button>
        <CKEditor
          editor={ClassicEditor}
          data={editorValue}
          config={config}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorValue(data);
          }}
        />
      </>
    );
  };

  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      {/* {writeArticleSegment()} */}
      {/* {previewArticleSegment()} */}
      {editArticleSegment()}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .editor-style {
    /* min-height: 5rem; */
  }
`;

export default CKMainEditor;
