import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AddCountry from "./components/AddCountry";
import AddState from "./components/AddState";
import AddZone from "./components/AddZone";

function AddPlaceTypes() {
  return (
    <Wrapper className="uk-container uk-container-small uk-margin-medium-top">
      <AddCountry />
      <AddState />
      <AddZone />
    </Wrapper>
  );
}

export default AddPlaceTypes;

const Wrapper = styled.main`
  background: #f2f2f2;
`;
