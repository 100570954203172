import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Redirect, Link, useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
// import ReCAPTCHA from "react-google-recaptcha";

import {
  mandatoryField,
  themes,
  placeTypes,
  getUser,
  addUserWithExpiry,
} from "../utils/utility";
import Loading from "../components/Loading";
import useApi from "../utils/custom-hooks/useApi";

const emailRegex =
  '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/';
const emailRegex1 = "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]+$/i";

// TODO: reCaptcha already implemented on both frontend as well backend but commented for now
// link: https://blog.logrocket.com/implement-recaptcha-react-application/#using-reaptcha-router
function LoginPage() {
  const [isSignUp, setIsSignUp] = useState(false);
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();
  const user = getUser();
  const history = useHistory();
  // const captchaRef = useRef(null);

  const [redirect, setRedirect] = useState(user ? true : false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // console.log("LOGIN PAGE LOADING  useEffect");
    // const perfObserver = new PerformanceObserver((observedEntries) => {
    //   const entry = observedEntries.getEntriesByType("navigation")[0];
    //   console.log("PAGE LOAD TIME: ", entry.responseEnd - entry.requestStart);
    // });
    // perfObserver.observe({
    //   type: "navigation",
    //   buffered: true,
    // });
  }, []);

  const handleSignIn = async (data) => {
    // const token = captchaRef.current.getValue();
    //  captchaRef.current.reset();

    const userInfo = {
      email: data.email,
      password: data.password,
      // captcha_token: token,
    };

    await makeApiCall("POST", `/signin`, userInfo);
    if (resultRef.current) {
      if (resultRef.current.status) {
        const { message, user, token } = resultRef.current;
        addUserWithExpiry(user, token);
        // alert(`SUCCESS:  ${message}`);
        toast.success("Wohoo... successfully logged in!");
        // setRedirect(true);
        const redirectTo = history.location.state
          ? history.location.state.from
          : "/my-bucket";
        history.replace(redirectTo);
      } else {
        toast.error(`${resultRef.current.message}`);
        // alert(`FAIL: ${resultRef.current.message}`);
      }
    }
  };

  const handleSignUp = async (data) => {
    const userInfo = {
      name: data.userName,
      email: data.userEmail,
      password: data.userPassword,
    };
    await makeApiCall("POST", `/signup`, userInfo);
    if (resultRef.current) {
      if (resultRef.current.status) {
        const { message, user, token } = resultRef.current;
        addUserWithExpiry(user, token);
        // alert(`SUCCESS:  ${message}`);
        toast.success("Wohoo... successfully account created!");
        // setRedirect(true);
        const redirectTo = history.location.state
          ? history.location.state.from
          : "/my-bucket";
        history.replace(redirectTo);
      } else {
        // alert(`FAIL: ${resultRef.current.message}`);
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const itemClick = () => {
    setIsSignUp(!isSignUp);
  };

  const signInForm = () => {
    return (
      <div className="uk-form-width-large form-container-small">
        <div className="uk-card uk-card-default uk-card-body uk-card-small uk-card-hover">
          <form class="uk-form-stacked " onSubmit={handleSubmit(handleSignIn)}>
            <div class="uk-margin">
              <label class="uk-form-label">Email</label>
              <input
                class="uk-input"
                type="text"
                placeholder="Your email"
                {...register("email", {
                  required: {
                    value: true,
                    message: "email is required",
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "This value should be a valid email.",
                    //   message: "not a valid email format",
                    //   value: /\S+@\S+\.\S+/,
                  },
                })}
              />
              <span className="uk-text-danger">{errors.email?.message}</span>
            </div>

            <div class="uk-margin">
              <label class="uk-form-label">Password</label>
              <input
                class="uk-input"
                type="password"
                placeholder="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: "password is required",
                  },
                  minLength: {
                    value: 5,
                    message: "password should be of min 5 char",
                  },
                })}
              />
              <span className="uk-text-danger">{errors.password?.message}</span>
            </div>

            {/* <ReCAPTCHA
              sitekey="6LcD4oAmAAAAAP7P5mqzlr57rgIYiATM2f9vBeHF"
              ref={captchaRef}
            /> */}

            <p className="uk-text-primary uk-text-right">
              <Link to="/forgot-password">Forgot your password?</Link>
            </p>

            <button
              className="uk-button uk-button-secondary uk-margin-small-bottom"
              type="submit"
              disabled={isLoading}
            >
              log in
            </button>
          </form>
        </div>
      </div>
    );
  };

  const signUpForm = () => {
    return (
      <div className="uk-form-width-large form-container-small">
        <div className="uk-card uk-card-default uk-card-body uk-card-small uk-card-hover">
          <form class="uk-form-stacked " onSubmit={handleSubmit(handleSignUp)}>
            <div class="uk-margin">
              <label class="uk-form-label">Name</label>
              <input
                class="uk-input"
                type="text"
                placeholder="Your name"
                {...register("userName", {
                  required: {
                    value: true,
                    message: "name is required",
                  },
                  minLength: {
                    value: 3,
                    message: "name should be of minimum 3 character",
                  },
                })}
              />
              <span className="uk-text-danger">{errors.userName?.message}</span>
            </div>

            <div class="uk-margin">
              <label class="uk-form-label">Email</label>
              <input
                class="uk-input"
                type="text"
                placeholder="Your email"
                {...register("userEmail", {
                  required: {
                    value: true,
                    message: "email is required",
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Not a valid email format",
                    //   value: /\S+@\S+\.\S+/,
                  },
                })}
              />
              <span className="uk-text-danger">
                {errors.userEmail?.message}
              </span>
            </div>

            <div class="uk-margin">
              <label class="uk-form-label">Password</label>
              <input
                class="uk-input"
                type="text"
                placeholder="password"
                {...register("userPassword", {
                  required: {
                    value: true,
                    message: "password is required",
                  },
                  minLength: {
                    value: 5,
                    message: "password should be of minimum 5 character",
                  },
                })}
              />
              <span className="uk-text-danger">
                {errors.userPassword?.message}
              </span>
            </div>

            <button
              className="uk-button uk-button-secondary uk-margin-small-bottom"
              type="submit"
              disabled={isLoading}
            >
              Create Account
            </button>
          </form>
        </div>
      </div>
    );
  };
  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger uk-margin-remove-top" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
          </div>
        </>
      );

      //  <h4 className="uk-text-danger">{error.msg}</h4>

      //   <div class="uk-alert-danger" data-uk-alert>
      //     <a class="uk-alert-close" data-uk-close></a>
      //     <p>{error.msg}</p>
      //   </div>
      // );

      // toast.error(`${error.msg}`);
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return (
        <div
          className="uk-margin-small-to uk-margin-small-bottom"
          data-uk-spinner="ratio: 1"
        ></div>
      );
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Login | Signup - travelBucket </title>
      </Helmet>

      <div className="inner-container">
        <div className="uk-flex uk-flex-center">
          <div>
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <h2 className="uk-margin-remove-bottom">
                {isSignUp ? "Create an account" : "Login to your account"}
              </h2>
              <p className="uk-margin-small-top item-text" onClick={itemClick}>
                {isSignUp
                  ? "Have an account? Login here."
                  : "Don't have an account? Signup here"}
              </p>
              {showLoading()}
              {errorMessage()}
            </div>
            {!isSignUp && signInForm()}
            {isSignUp && signUpForm()}

            {/* {redirect && <Redirect to={`/my-bucket`} />} */}

            {/* {redirect && (
              <>
                <CustomModal
                  modalType="alert"
                  isShow={isShow}
                  closeModal={toggleModal}
                >
                  <p>Hi, this an alert.</p>
                </CustomModal>
                ;
                <Redirect to={`/my-bucket`} />
              </>
            )} */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  background: #ebf0f4;
  min-height: 90vh;
  /* background: #c9b1b1; */
  .inner-container {
    padding: 3rem 0;
  }
  .item-text {
    /* color: #1e87f0; */
    cursor: pointer;
  }
  .item-text:hover {
    text-decoration: underline;
  }
  .bg {
    border: 2px solid red;
  }

  @media screen and (max-width: 576px) {
    .form-container-small {
      width: 320px;
    }
  }
  @media screen and (max-width: 320px) {
    .form-container-small {
      width: 280px;
    }
  }
`;
export default LoginPage;
