import { useState } from "react";

function UploadAndDisplayImages({ singleImage = false, updateImageList }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);

  const handleImageUpload = (event) => {
    const image = event.target.files[0];
    setImages((oldImages) => {
      const newList = [...oldImages, image];
      console.log("IMAGES:-----", `INSIDE length ${newList.length}`, newList);
      return newList;
    });

    // const allImages = event.target.files;
    // setImages((oldImages) => {
    //   return [...oldImages, ...allImages];
    // });
    updateImageList(images);

    console.log("IMAGES:-----", `length ${images.length}`, images);
  };

  const removeImage = (selectedIndex) => {
    const newImageList = images.filter(
      (image, index) => index !== selectedIndex
    );
    // setImages(newImageList);
    // updateImageList(images);
  };

  //  MAIN UI
  return (
    <div>
      <input
        className="uk-margin-small-bottom"
        type="file"
        name="images"
        onChange={handleImageUpload}
      />

      {/* <input type="file" name="images" onChange={handleImageUpload} /> */}
      <section className="uk-grid uk-grid-small uk-child-width-1-4">
        {images.map((image, index) => {
          return (
            <div className="uk-margin-small-bottom">
              <img
                alt="not fount"
                //   width={"250px"}
                src={URL.createObjectURL(image)}
              />
              <p className="uk-margin-small">{JSON.stringify(image.name)}</p>
              <button onClick={() => removeImage(index)}>Remove</button>
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default UploadAndDisplayImages;
