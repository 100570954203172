import React, { useState, useEffect, useRef } from "react";
import mockData from "./../mockDb";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { imageNotFound } from "../utils/utility";
import useApi from "../utils/custom-hooks/useApi";
import Loading from "../components/Loading";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useAppContext } from "../contexts/appContext";

function Home() {
  const [loading, setLoading] = useState(true);
  const [destinations, setDestinations] = useState();
  // const [error, setError] = useState(false);
  const discoverRefs = useRef(null);

  const { addInCache, getFromCache } = useAppContext();
  const { isLoading, error, result, resultRef, makeApiCall } = useApi();

  useEffect(() => {
    // const savedData = sessionStorage.getItem("dest");
    // const result = savedData ? JSON.parse(savedData) : null;

    const result = getFromCache("dest");
    if (result) {
      setDestinations(result);
      handleScrollPosition();
      // setTimeout(() => {
      //   handleScrollPosition();
      // }, 500);
    } else {
      fetchMainDestinations();
    }
  }, []);

  const fetchMainDestinations = async () => {
    await makeApiCall("GET", `/main-destinations`);
    if (resultRef.current) {
      if (resultRef.current.destinations) {
        setDestinations(resultRef.current.destinations);
        // sessionStorage.setItem(
        //   "dest",
        //   JSON.stringify(resultRef.current.destinations)
        // );
        addInCache("dest", resultRef.current.destinations);
      } else {
        toast.error(`${resultRef.current.message}`);
      }
    }
  };

  const itemClick = () => {
    sessionStorage.setItem("scrollPosition-1", window.pageYOffset);
  };

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition-1");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition-1");
    }
  };

  function handleStartExploringClick() {
    window.scrollTo({
      top: discoverRefs.current.offsetTop - 70,
      behavior: "smooth",
      // You can also assign value "auto"
      // to the behavior parameter.
    });
  }

  const errorMessage = () => {
    if (error.show) {
      return (
        <>
          <div className="uk-alert-danger" data-uk-alert>
            {/* <a className="uk-alert-close" data-uk-close></a> */}
            <p className="uk-text-danger">{error.msg}</p>
          </div>
        </>
      );
    }
  };

  const showLoading = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Explore - travelBucket </title>
      </Helmet>

      <section className="brand-intro">
        <div
          className="uk-height-1-1 uk-container uk-container-small uk-flex uk-flex-column uk-flex-center"
          data-uk-scrollspy="cls:uk-animation-fade; delay:200"
        >
          <h1
            className="brand-title"
            // data-uk-scrollspy="cls:uk-animation-fade; delay:200"
          >
            Make your travel bucket list with ease
          </h1>
          <p
            className="brand-sub-title uk-margin-small-top"
            data-uk-scrollspy="cls:uk-animation-fade; delay:200"
          >
            explore the destination by simply watching or reading the short &
            concise article
          </p>
          <div
            className="explore-btn-container"
            // data-uk-scrollspy="cls:uk-animation-fade; delay:300"
          >
            <button
              className="uk-button uk-display-inline-block tb-btn"
              onClick={handleStartExploringClick}
              // data-uk-scrollspy="cls:uk-animation-fade; delay:200"
            >
              Start exploring
            </button>
          </div>
        </div>
      </section>

      {/* TEMPORARY buttons - uikit practice */}
      {/* <div className="uk-hidden">
        <Link to={`/design-inspiration`}>
          <button
            onClick={handleStartExploringClick}
            className="uk-button uk-button-secondary"
          >
            Design Inspiration
          </button>
        </Link>
        <Link to={`/ui-kit/introduction`}>
          <button className="uk-button uk-button-secondary">
            Introduction
          </button>
        </Link>
        <Link to={`/ui-kit/installation`}>
          <button className="uk-button uk-button-secondary">
            Installation
          </button>
        </Link>
        <Link to={`/ui-kit/feature`}>
          <button className="uk-button uk-button-secondary">Feature</button>
        </Link>
      </div> */}

      <section
        className="uk-container uk-container-small uk-margin-medium"
        data-uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay:200"
        ref={discoverRefs}
      >
        <h2>Discover places</h2>
        {showLoading()}
        {errorMessage()}
        {destinations && destinations.length === 0 && (
          <p className="uk-margin-remove-top">nothing found 😞 </p>
        )}

        {/* <div className="uk-grid-small uk-child-width-1-1" data-uk-grid> */}
        {destinations &&
          destinations.map((destination) => {
            return (
              <article
                className="uk-card uk-card-default uk-card-small uk-card-body uk-card-hover uk-margin-small-bottom item"
                key={destination.place_id}
                onClick={itemClick}
              >
                <Link
                  to={`/main-destination/${destination.place_id}`}
                  className="remove-underline"
                >
                  <img
                    loading="lazy"
                    src={
                      destination.images[0]
                        ? destination.images[0].image_url
                        : imageNotFound
                    }
                    alt={destination.place_name}
                    className="card-img uk-margin-small-bottom"
                  />

                  <h3 className="uk-margin-small-top uk-margin-small-bottom">
                    {destination.place_name}
                  </h3>
                  <p className="uk-margin-remove-top uk-margin-remove-bottom">
                    {destination.place_info.length > 120 ? (
                      <>
                        {destination.place_info.slice(0, 120)}
                        <span className="uk-text-primary"> Read more...</span>
                      </>
                    ) : (
                      <>{destination.place_info}</>
                    )}
                  </p>
                </Link>
              </article>
            );
          })}
        {/* </div> */}
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  p {
    text-align: justify;
  }

  .brand-intro {
    /* background-color: #223c50; */
    background: linear-gradient(to right, #3a6073, #16222a);
    height: 85vh;
  }

  .brand-title {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 0;
    text-align: center;
    /* font-family: monospace; */
  }

  .brand-sub-title {
    /* background: linear-gradient(to right, #3a6073, #16222a); */
    /* border: 0; */
    /* tab-size: 0; */
    /* z-index: 0; */
    /* font-family: Consolas, monaco, monospace; */
    font-size: 1rem;
    color: #fff;
    text-align: center;
    /* font-family: monospace; */
  }
  .explore-btn-container {
    text-align: center;
  }
  .tb-btn {
    background-color: inherit;
    border: 1px solid var(--color--primary);
    color: var(--color--primary);
    /* transition: all 0.3s linear; */
  }
  .tb-btn:hover {
    /* background-color: inherit; */
    border-color: #617d98;
    color: #617d98;
  }
  .card-img {
    /* IMAGES WITH - SMALL CONTAINER(working fine), LARGE CONTAINER */
    /* object-fit: https://www.digitalocean.com/community/tutorials/css-cropping-images-object-fit */

    width: 100%;
    height: 20rem;
    object-fit: cover;
    background: var(--imageBackgroundColor);

    /*
      (image background) -  https://webgradients.com/ 
      (brand-intro-banner background) -  https://uigradients.com/#Sky 
       
    */
    /* background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); */

    /* background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
    background: linear-gradient(
      -180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
    background: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
    background: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
    background: linear-gradient(
      to top,
      #d5d4d0 0%,
      #d5d4d0 1%,
      #eeeeec 31%,
      #efeeec 75%,
      #e9e9e7 100%
    );
    background: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
    background: linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);
    background-color: #cddcdc;
    background: radial-gradient(
        at 50% 100%,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(0, 0, 0, 0.25) 100%
      ); */
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);

    /* WORKING SMALL CONTAINER :- https://stackoverflow.com/questions/46730667/how-to-display-image-of-any-size-in-a-fixed-size-100x100-div */
    /* max-width: 200px;
    max-height: 200px;
    width: auto;
    height: auto; */
  }

  .remove-underline {
    text-decoration: none;
  }

  @media (min-width: 420px) and (max-width: 768px) {
    .brand-title {
      font-size: 2rem;
    }

    .brand-sub-title {
      font-size: 0.9rem;
    }
    .explore-btn-container {
      /* text-align: left; */
    }
    .card-img {
      height: 20rem;
    }
    .item {
      /* padding: 10px 10px; */
    }
  }

  @media (max-width: 425px) {
    .brand-title {
      font-size: 1.75rem;
      text-align: left;
    }

    .brand-sub-title {
      font-size: 0.9rem;
      text-align: left;
    }
    .explore-btn-container {
      text-align: left;
    }
    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.3rem;
    }
    .tb-btn {
      padding: 0 20px;
    }
    .card-img {
      height: 16rem;
    }
    .item {
      padding: 10px;
    }
    .item h3 {
      padding: 0 10px;
    }
    .item p {
      padding: 0 10px 10px 10px;
    }
  }
`;

export default Home;
