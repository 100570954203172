import React, { useState, useEffect } from "react";
import styled from "styled-components";

const helpfulModalArticles = () => {
  // Modal Component - useState, props, Transition Group, Portal
  // link - https://medium.com/tinyso/how-to-create-a-modal-component-in-react-from-basic-to-advanced-a3357a2a716a
  // link (good Portal explaination) - https://hackernoon.com/reactjs-custom-modal-component-using-hooks-and-portals-p12j35le
  //
  // Modal Component - customHook, Portal
  // link - https://upmostly.com/tutorials/modal-components-react-custom-hooks
  // link - https://reactjsexample.com/a-nice-modal-manager-for-react/ [nice-modal-react docs]
  //
  // Modal Component - customHook, Context, Portal
  // link (context) - https://dev.to/alexandprivate/your-next-react-modal-with-your-own-usemodal-hook-context-api-3jg7
  // link (context, redux) - https://stackoverflow.com/questions/69581413/create-global-modal-that-calls-different-fuctions
  // link (redux) - https://betterprogramming.pub/global-dialog-modal-infrastructure-with-react-hooks-and-redux-hooks-1b6bedd052a6
  //
  //
  //
  // https://stackoverflow.com/questions/45180598/how-to-import-uikit-js-in-react-js-without-using-the-uikit-component
  // https://stackoverflow.com/questions/49511883/uikits-modals-in-react-integration
  // Create Modal Component in vanilla js
  // - create a `.css` file & put all classes & ids value there
  // - create a `.js` file & put dynamic things there like click handler to toggle modal 'show' class
  // You have to use custom attribute to `target` the modal container with id [data attribute, custom attribute]
};

const modalImplementationExample = () => {
  // const { isShow, toggleModal } = useModal();
  /* <button
        className="uk-button uk-button-primary"
        // onClick={toggleModal}
      >
        show modal
      </button> */
  /* Default Modal */
  // <CustomModal isShow={isShow} closeModal={toggleModal}>
  //   <p>Hi modal with customHook</p>
  // </CustomModal>;
  /* Alert Modal */
  // <CustomModal modalType="alert" isShow={isShow} closeModal={toggleModal}>
  //   <p>Hi, this an alert.</p>
  // </CustomModal>;
};

// modalType - customModal (default), alert, confirm, prompt
function CustomModal({
  modalType,
  children,
  isShow,
  closeModal,
  notifyCloseModal,
}) {
  //   const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isShow]);

  //   const showModal = () => {
  //     setIsShow(true);
  //   };

  //   const closeModal = () => {
  //     setIsShow(false);
  //   };

  //   const toggleModal = () => {
  //     setIsShow(!isShow);
  //   };

  const closeModalHandler = () => {
    if (notifyCloseModal) notifyCloseModal();
    closeModal();
  };
  let customModal;
  if (modalType === "alert") {
    customModal = (
      <Wrapper>
        {/* <!-- Modal container --> */}
        <div className={`${isShow ? "modal show" : "modal"}`}>
          {/* <!-- Modal content, body --> */}
          <div
            className="modal-content"
            data-uk-scrollspy="cls:uk-animation-slide-top; repeat:true"
          >
            <div>
              {children}
              {/* <button
                className="uk-button uk-button-secondary"
                onClick={closeModal}
              >
                OK
              </button> */}
            </div>
          </div>
        </div>
      </Wrapper>
    );
  } else if (modalType === "confirm") {
    customModal = (
      <>
        <p>Hi confirm</p>
      </>
    );
  } else {
    customModal = (
      <Wrapper>
        {/* <!-- Modal container --> */}
        <div className={`${isShow ? "modal show" : "modal"}`}>
          {/* <!-- Modal content, body --> */}
          <div
            className="modal-content"
            data-uk-scrollspy="cls:uk-animation-slide-top; repeat:true"
          >
            {/* <span className="close" onClick={closeModal}>
              &times;
            </span> */}

            <button
              class="uk-modal-close-default"
              type="button"
              data-uk-close
              // onClick={closeModal}
              onClick={closeModalHandler}
            ></button>

            {/* <button
              class="close"
              type="button"
              onClick={closeModal}
              data-uk-close
            ></button> */}

            {/* <div>
              <h2 className="sub-heading-text">Create new bucket</h2>
              <div class="uk-margin">
                <input
                  class="uk-input"
                  type="text"
                  placeholder="Name of your bucket"
                />
              </div>
              <div class="uk-margin">
                <textarea
                  class="uk-textarea"
                  rows="5"
                  placeholder="Description (optional)"
                ></textarea>
              </div>
              <button className="uk-button uk-button-secondary">submit</button>
            </div> */}

            {children}
          </div>
        </div>
      </Wrapper>
    );
  }

  return customModal;
}

const Wrapper = styled.div`
  /* Modal container */
  .modal {
    /* uikit */
    /* 1 */
    display: none;
    /* 2 */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    /* 3 */
    overflow-y: auto;
    /* 4 */
    padding: 15px 15px;
    /* 5 */
    background: rgba(0, 0, 0, 0.6);

    /* blog */
    /* display: none;
    position: fixed;
    z-index: 16;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); */
  }

  /* Modal Content, Body */
  .modal-content {
    /* uikit */
    /* 1 */
    position: relative;
    /* 2 */
    box-sizing: border-box;
    margin: 0 auto;
    width: 600px;
    /* 3 */
    max-width: 100% !important;
    /* 4 */
    background: #fff;
    padding: 20px 20px;

    /* Blog */
    /* position: relative;
    background-color: #fefefe;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #888;
    width: 600px;
    max-width: 100%; */
    /* max-width: calc(100% - 20px); */

    /* overflow: auto; */
    /* scroll-behavior: contain; */

    /* animation-name: animatetop; */
    /* animation-duration: 0.4s; */
  }

  /* Add Animation */
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  .show {
    display: block;
  }
  /* The Close Button */
  .close {
    position: absolute;
    /* z-index: 1010; */
    top: 10px;
    right: 10px;
    padding: 5px;

    /* color: #aaaaaa;
    float: right;
    font-size: 2rem;
    font-weight: bold; */
  }

  .close:hover,
  .close:focus {
    color: #6b6666;
    text-decoration: none;
    cursor: pointer;
  }
  .custom-style {
    height: 8rem;
    overflow-y: scroll;
    /* scroll-behavior: contain; */
  }

  /* Phone landscape and bigger */
  @media (min-width: 640px) {
    .modal {
      padding: 50px 30px;
    }
    .modal-content {
      padding: 30px 30px;
    }
  }
  /* Tablet landscape and bigger */
  @media (min-width: 960px) {
    .modal {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`;

export default CustomModal;

/**
 *
 * UNUSED CODE, ONLY TO REFER UIKIT MODAL
 */
const uikitModal = () => {
  return (
    <Wrapper2>
      {/* uikit-modal */}
      {/* <div class={`${isShow ? "uk-modal-uk-open" : "uk--modal"}`}>
        <div className="uk-modal-dialog">
          <span class="close" onClick={closeModal}>
            &times;
          </span>
          <h2 className="sub-heading-text">you can add your body here</h2>
      
        </div>
      </div> */}
    </Wrapper2>
  );
};

const Wrapper2 = styled.div`
  /* ui-kit css */
  .uk-modal {
    /* 1 */
    display: none;
    /* 2 */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    /* 3 */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* 4 */
    background: rgba(0, 0, 0, 0.6);
    /* 5 */
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    /* 6 */
    touch-action: cross-slide-y pinch-zoom double-tap-zoom;
    /* 7 */
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .uk-modal.uk-open {
    opacity: 1;
  }

  .uk-modal-dialog {
    /* 1 */
    position: relative;
    /* 2 */
    box-sizing: border-box;
    margin: 50px auto;
    padding: 20px;
    width: 600px;
    max-width: 100%;
    max-width: calc(100% - 20px);
    /* 3 */
    background: #fff;
    /* 4 */
    opacity: 0;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: opacity 0.3s linear, -webkit-transform 0.3s ease-out;
    transition: opacity 0.3s linear, transform 0.3s ease-out;
  }

  .uk-open .uk-modal-dialog {
    /* 4 */
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
